//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #2 - Import - Material UI Icons
//===============================================================================================================
import SearchIcon from '@material-ui/icons/Search';

export const FilterButton = ({ icon, text, onClick }) => {

    return (
        <div className="filter-item">
            <button type="button" className="filter-button" onClick={onClick}>
                {text}
                <span className="icon">
                    {icon}
                </span>
            </button>
        </div>

    )
}

export const SubmitFilter = ({onClick}) => {

    return (
        <div className="filter-item">
            <button type="submit" className="submit-filter" onClick={(e) => onClick(e)}>
                <SearchIcon />
            </button>
        </div>

    )
}

export const FieldFilter = ({ label, type, value, onChange, onClick }) => {

    return (
        <div className="filter-item">
            <div className="filter-date">
                <label className="_title-label-input">{label}</label>
                <div className="field">
                    <input
                        type={type}
                        className="form-control"
                        defaultValue={value}
                        onChange={onChange}
                        onClick={onClick}
                    />

                </div>
            </div>
        </div>
    )
}

export const Filters = ({ title, children }) => {

    return (
        <div className="Filters">
            <h2 className="title">{title}</h2>

            <form className="form-filter">
                {children}
            </form>

        </div>
    )
}