import Service from './Service';

export default class PlugNotas extends Service {

    generateSimpleInvoice(orderId) {
        return this.axiosInstance.get(`/plugnotas/simpleInvoice/${orderId}`);
    }

    createInvoice({ orderId, scamberBody }) {
        return this.axiosInstance.post(`/plugnotas`, { orderId, scamberBody });
    }

    consultInvoice({ orderId }) {
        return this.axiosInstance.get(`/plugnotas/${orderId}`);
    }

    consultInvoicePdf({ idNota }) {
        return this.axiosInstance.get(`/plugnotas/notas/${idNota}`, {}, { responseType: 'blob' });
    }

    consultInvoiceXml({ idNota }) {
        return this.axiosInstance.get(`/plugnotas/xml/${idNota}`, {}, { responseType: 'blob' });
    }

    createEntryInvoice({ adTransferId, selectedItemsIds, scamberId, type }) {
        return this.axiosInstance.post(`/plugnotas/entry`, adTransferId ? { adTransferId } : { selectedItemsIds, scamberId, type });
    }

}