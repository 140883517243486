import Service from './Service';

export default class Categorizacao extends Service {

  constructor() {
    super();
  }

  list() {
    return this.axiosInstance.get(`/categorizacao`);
  }
}