import React from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/pro-light-svg-icons";

export const Price = (props) => {

    return (
        <div className={"_price real "+ props.size}>
            <div className="ui-item__price">
                <span className="price-tag-fraction"> {props.value} </span>
                <span className="price-tag-symbol">r$</span>
            </div>
        </div>
    )

};

export const PricePoints = (props) => {

    return (
        <>
        <div className={"_product-price _1x points "+ props.size + " " + props.className}>
            {props.oldPrice && (
            <div className="ui-item__discount-price">
                <span className="price-tag-fraction"> {props.oldPrice} </span>
                <span className="price-tag-symbol">pts</span>
            </div>
            )}

            <div className="ui-item__price">
                <span className="price-tag-fraction">{props.value}</span>
                <span className="price-tag-symbol">pts</span>
            </div>
        </div>
        </>
    )

};

export const PricePointsIcon = (props) => {

    return (
        <>
            <div className={`_product-price with-icon _1x points ${props.color ? props.color : ""}`}>

                <div className="icon-coins">
                    <Icon icon={faCoins} />
                </div>
                <div className="ui-item__price">
                    <span className="price-tag-fraction">{props.value}</span>
                    <span className="price-tag-symbol">pts</span>
                </div>
            </div>
        </>
    )

};