import React, { useState } from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import {
    Wrapper,
    Head,
    HeadColumn,
    Column,
    StatusCount,
    ButtonTag,
    ButtonContent,
    Title,
    Text,
    ButtonIcon,
    Content,
    ContentButtons,
    LineTitle,
    TitleLineAction,
    ButtonResultAction,
    ColumnWrapper,
    ButtonTitle,
    ButtonText,
} from "./styles";

export const ButtonAction = ({ title, text, icon, disabled = false, onClick }) => {

    return (
        <ButtonResultAction type="button" disabled={disabled} onClick={onClick}>
            <ColumnWrapper>
                <Column>
                    <ButtonTitle>
                        {title}
                    </ButtonTitle>
                    <ButtonText>
                        {text}
                    </ButtonText>
                </Column>
                <Column className="last-column">
                    <ButtonIcon>
                        {icon}
                    </ButtonIcon>
                </Column>
            </ColumnWrapper>
        </ButtonResultAction>
    )
}

export function ResultBlockActions({ children }) {

    return (
        <>
            <LineTitle>
                <TitleLineAction>
                    ações
                </TitleLineAction>
            </LineTitle>

            {children}

        </>
    )
}