// React
import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";

import { useHistory } from 'react-router-dom';

// Bootstrap
import { Button } from "react-bootstrap";

import Authentication from "./../services/Authentication";
import Scamber from "./../services/Scamber";

import { scambMenu, shoppingMenu, pdvMenu } from './../data/Access'


// Material - UI
import { Grid, TextField, useMediaQuery, makeStyles } from "@material-ui/core";

// Styles
const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
  svgSize: {
    width: "18px !important",
    height: "auto !important",
  },
});

export default function Home(props) {
  // Styles
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const history = useHistory();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");


  useEffect(() => {
    if (Boolean(localStorage.getItem("token"))){
      const getRedirect = localStorage.getItem("redirect");
      console.log(getRedirect);

      if(getRedirect && getRedirect !== undefined) {
        return window.location.href = getRedirect;
      } else {
        return window.location.href = "/contas_detalhe";
      }
    }
  }, []);

  const handleSubmitForm = (e) => {
    e.preventDefault();

    const auth = new Authentication();
    const scamber = new Scamber();

    auth
      .doLogin({ email: user, password: password })
      .then(function (response) {
        if (response.status !== 200)
          return Swal.fire({
            icon: "info",
            text: "Login e/ou senha inválido.",
          });
        localStorage.setItem("token", true);

        scamber.loadScamberPermissions().then((response) => {
          if (response.data.type === 'success') {
            const data = response.data.payload.permissions;
            let redirectUser = false;

            if(data.scamb && data.scamb.length !== 0) {
                if(!redirectUser) {
                  redirectUser = true;
                  localStorage.setItem("redirect", scambMenu[data.scamb[0]]?.link);
                  return history.push(scambMenu[data.scamb[0]]?.link);                        
                }
            }

            if(data.shopping && data.shopping.length !== 0) {
                if(!redirectUser) {
                  redirectUser = true;
                  localStorage.setItem("redirect", shoppingMenu[data.shopping[0]]?.link);
                  return history.push(shoppingMenu[data.shopping[0]]?.link);
                }
            }

            if(data.pdv && data.pdv.length !== 0) {
                if(!redirectUser) {
                  redirectUser = true;
                  localStorage.setItem("redirect", pdvMenu[data.pdv[0]]?.link);
                  return history.push(pdvMenu[data.pdv[0]]?.link);
                }
            }
          }
        }).catch((error) => {
          if (history.location.pathname !== '/') {
            Swal.fire({
                type: "info",
                html: scamber.formatErrorMessage(error.response),
                title: "",
            });
          }
        });
      }).catch(function (error) {
        Swal.fire({ icon: "info", text: "Login e/ou senha inválido." });
      });
  };

  return (
    <>
      <div id="view_login">
        <section className="_wrap-auth-screen">
          <div className="auth-screen">
            <div className="logo">
              <img src="/images/logo.png" alt="Scamb" />
            </div>

            <div className="_wrap-form">
              <form className="_form" onSubmit={(e) => handleSubmitForm(e)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-login"
                      label="Login *"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="email"
                      fullWidth
                      onChange={(e) => setUser(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-password"
                      label="Senha *"
                      type={password ? "password" : "password"}
                      variant="outlined"
                      name="password"
                      size={matches ? "small" : "small"}
                      fullWidth
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <div className="form-group-button">
                  <Button
                    type="submit"
                    className="btn btn-primary"
                    color="primary"
                    autoFocus
                    style={{ height: matches ? 35 : 40 }}
                  >
                    Fazer login
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
