import React, { useEffect, useState, useCallback, useRef } from 'react';
import Swal from 'sweetalert2';
import { Button } from "react-bootstrap";
import { TextField, Grid, } from "@material-ui/core";
import SubjectIcon from '@material-ui/icons/Subject';
import { useForm } from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ButtonMaterial from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { format } from 'date-fns';

import CommissionsService from '../../services/Commissions';
import ScamberService from '../../services/Scamber';

import ModalDialog from '../../components/ModalDialog';
import ProductCard from '../../components/ProductCard';

function Commissions() {
  const [commissions, setCommissions] = useState([]);
  const [editCommission, setEditCommission] = useState();
  const [check, setCheck] = useState({ pause_adjustment: true });
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);

  const refModalEditCommissions = useRef();
  const refModalAddStores = useRef();
  const refModalViewStores = useRef();

  const { handleSubmit, register, setValue, errors } = useForm();
  const { handleSubmit: handleSubmitSearchFilter, register: registerSearchFilter, reset: resetSearchFilter } = useForm();

  const handleOpenModalEditCommissions = ({ parameter }) => {
    setEditCommission(parameter);

    setValue('commissionValue', parameter.valor_comissao);
    setValue('startedDate', format(new Date(parameter.data_inicio), 'yyyy-MM-dd'));
    setValue('expiredDate', format(new Date(parameter.data_fim), 'yyyy-MM-dd'));
    setSelectedStores(parameter.lojas)
    setCheck({ pause_adjustment: parameter.ativo })

    refModalEditCommissions.current.openModal();
  }

  const handleOpenModalViewCommissions = ({ parameter }) => {
    setSelectedStores(parameter.lojas)
    refModalViewStores.current.openModal();
  }

  const handleChangeCheckbox = (event) => {
    const { target } = event;
    setCheck({ ...check, [target.name]: target.checked });
  };

  const handleSearchStores = () => {
    const scamberService = new ScamberService();

    scamberService.listAllScamberWithStore().then((response) => {
      setStores(response.data.payload);
    })
  }

  const onSearch = (data) => {
    handleSearchStores();
  }

  const openSelectProductModal = () => {
    handleSearchStores();
    refModalAddStores.current.openModal();
  }

  const onCleanFilter = () => {
    resetSearchFilter({})
    handleSearchStores();
  }

  const addStore = (e, store) => {
    e.preventDefault();

    if (selectedStores) {
      const existstore = selectedStores.find((a) => a._id === store._id);

      if (!existstore) {
        setSelectedStores([...selectedStores, store]);
      }
    } else {
      setSelectedStores([store]);
    }
  }

  const removeStore = (e, store) => {
    e.preventDefault();

    const excludesStore = selectedStores.filter((a) => a._id !== store._id);
    setSelectedStores(excludesStore);
  }

  const editCommissions = (data) => {
    const commissionsService = new CommissionsService();

    Swal.fire('Editando comissão... Aguarde');
    Swal.showLoading();

    data.allScambersStores = selectedStores;
    data.active = check.pause_adjustment;

    commissionsService.editCommission({
      commissionId: editCommission._id,
      comission: data,
    }).then(() => {
      Swal.fire({
        title: "",
        text: "Comissãoatualizada com sucesso!",
        type: "success",
      }).then(() => {
        refModalEditCommissions.current.closeModal();
        getAllCommissions();
      });
    }).catch(err => {
      Swal.fire({
        type: 'error',
        html: commissionsService.formatErrorMessage(err.response),
        title: '',
      });
    })
  }

  const getAllCommissions = () => {
    const commissionsService = new CommissionsService();

    Swal.fire('Buscando comissão... Aguarde');
    Swal.showLoading();

    commissionsService.listCommission().then(response => {
      setCommissions(response.data.payload);
      Swal.close();
    }).catch(err => {
      Swal.close();
      Swal.fire({
        type: 'error',
        html: commissionsService.formatErrorMessage(err.response),
        title: '',
      });
    })
  }

  const handleInitialValue = useCallback(() => {
    handleSearchStores();
    getAllCommissions();
  }, []);

  useEffect(() => {
    handleInitialValue()
  }, [handleInitialValue])


  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">
          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title"> Comissão</div>
          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope={"col"}>Nome</th>
                <th scope={"col"}>Comissão</th>
                <th scope={"col"}>Data de Inicio</th>
                <th scope={"col"}>Data fim</th>
                <th scope={"col"}>Ativo</th>
                <th scope={"col"}>Lojas</th>
                <th scope={"col"}>Editar</th>
              </tr>
            </thead>
            <tbody>
              {commissions && commissions.map((register) => (
                <tr key={register._id}>
                  <td>{register.nome}</td>
                  <td>{register.valor_comissao}%</td>
                  <td>{format(new Date(register.data_inicio), 'dd/MM/yyyy')}</td>
                  <td>{format(new Date(register.data_fim), 'dd/MM/yyyy')}</td>
                  <td>{register.ativo ? 'Sim' : 'Não'}</td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: 45 }}
                      onClick={() => handleOpenModalViewCommissions({ parameter: register })}
                    >
                      Visualizar
                    </Button>
                  </td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: 45 }}
                      onClick={() => handleOpenModalEditCommissions({ parameter: register })}
                    >
                      Editar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>

      <ModalDialog
        dialogTitle="Editar comissão"
        className="_modal-category GridProducts"
        maxWidth="sm"
        ref={refModalEditCommissions}
      >
        <div className="select-product-container">
          <div className="search-products-container">
            <div className="_wrap-form" style={{ marginBottom: '20px', marginTop: '20px' }}>
              <form className="_form" onSubmit={handleSubmit(editCommissions)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Comissão"
                      type="number"
                      variant="outlined"
                      size={"small"}
                      name="commissionValue"
                      fullWidth
                      helperText={!!errors.commissionValue && "Campo não pode ficar em branco"}
                      error={!!errors.commissionValue}
                      inputRef={register({ required: true })}
                      InputProps={{ inputProps: { min: 0, max: 100 } }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-login"
                      label="Data: Inicial *"
                      type="date"
                      variant="outlined"
                      size="small"
                      name="startedDate"
                      helperText={!!errors.startedDate && "Campo não pode ficar em branco"}
                      error={!!errors.startedDate}
                      fullWidth
                      inputRef={register({ required: true })}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-login"
                      label="Data: Final *"
                      type="date"
                      variant="outlined"
                      size="small"
                      name="expiredDate"
                      helperText={!!errors.expiredDate && "Campo não pode ficar em branco"}
                      error={!!errors.expiredDate}
                      fullWidth
                      inputRef={register({ required: true })}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={12} xs={12} className="WrapButtonSubmit">
                    <h1 className="_content-title">Seleção de Produtos</h1>
                    <Button onClick={() => openSelectProductModal({})}>
                      Selecionar Produtos
                    </Button>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      label="Ativar comissão"
                      control={
                        <Switch
                          checked={check.pause_adjustment}
                          onChange={handleChangeCheckbox}
                          name="pause_adjustment"
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  className="btn-step"
                  style={{ height: 35, marginBottom: '20px' }}
                >
                  Concluir
                </Button>
              </form>
            </div>
          </div>
        </div>
      </ModalDialog>

      <ModalDialog
        dialogTitle="Seleção de Lojas"
        className="_modal-category GridProducts"
        maxWidth="lg"
        btnTitle="Aplicar"
        ref={refModalAddStores}
      >
        <div className="select-product-container">
          <div className="search-products-container">
            <div className="_wrap-form" style={{ marginBottom: '20px', marginTop: '20px' }}>
              <form className="_form" onSubmit={handleSubmitSearchFilter(onSearch)}>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-add-title"
                      label="Filtrar por"
                      type="text"
                      variant="outlined"
                      size="small"
                      name="filterby"
                      fullWidth
                      inputRef={registerSearchFilter({ required: false })}
                      inputProps={{ maxLength: 40 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <Button type='submit' style={{ marginTop: '3px' }}>
                      Aplicar
                    </Button>
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <Button color="secondary" style={{ marginTop: '3px' }} onClick={() => onCleanFilter()}>
                      Limpar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>

            <Grid container spacing={2}>
              {stores && stores.map((store, index) => (
                <Grid item xs={12} key={index}>
                  <ProductCard
                    key={store._id}
                    title={store?.minha_loja?.titulo}
                  >
                    <ButtonMaterial
                      endIcon={<AddIcon />}
                      variant="contained"
                      onClick={(e) => addStore(e, store)}
                      style={{ marginRight: '10px' }}
                    >
                      Adicionar
                    </ButtonMaterial>
                  </ProductCard>
                </Grid>
              ))}

            </Grid>
          </div>

          {selectedStores?.length > 0 && (
            <div className="alredy-select-container">
              <div className="_title-select-product">Produtos já selecionados</div>

              <List className="alredy-select-list">
                {selectedStores && selectedStores.map((store) => (
                  <ProductCard
                    key={store._id}
                    title={store?.minha_loja?.titulo}
                  >
                    <ButtonMaterial
                      endIcon={<DeleteIcon color="secondary" />}
                      onClick={(e) => { removeStore(e, store) }}
                    />
                  </ProductCard>
                ))}
              </List>
            </div>
          )}
        </div>
      </ModalDialog>

      <ModalDialog
        dialogTitle="Lojas selecionadas"
        className="_modal-category GridProducts"
        maxWidth="sm"
        ref={refModalViewStores}
      >
        {selectedStores?.length > 0 && (
          <div className="alredy-select-container">
            <List className="alredy-select-list">
              {selectedStores && selectedStores.map((store) => (
                <ProductCard
                  key={store._id}
                  title={store?.minha_loja?.titulo}
                />
              ))}
            </List>
          </div>
        )}
      </ModalDialog>
    </>
  )
}

export default Commissions;