import Service from './Service';

export default class Scamber extends Service {

    constructor() {
        super();
    }

    listarScambers(page, limit, filter) {
        return this.axiosInstance.get(`/scamber?page=${page}&limit=${limit}${filter ? '&filter=' + filter : ''}`);
    }

    pontuarScamber(pointInfo) {
        return this.axiosInstance.post(`/scamber/ponctuate`, pointInfo);
    }

    loadScamberPermissions() {
        return this.axiosInstance.get(`/scamber/permissions`);
    }

    loadLoyaltyExtract(scamberID, startDate, endDate, productCode) {
        return this.axiosInstance.get(`/scamber/extract/${scamberID}?a=a${startDate ? '&startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}${productCode ? '&productCode=' + productCode : ''}`);
    }

    loadLoyaltyRedemptionExtract(scamberId, startDate, endDate) {
        return this.axiosInstance.get(`/extract/loyalty/redemption/${scamberId}${startDate ? '?startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}`);
    }

    loadPointsAwaitingApproval(scamberId) {
        return this.axiosInstance.get(`/extract/loyalty/pointsAwaitingApproval/${scamberId}`);
    }

    loadExtractAwaitingApproval(scamberId, startDate, endDate) {
        return this.axiosInstance.get(`/extract/loyalty/extractAwaitingApproval/${scamberId}?a=a${startDate ? '&startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}`);
    }

    loadPointExtract(limit, startDate, endDate, filter, scamberId) {
        return this.axiosInstance.get(`/extract/history/${scamberId}${limit ? '?limit=' + limit : ''}${startDate ? '&startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}${filter ? '&filter=' + filter : ''}`);
    }

    loadLoyaltyExtractExpiration(startDate, endDate, filter, contaLoyalty) {
        return this.axiosInstance.get(`/extract/loyalty/expiration/${contaLoyalty}?a=a${startDate ? '&startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}${filter ? '&filter=' + filter : ''}`);
    }

    createScamberPartner(scamber) {
        return this.axiosInstance.post('/scamber/createpartner', { scamber: scamber });
    }

    updateScamberPartner(scamber) {
        return this.axiosInstance.put('/scamber/updatepartner', { scamber: scamber });
    }

    listScamberPartner(search) {
        return this.axiosInstance.get(`/scamber/listpartners?search=${search || ''}`);
    }

    listOneScamberPartner(id) {
        return this.axiosInstance.get(`/scamber/listonepartner/${id}`);
    }

    createAdminUser({ login, senha, permissoes }) {
        return this.axiosInstance.post(`/scamber/createAdminUser`, { login: login, senha: senha, permissoes: permissoes });
    }

    editAdminUser({ userAdminId, senha, permissoes }) {
        return this.axiosInstance.post(`/scamber/editAdminUser`, { userAdminId: userAdminId, senha: senha, permissoes: permissoes });
    }

    findAdminUsers({ search }) {
        return this.axiosInstance.get(`/scamber/findAdminUsers?search=${search ? search : ''}`);
    }

    listAllScamberWithStore() {
        return this.axiosInstance.get(`/scamber/scambersWithStore`);
    }

    updateScamberCommissionAndCashback({ scamberId, commision, cashback, cashbackSeller, descontPoints }) {
        return this.axiosInstance.put(`/scamber/updatecommissios`, { scamberId, commision, cashback, cashbackSeller, descontPoints });
    }

}