import Service from './Service';

class OrderPoints extends Service {

    constructor() {
        super();
    }

    loadOrdersPoints(startDate, endDate, filter) {
        return this.axiosInstance.get(`/orderpoints${startDate?'?startDate=' + startDate:''}${endDate?'&endDate=' + endDate:''}${filter?'&scamber=' + filter : ''}`);
    }
}

export default OrderPoints;