export function downloadBase64ToPDF(pdf, filename) {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = `${filename}.pdf`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export function downloadBase64ToXML(pdf, filename) {
  const linkSource = `data:application/xml;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = `${filename}.xml`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}