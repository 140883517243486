import styled from "styled-components";

export const WrapperPage = styled.div`
    background-color: ${({theme}) =>  theme.color.extraLight};
    width: 100%;
    padding-top: 15px;
    padding-bottom: 30px;

    .ResultBlock {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .pagination-style {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;