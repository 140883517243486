import React, {useState, useEffect, useRef} from 'react';
import OrderService from './../../services/OrderShopping';
import Dowload from '../../services/Dowload';
import Swal from 'sweetalert2';
import Pagination from '@material-ui/lab/Pagination';
import SubjectIcon from "@material-ui/icons/Subject";
import ModalDialog from '../../components/ModalDialog';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import {useMediaQuery, TextField} from "@material-ui/core";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import SearchIcon from "@material-ui/icons/Search";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

const OrdersValues = () => {

    const matches = useMediaQuery("(min-width:600px)");
    const refModalOrderVendorDetails = useRef();

    const [totals , setTotals] = useState({});
    const [registros, setRegistros] = useState([]);
    const [pagination, setPagination] = useState();
    const [orderVendor, setOrderVendor] = useState({});
    const [orderTransferValue, setOrderTransferValue] = useState();
    const [filterEmail, setFilterEmail] = useState();
    const [ordenation, setOrdenation] = useState(-1);
    const [ĺimitOfData] = useState(10);

    const loadOrdersTotals = () => {
        const orderService = new OrderService();

        orderService.loadOrdersTotals().then((response) => {
            setTotals(response.data.payload);
          }).catch((error) => {
            Swal.fire({
              type: "info",
              text: orderService.formatErrorMessage(error.response),
              title: "",
            });
          })
    }

    const loadOrders = (page, email, order) => {
        const orderService = new OrderService();
    
        Swal.fire('Carregando lista ... Aguarde');
        Swal.showLoading();
        orderService.loadOrdersValues(page, email, order, ĺimitOfData).then((response) => {
          Swal.close();
          setRegistros(response.data.payload.docs);
          setPagination({totalPages: response.data.payload.totalPages});
        }).catch((error) => {
          Swal.fire({
            type: "info",
            text: orderService.formatErrorMessage(error.response),
            title: "",
          });
        })
    }

    const loadOrderVendor = async (orderID, transferValue) => {
        const orderService = new OrderService();
    
        Swal.fire('Carregando informações do vendedor ... Aguarde');
        Swal.showLoading();

        try {
            const vendorResponse = await orderService.loadOrderVendorInfo(orderID);
            setOrderVendor(vendorResponse.data.payload);
            setOrderTransferValue(transferValue);
            Swal.close();
        } catch (error) {
            Swal.fire({
                type: "info",
                text: orderService.formatErrorMessage(error.response),
                title: "",
            }).then(() => refModalOrderVendorDetails.current.closeModal());
        }
    }

    const getOrdersFile = () => {
        const download = new Dowload();
    
        Swal.fire('Gerando arquivo ... Aguarde');
        Swal.showLoading();
        download.getOrderMoneyFile(filterEmail, ordenation).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset=utf-8;' }));
          var fileLink = document.createElement('a');
          const newDate  = new Date();
    
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `valores_pedidos${newDate.getDate()}_${newDate.getMonth() + 1}_${newDate.getFullYear()}.csv`);
          document.body.appendChild(fileLink);
          
          Swal.close();
          fileLink.click();
        }).catch(error => {
          Swal.close();
          Swal.fire({
            type: "info",
            html: download.formatErrorMessage(error.response),
            title: "",
          });
        });
      }

    const handlePageChange = (page) => {
        loadOrders(page);
    };

    useEffect(() => {
        loadOrders(1, filterEmail, ordenation);
        loadOrdersTotals();
    }, []);

    const formatToBRL = (value) => {
        if(value) return value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

        return "R$ 0,00";
    }

    const formatToPercentage = (value) => {
        return `${value} %`;
    }

    const openVendorModal = async (orderID, transferValue) => {
        await loadOrderVendor(orderID, transferValue);
        refModalOrderVendorDetails.current.openModal();
    }

    const clearFilter = (e) => {
        e.preventDefault();

        setFilterEmail('');
        
        loadOrders(1);
    }

    const onFilter = (e) => {
        e.preventDefault();
        loadOrders(1, filterEmail, ordenation);
    }

    return (
        <main role="main" id="view_account-details">
            <div className="_fluid-container">

                <div className="_page-section">
                        <div className="icon">
                            <SubjectIcon />
                        </div>
                        <div className="title"> Valores Pedidos</div>
                </div>

                <div className="_wrap-filter-activity">
                    <h2 className="title">Filtros</h2>
                    <div className="_filter-activity">
                        <form className="_form" onSubmit={(e) => onFilter(e)}>
                            <div className="_wrap-dates">

                            <div className="_wrap-input-group">
                                <label className="_title-label-input">Filtrar</label>
                                <div className="input-group">
                                <TextField
                                    type="text"
                                    className="form-control"
                                    name="filterEmail"
                                    value={filterEmail}
                                    aria-describedby="cc-name"
                                    onChange={(e) => setFilterEmail(e.target.value)}
                                />
                                </div>
                            </div>
                            </div>

                            <div className="_switch">
                                <div className="_option">
                                    <label htmlFor="filter-type-1">
                                    <input type="radio" id="filter-type-1" name="ordenation" defaultChecked={true} value={-1} onChange={(e) => setOrdenation(e.target.value)} />
                                    <div className="mark">
                                        <Icon icon={faCheck} />
                                    </div>
                                    <div className="title">
                                        Decrescente
                                    </div>
                                    </label>
                                </div>

                                <div className="_option">
                                    <label htmlFor="filter-type-2">
                                    <input type="radio" id="filter-type-2" name="ordenation" value={1} onChange={(e) => setOrdenation(e.target.value)} />
                                    <div className="mark">
                                        <Icon icon={faCheck} />
                                    </div>
                                    <div className="title">
                                        Crescente
                                    </div>
                                    </label>
                                </div>
                            </div>

                            <div className="_wrap-input-group _submit">
                            <button type="submit" className="submit-filter">
                                <SearchIcon></SearchIcon>
                            </button>
                            </div>
                        </form>
                        <div className="excel-div">
                            <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
                        </div>
                        <div className="excel-div">
                            <button onClick={getOrdersFile} className="excel-button">Exportar .csv</button>
                        </div>
                    </div>
                </div>

                <div style={{marginBottom: '20px'}}>
                    <div style={{fontWeight: 'bold'}}> Totais conta IUGU</div><br/>
                    <span>&nbsp;<strong>Total bruto:</strong> {formatToBRL(totals.totalComDescontos)}</span>
                    <span>&nbsp;<strong>Total taxas IUGU:</strong> {formatToBRL(totals.totalTaxas)}</span>
                    <span>&nbsp;<strong>Total valor líquido:</strong> {formatToBRL(totals.totalValorLiquido)}</span>
                    <span>&nbsp;<strong>Total vendedor(subcontas):</strong> {formatToBRL(totals.totalValorSubConta)}</span>
                    <span>&nbsp;<strong>Total SCAMB (Conta Mestre):</strong> {formatToBRL(totals.totalValorContaMestre)}</span>
                </div>
                
                <table className="table table-striped">
                    <thead>
                        <tr style={{textAlign: 'center'}}>
                            <th scope={"col"}>Vendedor</th>
                            <th scope={"col"}>Data do Pedido</th>
                            <th scope={"col"}>Total Pedido</th>
                            <th scope={"col"}>Frete</th>
                            <th scope={"col"}>Saldo Pontos Compra</th>
                            <th scope={"col"}>Desconto Pontos</th>
                            <th scope={"col"}>Total em Reais</th>
                            <th scope={"col"}>Cupom</th>
                            <th scope={"col"}>Desconto Aplicado</th>
                            <th scope={"col"}>Novo Total</th>
                            <th scope={"col"}>Porcentagem Comissão</th>
                            <th scope={"col"}>SCAMB <br/>Conta Mestre</th>
                            <th scope={"col"}>Vendedor <br/>Subconta</th>
                            <th scope={"col"}>Taxas IUGU</th>
                            <th scope={"col"}>Valor líquido</th>
                            <th scope={"col"}>Valor Correto <br/>Subconta</th>
                            <th scope={"col"}>Valor a transferir</th>
                            <th scope={"col"}>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {registros.length > 0 ? registros.map((registro) => {
                            return (
                            <tr key={registro._id} style={{textAlign: 'center'}}>
                                <td><a href="#!" onClick={() => openVendorModal(registro._id, registro.valorTransferir)}>{registro.emailVendedor}</a></td>
                                <td>{formatToBRL(registro.dataPadido)}</td>
                                <td>{formatToBRL(registro.totalPedido)}</td>
                                <td>{formatToBRL(registro.frete)}</td>
                                <td>{registro.pontosUtilizados}</td>
                                <td>{formatToBRL(registro.valorDescontoPontos)}</td>
                                <td>{formatToBRL(registro.totalComDescontoPontos)}</td>
                                <td>{registro.tipoDescontoAplicado === 'percentual'? 
                                    formatToPercentage(registro.cupomDescontoAplicado): formatToBRL(registro.cupomDescontoAplicado)}</td>
                                <td>{formatToBRL(registro.valorDescontoCupom)}</td>
                                <td>{formatToBRL(registro.valorFinalDescontos)}</td>
                                <td>{formatToPercentage(registro.porcentagemComissao)}</td>
                                <td>{formatToBRL(registro.valorContaMestre)}</td>
                                <td>{formatToBRL(registro.valorSubConta)}</td>
                                <td>{formatToBRL(registro.valorTaxa)}</td>
                                <td>{formatToBRL(registro.valorLiquido)}</td>
                                <td>{formatToBRL(registro.valorCorretoSubconta)}</td>
                                <td>{formatToBRL(registro.valorTransferir)}</td>
                                <td>{registro.status_pedido}</td>
                            </tr>
                            )
                        }) :
                            <>
                            </>
                        }
                    </tbody>
                </table>

                <div class="pagination-style" style={{ marginTop: '10px'}}>
                {pagination && pagination.totalPages ? (
                    <Pagination
                        shape="rounded"
                        count={pagination.totalPages}
                        variant="outlined"
                        color="primary"
                        hidePrevButton
                        hideNextButton
                        size="large"
                        onChange={(_, p) => handlePageChange(p)}
                        />
                ) : undefined}
                </div>

            </div>

            <ModalDialog
                ref={refModalOrderVendorDetails}
                dialogTitle="Informações para deposito valor faltante"
                onCloseModal={
                    <button
                        type="button"
                        className="btn"
                        onClick={() => {console.log('passei'); setOrderVendor({}); setOrderTransferValue(); refModalOrderVendorDetails.current.closeModal()}}
                    >
                        {matches ? <CloseIcon /> : <ChevronLeftIcon />}
                    </button>
                }>
                <div className="_wrap-form">
                    <div style={{padding: '20px'}}>
                        <p style={{textAlign: 'center', fontSize: '1.2em', marginBottom: '12px'}}><strong>Valor a Transferir: </strong> {formatToBRL(orderTransferValue)}</p>
                        
                        <p><strong>Pedido: </strong> {orderVendor.order}</p>
                        <p><strong>Email vendedor: </strong> {orderVendor.email}</p>
                        <p><strong>CPF/CNPJ: </strong> {orderVendor.document}</p>
                        <p><strong>Banco: </strong> {orderVendor.bank}</p>
                        <p><strong>Tipo de Conta: </strong> {orderVendor.account_type}</p>
                        <p><strong>Agência: </strong> {orderVendor.bank_ag}</p>
                        <p><strong>Conta: </strong> {orderVendor.bank_cc}</p>
                    </div>
                </div>
            </ModalDialog>
        </main>
    )

}

export default OrdersValues;