import Service from './Service';

export default class Invoice extends Service {

  consultInvoice() {
    return this.axiosInstance.get(`/invoice`);
  }

  syncAllInvoices() {
    return this.axiosInstance.put(`/invoice`);
  }

}