import Service from './Service';

class CancelOrders extends Service {

    constructor() {
        super();
    }

    reversalOrder(orderId){
        return this.axiosInstance.post(`/corder/reversalorder`, { orderId: orderId });
    }

    listReversalOrders(startDate, endDate, nameFilter, filter, website) {
        return this.axiosInstance.get(`/corder${website ? '?website=' + website: '?website=scamb'}${startDate ? '&startDate=' + startDate: ''}${endDate ? '&endDate=' + endDate: ''}${filter ? '&' + nameFilter + '=' + filter: ''}`);
    }

    chageStatusOrder(orderId){
        return this.axiosInstance.post(`/corder/cancelorder`, { orderId: orderId });
    }
}

export default CancelOrders;