import styled from "styled-components";

// * Drawer Shopping Cart
export const DrawerHead = styled.div`
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    min-height: 64px;
    border-bottom: 0.0625rem solid ${({ theme }) => theme.color.light};
`;

export const DColumn = styled.div`
    button {
        box-shadow: none;
        height: 38px;
        width: 38px;
        min-width: 38px;
        border-radius: 100px;

        svg {
            height: 16px;
            width: auto !important;
            position: relative;
            top: 2px;

            path {
                fill: ${({ theme }) => theme.color.black}
            }
        }
    }
`;

export const DrawerTitle = styled.div`
    
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    font-size: 14px;
    color: ${({ theme }) => theme.color.black};

    .cart-count {
        position: relative;
        top: -3px;
        height: 21px;
        width: 21px;
        min-width: 21px;
        font-size: 10px;
    }
    
    svg {
        height: 18px;
        width: auto !important;
        margin-right: 0.375rem;
        
        path {
            fill: ${({ theme }) => theme.color.black}
        }
    }
`;