//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #2 - Import - Material UI Icons
//===============================================================================================================
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export const Accordion = ({ title, orderId, buyer, orderDate, children }) => {

    // * States
    const [open, setOpen] = React.useState(false);

    // *  Handles Funcions
    const handleAccordion = () => setOpen(!open);

    return (
        <div className="category-accordion">

            <div className="accordion-header" onClick={handleAccordion}>
                {title ? <h1 className="accortion-title"> {title} </h1> : (
                    <div>
                        {orderId && <h1 className="accortion-title" style={{marginBottom: '10px'}}> Pedido: {orderId} </h1>}
                        {buyer && <h1 className="accortion-title" style={{marginBottom: '10px'}}> Comprador: {buyer} </h1>}
                        {orderDate && <h1 className="accortion-title"> Data da Compra: {orderDate} </h1>}
                    </div>
                )}
                    
                <div className="toggle-icon">
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}

                </div>
            </div>
            {open && (
                <div className="accordion-content">
                    {children}
                </div>
            )}
        </div>
    )
}