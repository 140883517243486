import React from "react";

import { ListBase } from "./styles";

function List({ className, children }) {

    return (
        <ListBase className={className}>
            {children}
        </ListBase>
    )
}

export default List;