import React from "react";

export const ListContent = (props) => {

    return (
        <ul className="_list-content" style={{backgroundColor: `${props.bgColor}`}}>
            {props.children}
        </ul>
    )
}

export const ListItem = (props) => {

    return (
        <li className={props.className} style={{backgroundColor: `${props.bgColor}`}}>
            <div className="_item-title">
               {props.title} {props.icon}
            </div>
            <div className="_item-content" onClick={props.onClick}>
                {props.children}
            </div>
        </li>
    )
}