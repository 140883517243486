import Service from './Service';

export default class Authentication extends Service {

  doLogin( userAndPassword ) {
    return this.axiosInstance.post(`/auth/local`, userAndPassword);
  }

  doLogout() {
    return this.axiosInstance.get(`/auth/logout`);
  }

}
