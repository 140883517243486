import styled from 'styled-components';

export const Product = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.light};
    border-radius: 3px;
    /* margin: ${props => props.margin + "px"}; */
    min-height: 240px;
    display: block;
    position: relative;
    -webkit-box-shadow: 0 6px 8px 0 rgba(110,110,110,0.1);
    box-shadow: 0 6px 8px 0 rgba(110,110,110,0.1);
    width: 100%;
`;

export const Discount = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 100px;
    border: 0.0938rem dashed ${({ theme }) => theme.color.blue};
    background-color: ${({ theme }) => theme.color.red};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 5px;
    right: 5px;
`;

const DiscountExtends = styled.div`
    line-height: 1;
    color: ${({ theme }) => theme.color.white};
    font-size: 0.75rem;
    font-weight: ${({ theme }) => theme.fontWeight.fw700};

`;

export const DiscountValue = styled(DiscountExtends)`

`;

export const OffText = styled(DiscountExtends)`
    text-transform: uppercase;
    font-size: 0.4375rem;
`;

export const WrapperProductImage = styled.div`
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    position: relative;
    border-bottom: 1px solid ${({ theme }) => theme.color.extraLight};
`;

export const ProductImage = styled.div`
    width: 100%;
    height: 100%;
    min-height: 191px;
    max-height: 191px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${({ theme }) => theme.color.light};
    overflow: hidden;

    ${({ theme }) => theme.media("xs", `
        min-height: 141px;
        max-height: 141px;
    `)};

    ${({ theme }) => theme.media("md", `
        min-height: 191px;
        max-height: 191px;
    `)};
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0px none;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
`;

export const AdWeight = styled.div`
    position: absolute;
    left: 0.625rem;
    bottom: 5px;
    background-color: ${({ theme }) => theme.color.white};
    font-family: ${({ theme }) => theme.font.nunito};
    font-size: 11px;
    font-weight: 700;
    color: #3d3d40;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.color.light};
    padding: 0.25rem .3125rem;
    border-radius: 5px;
    line-height: 1;
`;

export const Content = styled.div`
    padding: 0.625rem;
    border-bottom-left-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
`;

export const WrapperColumns = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Column = styled.div`

    &:first-child {
        width: 50%;
    }
`;

export const Title = styled.div`
    font-size: 0.75rem;
    color: ${({ theme }) => theme.color.grayBold};
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    line-height: 1;
    white-space: nowrap;
    margin-bottom: 0.625rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ theme }) => theme.media("xs", `
        width: 130px;
    `)}
    
    ${({ theme }) => theme.media("sm", `
        width: auto;
    `)}
`;

export const Pricing = styled(Title)`
    min-height: auto;
    font-size: 1rem;
    color: ${({ theme }) => theme.color.blue};
    white-space: nowrap;
    margin-bottom: 0.3125rem;
`;

export const Equivalent = styled.div`
    font-size: 0.5625rem;
    color: ${({ theme }) => theme.color.gray};
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
`;

export const RealPrice = styled.div`
    color: ${({ theme }) => theme.color.grayBold};
    font-size: 1.125rem;
    margin-top: 0.125rem;
`;

export const ButtonAddToCart = styled.button`
    border: 0;
    padding: 0.625rem;
    display: flex;
    background-color: ${({ theme }) => theme.color.transparent};
    position: relative;

    svg {
        height: 20px;
        width: auto !important;

        path {
            fill: ${({ theme }) => theme.color.red};
            opacity: 0.8;
        }
    }
`;

export const CheckAddToCart = styled.div`
    height: 16px;
    width: 16px;
    min-width: 16px;
    border-radius: 6.25rem;
    background-color: ${({ theme }) => theme.color.blue};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 4px;
    top: 8px;

    svg {
        height: 8px;
        width: auto !important;

        path {
            fill: ${({ theme }) => theme.color.white};
            opacity: 1;
        }
    }
`;

export const Bottom = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-top: 0.0625rem solid ${({ theme }) => theme.color.extraLight};
`;

export const WrapAvatar = styled.div`
    height: 34px;
    width: 34px;
    min-width: 34px;
    border-radius: 100px;
    overflow: hidden;
    border: 0.0625rem solid ${({ theme }) => theme.color.extraLight};
    margin-right: 0.375rem;
`;

export const StoreAvatar = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
`;

export const StoreInfo = styled.div``;


export const StoreTitle = styled(Title)`
    margin-bottom: 0.375rem;
    
    ${({ theme }) => theme.media("xs", `
        width: 90px;
    `)}
    
    ${({ theme }) => theme.media("sm", `
        width: auto;
    `)}
`;

export const StoreLocation = styled(Title)`
    font-size: 0.5625rem;
    margin-bottom: 0;
`;
