import React from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/pro-light-svg-icons";

import { PricePoints } from "../price";

export const Timeline = (props) => {

    return (
        <div className="_order-timeline">

            {props.children}

        </div>
    )
}

export const Status = (props) => {

    return (
        <div className="_timeline-item">
            <div className="_col">
                <div className="icon">
                    {props.icon}
                </div>
                <div className="title">
                    {props.title}
                </div>
            </div>

            <div className="_col">
                <div className="date"> {props.date} </div>
                <div className="hour"> {props.hour} </div>
            </div>
        </div>
    )
}

export const StatusExtract = (props) => {

    return (
        <div className={"_timeline-item " + props.className}>
            <div className="_col">
                <div className="icon">
                    {props.icon}
                </div>
                <div className="title">
                    <div className="expiration-date">
                        {props.hasExpired ? "Expirou em" : "Expira em"}
                        <span>{props.date}</span>
                    </div>
                    <div className="icon-points">
                        
                        <div className="icon-coins">
                            <Icon icon={faCoins} />
                        </div>
                        <h4 className="title">
                            <PricePoints value={props.points} className="blue" />
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const TimelinePoints = (props) => {

    return (
        <div className="_order-timeline points">

            {props.children}

        </div>
    )
}