import Service from './Service';

class Domain extends Service {

    constructor() {
        super()
    }

    listBrands(brand) {
        if (brand) return this.axiosInstance.get('/domain/brands?brand=' + brand);
        return this.axiosInstance.get('/domain/brands');
    }

    listConditions() {
        return this.axiosInstance.get('/domain/conditions');
    }

    listSizeGroups(website) {
        return this.axiosInstance.get(`/domain/list/sizeGroup${website? '?website=' + website: ''}`);
    }
}

export default Domain;