import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button } from "react-bootstrap";
import {
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Slide,
  useTheme,
  makeStyles,
  DialogActions
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";

const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalDialog = (props, ref) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(props.fullScreenSize ?? "md"));
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (props.customMethod) props.customMethod();
  };

  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Dialog
        fullScreen={props.isFullscreen}
        fullWidth={true}
        open={open}
        maxWidth={props.maxWidth ? props.maxWidth : "sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className={props.wrapperClassName}

      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            {props.showCloseIcon && (
              <button type="button" className="btn" onClick={props.onClose ? props.onClose : handleClose}>
                <FontAwesomeIcon
                  icon={matches ? faTimes : faChevronLeft}
                ></FontAwesomeIcon>
              </button>
            )}
            <div className="_title-view">{props.dialogTitle}</div>
          </div>
        </DialogTitle>
        <DialogContent className={props.className}>
          <Typography component={"span"} variant={"body2"}>
            {props.reset ? open && props.children : props.children}
          </Typography>
        </DialogContent>
        {props.btnTitle && (
          <DialogActions>
            <Button
              className={"btn-step " + classes.center}
              onClick={handleClose}
              color="primary"
              autoFocus
              style={{ height: matches ? 35 : 40 }}
            >
              {props.btnTitle}
              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}

export default forwardRef(ModalDialog);
