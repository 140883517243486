import Service from './Service';

export default class Report extends Service {

  constructor(){
    super();
  }

  contasDetalhe( filtro ) {
    return this.axiosInstance.post(`/report/contasDetalhe`, filtro);
  }

  marcasResumo() {
    return this.axiosInstance.get(`/report/marcasResumo`);
  }

}