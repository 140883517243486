import Service from './Service';

class Order extends Service {

    constructor() {
        super();
    }

    loadOrders(startDate, endDate, nameFilter, filter, website, page, limit) {
        return this.axiosInstance.get(`/order?a=a${startDate?'&startDate=' + startDate:''}${endDate?'&endDate=' + endDate:''}${filter?'&' + nameFilter + '=' + filter:''}${website? '&website=' + website : ''}${page? '&page=' + page : ''}${limit? '&limit=' + limit : ''}`);
    }

    orderFinish(order) {
        return this.axiosInstance.post(`/order/finish`, order);
    }

    findOrder(orderId) {
        return this.axiosInstance.get(`/order/oneOrder/${orderId}`);
    }

    createOrder({ scamberBody, paymentMethod }) {
        return this.axiosInstance.post(`/order`, { scamberBody, paymentMethod });
    }
}

export default Order;