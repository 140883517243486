import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Table } from "react-bootstrap";
import Swal from 'sweetalert2';
import Close from '@material-ui/icons/Close';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import SearchIcon from '@material-ui/icons/Search';
import { useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/pro-light-svg-icons";

import PageHeadline from "../../components/ui/page-headline";
import Container from "../../components/ui/container";
import { Filters, FieldFilter, FilterButton, SubmitFilter } from "../../components/ui/filters";
import { Accordion } from "../../components/ui/category-accordion";
import ModalDialog from '../../components/ModalDialog';

import Categorizacao from '../../services/Categorizacao';
import Anuncios from '../../services/Anuncios';
import Download from '../../services/Dowload';

const ButtonTable = ({ title, icon, onClick }) => {
    return (
        <button type="button" className="button-table" onClick={onClick}>
            <span>{title}</span> {icon}
        </button>
    )
}
 
function AnunciosByCategories() {
    const refModalDialogCategory = useRef();
    const refModalItems = useRef();
    const matches = useMediaQuery("(min-width:600px)");

    const [selectedAnuncio, setSelectedAnuncio] = useState();
    const [listSelectedCategorization, setListSelectedCategorization] = useState([]);
    const [listActiveCategorization, setListActiveCategorization] = useState([]);
    const [listCategorization, setListCategorization] = useState([]);
    const [category, setCategory] = useState("");
    const [selectCategory, setSelectCategory] = useState('');
    const [beleza, setBeleza] = useState([]);
    const [casa, setCasa] = useState([]);
    const [infantil, setInfantil] = useState([]);
    const [outros, setOutros] = useState([]);
    const [roupas, setRoupas] = useState([]);
    const [mulher, setMulher] = useState([]);
    const [homem, setHomem] = useState([]);
    const [belezalabel, setBelezalabel] = useState('');
    const [casalabel, setCasalabel] = useState('');
    const [infantillabel, setInfantillabel] = useState('');
    const [outroslabel, setOutroslabel] = useState('');
    const [roupaslabel, setRoupaslabel] = useState('');
    const [mulherlabel, setMulherlabel] = useState('');
    const [homemlabel, setHomemlabel] = useState('');
    const [startDateBeleza, setStartDateBeleza] = useState('');
    const [endDateBeleza, setEndDateBeleza] = useState('');
    const [startDateCasa, setStartDateCasa] = useState('');
    const [endDateCasa, setEndDateCasa] = useState('');
    const [startDateInfantil, setStartDateInfantil] = useState();
    const [endDateInfantil, setEndDateInfantil] = useState();
    const [startDateOutros, setStartDateOutros] = useState();
    const [endDateOutros, setEndDateOutros] = useState();
    const [startDateRoupas, setStartDateRoupas] = useState();
    const [endDateRoupas, setEndDateRoupas] = useState();
    const [startDateMulher, setStartDateMulher] = useState();
    const [endDateMulher, setEndDateMulher] = useState();
    const [startDateHomem, setStartDateHomem] = useState();
    const [endDateHomem, setEndDateHomem] = useState();

    const categoryIds = {
      'Beleza e Saúde': { 
        id:'5f2b397f4132b6c235b31545', 
        setState: setBeleza, 
        setLabel: setBelezalabel, 
        valueStartDate: startDateBeleza, 
        valueEndate: endDateBeleza,
        setStartDate: setStartDateBeleza, 
        setEndate: setEndDateBeleza,
      },
      'Casa e Decoração': { 
        id: '5f2b397f4132b6c235b3154a', 
        setState: setCasa, 
        setLabel:setCasalabel, 
        valueStartDate: startDateCasa, 
        valueEndate: endDateCasa,
        setStartDate: setStartDateCasa, 
        setEndate: setEndDateCasa,
      },
      'Infantil': { 
        id:'5f2b397f4132b6c235b314ed', 
        setState: setInfantil, 
        setLabel:setInfantillabel, 
        valueStartDate: startDateInfantil, 
        valueEndate: endDateInfantil,
        setStartDate: setStartDateInfantil, 
        setEndate: setEndDateInfantil,
      },
      'Outros': { 
        id:'5f2b397f4132b6c235b31569', 
        setState: setOutros, 
        setLabel:setOutroslabel, 
        valueStartDate: startDateOutros, 
        valueEndate: endDateOutros,
        setStartDate: setStartDateOutros, 
        setEndate: setEndDateOutros,
      },
      'Roupas e etc': {
        id:'5f2b397f4132b6c235b31490', 
        setState: setRoupas, 
        setLabel:setRoupaslabel, 
        valueStartDate: startDateRoupas, 
        valueEndate: endDateRoupas,
        setStartDate: setStartDateRoupas, 
        setEndate: setEndDateRoupas,
      },
      'Mulher': {
        id:'5f2b397f4132b6c235b31491', 
        setState: setMulher, 
        setLabel:setMulherlabel, 
        valueStartDate: startDateMulher, 
        valueEndate: endDateMulher,
        setStartDate: setStartDateMulher, 
        setEndate: setEndDateMulher,
      },
      'Homem': {
        id:'5f2b397f4132b6c235b314c5', 
        setState: setHomem, 
        setLabel:setHomemlabel, 
        valueStartDate: startDateHomem, 
        valueEndate: endDateHomem,
        setStartDate: setStartDateHomem, 
        setEndate: setEndDateHomem,
      },
    }
  
    const loadCategorization = () => {
      const categorization = new Categorizacao();
    
      categorization
        .list()
        .then((response) => {
          if (response.status === 200) {
            setListCategorization(response.data.payload);
            setListActiveCategorization(response.data.payload);
          }
        })
        .catch((error) =>
          categorization.formatErrorMessage(error.response, 'CALICT'),
        );
    };

    const loadOneAnuncio = async (category, startDate, endDate, setValues) => {
      const anuncios = new Anuncios();

      await anuncios
        .findCoutsAnunciosbySubCategories(category, startDate, endDate, 'shopping')
        .then((response) => {
          console.log(response.data.payload)
          setValues(response.data.payload)
        })
        .catch((error) =>
          anuncios.formatErrorMessage(error.response, 'LCLOA'),
        );
    }

    const openCategorizationModal = (categorySelect) => {
      const oneCategory = listCategorization.filter(c => c.nome === categorySelect);
      setSelectCategory(categorySelect);
      setListActiveCategorization(oneCategory);
      setListSelectedCategorization([]);
  
      refModalDialogCategory.current.openModal();
    };
    const handleCategorizationBack = (oldCategorizationList, index) => {
      const listSelected = listSelectedCategorization.slice(0, index);
      setListSelectedCategorization(listSelected);
  
      setListActiveCategorization(oldCategorizationList);
    };
  
    const handleCategorization = (categorization) => {
      setCategory(categorization.nome);
  
      let listSelected;
      if (categorization.categorias && categorization.categorias.length > 0) {
        setListActiveCategorization(categorization.categorias);
  
        listSelected = listSelectedCategorization;
        listSelected.push({
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
        });
        setListSelectedCategorization(listSelected);
      } else if (
        categorization.tipos_produtos &&
        categorization.tipos_produtos.length > 0
      ) {
        setListActiveCategorization(categorization.tipos_produtos);
  
        listSelected = listSelectedCategorization;
        listSelected.push({
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
        });
        setListSelectedCategorization(listSelected);
      } else if (categorization.produtos && categorization.produtos.length > 0) {
        setListActiveCategorization(categorization.produtos);
  
        listSelected = listSelectedCategorization;
        listSelected.push({
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
        });
        setListSelectedCategorization(listSelected);
      } else {
        listSelected = listSelectedCategorization;
        const lastIndex = listSelected.length > 0 ? listSelected.length - 1 : 0;
  
        if (listSelected[lastIndex] && listSelected[lastIndex].lastLevel) {
          listSelected[lastIndex] = {
            oldCategorizationList: listActiveCategorization,
            categorization: categorization,
            lastLevel: true,
          };
          setListSelectedCategorization(listSelected);
        } else {
          listSelected.push({
            oldCategorizationList: listActiveCategorization,
            categorization: categorization,
            lastLevel: true,
          });
          setListSelectedCategorization(listSelected);
        }
      }
    };
  
    const hasChindren = (categorization) => {
      if (categorization.categorias && categorization.categorias.length > 0) {
        return true;
      } else if (
        categorization.tipos_produtos &&
        categorization.tipos_produtos.length > 0
      ) {
        return true;
      } else if (categorization.produtos && categorization.produtos.length > 0) {
        return true;
      }
  
      return false;
    };

    const onSelectCategorization = async () => {
      const label = listSelectedCategorization.reduce((label, selected, index) => {
        return (label += ` ${index > 0 ? ">" : ""} ${selected.categorization.nome
          }`);
      }, "");

      categoryIds[selectCategory].setLabel(label);
    }

    const getOrdersFile = (value) => {
      const download = new Download();
      setSelectCategory(value);
      
      let lastSelection = 0;
      if(listSelectedCategorization.length > 0){
        lastSelection = listSelectedCategorization[listSelectedCategorization.length -1].categorization._id 
      }

      Swal.fire('Gerando arquivo ... Aguarde');
      Swal.showLoading();
      download.getAnuncioBySubCategoriesFile(
        lastSelection ? lastSelection : categoryIds[value].id,
        categoryIds[value].valueStartDate,
        categoryIds[value].valueEndate,
        'shopping'
        ).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset=utf-8;' }));
        var fileLink = document.createElement('a');
        const newDate  = new Date();

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `subcategorias_${newDate.getDate()}_${newDate.getMonth() + 1}_${newDate.getFullYear()}.csv`);
        document.body.appendChild(fileLink);
  
        Swal.close();
        fileLink.click();
      }).catch(error => {
        Swal.close();
        Swal.fire({
          type: "info",
          html: download.formatErrorMessage(error.response),
          title: "",
        });
      });
    }

    const onFilter = async (e, value) => {
      e.preventDefault();
      setSelectCategory(value);
      
      Swal.fire('Carregando lista ... Aguarde');
      Swal.showLoading();
      if(listSelectedCategorization.length > 0){
        const lastSelection = listSelectedCategorization[listSelectedCategorization.length -1].categorization._id;
        
        await loadOneAnuncio(
          lastSelection, 
          categoryIds[value].valueStartDate, 
          categoryIds[value].valueEndate, 
          categoryIds[value].setState
        );
      } else {
        await loadOneAnuncio(
          categoryIds[value].id, 
          categoryIds[value].valueStartDate, 
          categoryIds[value].valueEndate, 
          categoryIds[value].setState
        );
      }
      Swal.close();
    }

    const clearFilter = async (e, value) => {
      e.preventDefault();
      setSelectCategory(value);
      
      Swal.fire('Carregando lista ... Aguarde');
      Swal.showLoading();

      categoryIds[value].setStartDate('');
      categoryIds[value].setEndate('');
      categoryIds[value].setLabel('');
      
      await loadOneAnuncio(categoryIds[value].id, null, null, categoryIds[value].setState);
     
      Swal.close();
    }

    const loadAllAnuncios = useCallback(async () => {
      Swal.fire('Carregando lista ... Aguarde');
      Swal.showLoading();
      await loadOneAnuncio(categoryIds['Beleza e Saúde'].id, null, null, categoryIds['Beleza e Saúde'].setState);
      await loadOneAnuncio(categoryIds['Casa e Decoração'].id, null, null, categoryIds['Casa e Decoração'].setState);
      await loadOneAnuncio(categoryIds['Infantil'].id, null, null, categoryIds['Infantil'].setState);
      await loadOneAnuncio(categoryIds['Outros'].id, null, null, categoryIds['Outros'].setState);
      await loadOneAnuncio(categoryIds['Roupas e etc'].id, null, null, categoryIds['Roupas e etc'].setState);
      await loadOneAnuncio(categoryIds['Mulher'].id, null, null, categoryIds['Mulher'].setState);
      await loadOneAnuncio(categoryIds['Homem'].id, null, null, categoryIds['Homem'].setState);
      Swal.close();
    },[])
  
    useEffect(() => {
      loadAllAnuncios();
      loadCategorization();
    }, [loadAllAnuncios]);

    return (
        <>
            <Container id="view_list-categories" containerType="fluid">
                <PageHeadline title="Subcategorias Shopping" />
                <Accordion title="Beleza e Saúde">
                    <Filters title="Filtros">
                        <FieldFilter type="date" label="Data Inicial" value={startDateBeleza} onChange={({ target }) => setStartDateBeleza(target.value)} />
                        <FieldFilter type="date" label="Data Final" value={endDateBeleza} onChange={({ target }) => setEndDateBeleza(target.value)} />
                        <FilterButton icon={<ListAltSharpIcon />} text={belezalabel ? belezalabel : "Filtrar Categorias"} onClick={() => openCategorizationModal("Beleza e Saúde")} />

                        <SubmitFilter onClick={(e) => onFilter(e, "Beleza e Saúde")}/>

                        <FilterButton icon={<Close />} text="Limpar Filtros" onClick={(e) => clearFilter(e, "Beleza e Saúde")} />
                        <FilterButton icon={<DescriptionSharpIcon />} text="Exportar .csv" onClick={() => getOrdersFile("Beleza e Saúde")} />
                    </Filters>

                    <Table bordered hover size="lg">
                        <thead>
                            <tr>
                                <th>INCLUSÃO</th>
                                <th>QUANTIDADE</th>
                                <th>ANÚNCIOS</th>
                            </tr>
                        </thead>

                        <tbody>
                              {beleza && beleza.map(b => (
                                <tr>
                                  <td>{b._id.dia + '/' + b._id.mes + '/' + b._id.ano}</td>
                                  <td>{b.count}</td>
                                  <td>
                                    <ButtonTable title="Ver anúncio" icon={<SearchIcon />} 
                                      onClick={() => {setSelectedAnuncio(b.anuncios); refModalItems.current.openModal()}} />
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                    </Table>
                </Accordion>

                <Accordion title="Casa e Decoração">
                    <Filters title="Filtros">
                        <FieldFilter type="date" label="Data Inicial" value={startDateCasa} onChange={({ target }) => setStartDateCasa(target.value)} />
                        <FieldFilter type="date" label="Data Final" value={endDateCasa} onChange={({ target }) => setEndDateCasa(target.value)} />
                        <FilterButton icon={<ListAltSharpIcon />} text={casalabel ? casalabel :"Filtrar Categorias"} onClick={() => openCategorizationModal("Casa e Decoração")} />

                        <SubmitFilter onClick={(e) => onFilter(e, "Casa e Decoração")}/>

                        <FilterButton icon={<Close />} text="Limpar Filtros" onClick={(e) => clearFilter(e, "Casa e Decoração")} />
                        <FilterButton icon={<DescriptionSharpIcon />} text="Exportar .csv" onClick={() => getOrdersFile("Casa e Decoração")} />
                    </Filters>

                    <Table bordered hover size="lg">
                        <thead>
                            <tr>
                                <th>INCLUSÃO</th>
                                <th>QUANTIDADE</th>
                                <th>ANÚNCIOS</th>
                            </tr>
                        </thead>

                        <tbody>
                          {casa && casa.map(c => (
                            <tr>
                                <td>{c._id.dia + '/' + c._id.mes + '/' + c._id.ano}</td>
                                <td>{c.count}</td>
                                <td>
                                  <ButtonTable title="Ver anúncio" icon={<SearchIcon />} 
                                    onClick={() => {setSelectedAnuncio(c.anuncios); refModalItems.current.openModal()}} />
                                </td>
                            </tr>
                          ))}
                        </tbody>
                    </Table>
                </Accordion>

                <Accordion title="Infantil">
                    <Filters title="Filtros">
                        <FieldFilter type="date" label="Data Inicial" value={startDateInfantil} onChange={({ target }) => setStartDateInfantil(target.value)} />
                        <FieldFilter type="date" label="Data Final" value={endDateInfantil} onChange={({ target }) => setEndDateInfantil(target.value)} />
                        <FilterButton icon={<ListAltSharpIcon />} text={infantillabel ? infantillabel : "Filtrar Categorias"} onClick={() => openCategorizationModal("Infantil")} />

                        <SubmitFilter onClick={(e) => onFilter(e, "Infantil")}/>

                        <FilterButton icon={<Close />} text="Limpar Filtros" onClick={(e) => clearFilter(e, "Infantil")} />
                        <FilterButton icon={<DescriptionSharpIcon />} text="Exportar .csv" onClick={() => getOrdersFile("Infantil")} />
                    </Filters>

                    <Table bordered hover size="lg">
                        <thead>
                            <tr>
                                <th>INCLUSÃO</th>
                                <th>QUANTIDADE</th>
                                <th>ANÚNCIOS</th>
                            </tr>
                        </thead>

                        <tbody>
                          {infantil && infantil.map(i => (
                            <tr>
                                <td>{i._id.dia + '/' + i._id.mes + '/' + i._id.ano}</td>
                                <td>{i.count}</td>
                                <td>
                                  <ButtonTable title="Ver anúncio" icon={<SearchIcon />} 
                                    onClick={() => {setSelectedAnuncio(i.anuncios); refModalItems.current.openModal()}} />
                                </td>
                            </tr>
                          ))}
                        </tbody>
                    </Table>
                </Accordion>

                <Accordion title="Outros">
                    <Filters title="Filtros">
                        <FieldFilter type="date" label="Data Inicial" value={startDateOutros} onChange={({ target }) => setStartDateOutros(target.value)} />
                        <FieldFilter type="date" label="Data Final" value={endDateOutros} onChange={({ target }) => setEndDateOutros(target.value)} />
                        <FilterButton icon={<ListAltSharpIcon />} text={outroslabel ? outroslabel : "Filtrar Categorias"} onClick={() => openCategorizationModal("Outros")} />

                        <SubmitFilter onClick={(e) => onFilter(e, "Outros")}/>

                        <FilterButton icon={<Close />} text="Limpar Filtros" onClick={(e) => clearFilter(e, "Outros")} />
                        <FilterButton icon={<DescriptionSharpIcon />} text="Exportar .csv" onClick={() => getOrdersFile("Outros")} />
                    </Filters>

                    <Table bordered hover size="lg">
                        <thead>
                            <tr>
                                <th>INCLUSÃO</th>
                                <th>QUANTIDADE</th>
                                <th>ANÚNCIOS</th>
                            </tr>
                        </thead>

                        <tbody>
                            {outros && outros.map(o => (
                              <tr>
                                <td>{o._id.dia + '/' + o._id.mes + '/' + o._id.ano}</td>
                                <td>{o.count}</td>
                                <td>
                                  <ButtonTable title="Ver anúncio" icon={<SearchIcon />} 
                                    onClick={() => {setSelectedAnuncio(o.anuncios); refModalItems.current.openModal()}} />
                                  </td>
                              </tr>
                            ))}
                        </tbody>
                    </Table>
                </Accordion>

                <Accordion title="Roupas e etc">
                    <Filters title="Filtros">
                        <FieldFilter type="date" label="Data Inicial" value={startDateRoupas} onChange={({ target }) => setStartDateRoupas(target.value)} />
                        <FieldFilter type="date" label="Data Final" value={endDateRoupas} onChange={({ target }) => setEndDateRoupas(target.value)} />
                        {/* <FilterButton icon={<ListAltSharpIcon />} text={roupaslabel ? roupaslabel : "Filtrar Categorias"} onClick={() => openCategorizationModal("Roupas e etc")} /> */}

                        <SubmitFilter onClick={(e) => onFilter(e, "Roupas e etc")}/>

                        <FilterButton icon={<Close />} text="Limpar Filtros" onClick={(e) => clearFilter(e, "Roupas e etc")} />
                        <FilterButton icon={<DescriptionSharpIcon />} text="Exportar .csv" onClick={() => getOrdersFile("Roupas e etc")} />
                    </Filters>

                    <Table bordered hover size="lg">
                        <thead>
                            <tr>
                                <th>INCLUSÃO</th>
                                <th>QUANTIDADE</th>
                                <th>ANÚNCIOS</th>
                            </tr>
                        </thead>

                        <tbody>
                            {roupas && roupas.map(r => (
                              <tr>
                                <td>{r._id.dia + '/' + r._id.mes + '/' + r._id.ano}</td>
                                <td>{r.count}</td>
                                <td>
                                  <ButtonTable title="Ver anúncio" icon={<SearchIcon />} 
                                    onClick={() => {setSelectedAnuncio(r.anuncios); refModalItems.current.openModal()}} />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                    </Table>
                </Accordion>
                

                <Accordion title="Mulher">
                    <Filters title="Filtros">
                        <FieldFilter type="date" label="Data Inicial" value={startDateMulher} onChange={({ target }) => setStartDateMulher(target.value)} />
                        <FieldFilter type="date" label="Data Final" value={endDateMulher} onChange={({ target }) => setEndDateMulher(target.value)} />
                        <FilterButton icon={<ListAltSharpIcon />} text={mulherlabel ? mulherlabel : "Filtrar Categorias"} onClick={() => openCategorizationModal("Mulher")} />

                        <SubmitFilter onClick={(e) => onFilter(e, "Mulher")}/>

                        <FilterButton icon={<Close />} text="Limpar Filtros" onClick={(e) => clearFilter(e, "Mulher")} />
                        <FilterButton icon={<DescriptionSharpIcon />} text="Exportar .csv" onClick={() => getOrdersFile("Mulher")} />
                    </Filters>

                    <Table bordered hover size="lg">
                        <thead>
                            <tr>
                                <th>INCLUSÃO</th>
                                <th>QUANTIDADE</th>
                                <th>ANÚNCIOS</th>
                            </tr>
                        </thead>

                        <tbody>
                            {mulher && mulher.map(r => (
                              <tr>
                                <td>{r._id.dia + '/' + r._id.mes + '/' + r._id.ano}</td>
                                <td>{r.count}</td>
                                <td>
                                  <ButtonTable title="Ver anúncio" icon={<SearchIcon />} 
                                    onClick={() => {setSelectedAnuncio(r.anuncios); refModalItems.current.openModal()}} />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                    </Table>
                </Accordion>

                <Accordion title="Homem">
                    <Filters title="Filtros">
                        <FieldFilter type="date" label="Data Inicial" value={startDateHomem} onChange={({ target }) => setStartDateHomem(target.value)} />
                        <FieldFilter type="date" label="Data Final" value={endDateHomem} onChange={({ target }) => setEndDateHomem(target.value)} />
                        <FilterButton icon={<ListAltSharpIcon />} text={homemlabel ? homemlabel : "Filtrar Categorias"} onClick={() => openCategorizationModal("Homem")} />

                        <SubmitFilter onClick={(e) => onFilter(e, "Homem")}/>

                        <FilterButton icon={<Close />} text="Limpar Filtros" onClick={(e) => clearFilter(e, "Homem")} />
                        <FilterButton icon={<DescriptionSharpIcon />} text="Exportar .csv" onClick={() => getOrdersFile("Homem")} />
                    </Filters>

                    <Table bordered hover size="lg">
                        <thead>
                            <tr>
                                <th>INCLUSÃO</th>
                                <th>QUANTIDADE</th>
                                <th>ANÚNCIOS</th>
                            </tr>
                        </thead>

                        <tbody>
                            {homem && homem.map(r => (
                              <tr>
                                <td>{r._id.dia + '/' + r._id.mes + '/' + r._id.ano}</td>
                                <td>{r.count}</td>
                                <td>
                                  <ButtonTable title="Ver anúncio" icon={<SearchIcon />} 
                                    onClick={() => {setSelectedAnuncio(r.anuncios); refModalItems.current.openModal()}} />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                    </Table>
                </Accordion>
            </Container>

            <ModalDialog
              ref={refModalItems}
              dialogTitle="Produtos"
              onCloseModal={
                <button
                  type="button"
                  className="btn"
                  onClick={() => { refModalItems.current.closeModal() }}
                >
                  {matches ? <CloseIcon /> : <ChevronLeftIcon />}
                </button>
              }
              >
              <div className="_wrap-form">
                {selectedAnuncio && selectedAnuncio.map((object, index) => (
                  <div key={index}>
                    <p style={{ paddingTop: '15px', marginBottom: 0 }}><strong>Produto: </strong><a href={object.url_encurtada} target="_black">{object.titulo}</a></p>
                    <p style={{ marginBottom: 0 }}><strong>Descrição: </strong> {object.descricao}</p>
                    <p style={{ marginBottom: 0 }}><strong>Valor Pontos: </strong> {object.pontos_por.$numberDecimal}</p>
                    <p style={{ paddingBottom: '15px', marginBottom: 0 }}><strong>Valor Taxa: </strong> R$ {object?.taxa_scamb.$numberDecimal || 0}</p>
                  </div>
                ))}
              </div>
            </ModalDialog>

            <ModalDialog
                customMethod={onSelectCategorization}
                dialogTitle="Categorias"
                btnTitle="Aplicar"
                className="_modal-category"
                ref={refModalDialogCategory}
                >
                <span className="_content">
                    <a href="#!" className="tag-selected-radio">
                    {listSelectedCategorization.map(
                        (selectedCategorization, index) => {
                        if (index === 0) {
                            return (
                            <div
                                onClick={() =>
                                handleCategorizationBack(
                                    selectedCategorization.oldCategorizationList,
                                    index
                                )
                                }
                                key={index}
                                className="_title-selected _only"
                            >
                                <FontAwesomeIcon
                                className="icon"
                                icon={faChevronLeft}
                                ></FontAwesomeIcon>
                                <span class="_text">

                                {selectedCategorization.categorization.nome}
                                </span>
                            </div>
                            );
                        } else {
                            return (
                            <div
                                onClick={() =>
                                handleCategorizationBack(
                                    selectedCategorization.oldCategorizationList,
                                    index
                                )
                                }
                                key={index}
                                className="_title-selected _only _multiple"
                            >
                                <span class="_text">

                                {selectedCategorization.categorization.nome}
                                </span>
                            </div>
                            );
                        }
                        }
                    )}
                    </a>

                    <ul id="drilldown">
                    {listActiveCategorization.map((categorization, index) => (
                        <li key={index}>
                        <label>
                            <input
                            type="radio"
                            name="select_category_lvl_1"
                            checked={
                                category === categorization.nome ? "checked" : ""
                            }
                            onChange={() => handleCategorization(categorization)}
                            />
                            <span className="_name">{categorization.nome}</span>
                            {hasChindren(categorization) ? (
                            <div className="icon">
                                <FontAwesomeIcon
                                icon={faChevronRight}
                                ></FontAwesomeIcon>
                            </div>
                            ) : (
                                <></>
                            )}
                        </label>
                        </li>
                    ))}
                    </ul>
                </span>
            </ModalDialog>
        </>
    )
}

export default AnunciosByCategories