import Service from './Service';

export default class Anuncios extends Service {

  constructor() {
    super();
  }

  findAnuncios(filter) {
    return this.axiosInstance.get(`/anuncios?filter=${filter || ''}`);
  }
  findAnunciosByCategories(filter) {
    return this.axiosInstance.get(`/anuncios/categorias?category=${filter}`);
  }

  findCoutsAnunciosbyCategories(startDate, endDate, website) {
    return this.axiosInstance.get(`/anuncios/coutcategorias${website ? '?website=' + website : '?website=scamb'}${startDate ? '&startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}`);
  }

  findCoutsAnunciosbyBrands(search, website) {
    return this.axiosInstance.get(`/anuncios/marcas${website ? '?website=' + website : '?website=scamb'}${search ? '&search=' + search : ''}`);
  }

  findCoutsAnunciosbySubCategories(category, startDate, endDate, website) {
    return this.axiosInstance.get(`/anuncios/coutsubcategorias?categoryid=${category}${website ? '&website=' + website : '&website=scamb'}${startDate ? '&startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}`);
  }

  findAdsByUserSearch({ searchString, limit, category, tag, aleatory, price, points, condition, categoryList, brand, deliveryType, storeUrl, size, website, statusAd, adId }) {
    return this.axiosInstance.get(`anuncios/find/products/?limit=${limit}${searchString ? '&key=' + searchString : ''}${category ? '&category=' + category : ''}${tag ? '&tag=' + tag : ''}${aleatory ? '&aleatory=' + aleatory : ''}${price ? '&price=' + price : ''}${points ? '&points=' + points : ''}${condition ? '&condition=' + condition : ''}${categoryList ? '&categoryList=' + categoryList : ''}${brand ? '&brand=' + brand : ''}${deliveryType ? '&deliveryType=' + deliveryType : ''}${storeUrl ? '&storeUrl=' + storeUrl : ''}${size ? '&size=' + size : ''}${website ? '&website=' + website : ''}${statusAd ? '&statusAd=' + statusAd : ''}${adId ? '&adId=' + adId : ''}`);
  }

  changeStatusAdExternal({ anuncioId, status }) {
    return this.axiosInstance.post(`anuncios/adexternal?anuncioId=${anuncioId}&status=${status}`)
  }

  findAllExternalAds({ website }) {
    return this.axiosInstance.get(`anuncios/adexternal?website=${website}`)
  }

  generateQrCodeImage({ adId }) {
    return this.axiosInstance.post(`anuncios/gerenateqrcode`, { adId: adId });
  }

  exportQrCode({ adId }) {
    return this.axiosInstance.get(`anuncios/exportqrcode/${adId}`, { responseType: 'blob' });
  }

  changeAdStatus({ adId, status }) {
    return this.axiosInstance.post(`anuncios/changestatus`, { adId: adId, status: status });
  }

  findAdById({ adId }) {
    return this.axiosInstance.get(`anuncios/findOne/${adId}`);
  }
}