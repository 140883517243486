import React, { useState } from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import {
    Wrapper,
    Head,
    HeadColumn,
    Column,
    StatusCount,
    ButtonTag,
    ButtonContent,
    Title,
    Text,
    ButtonIcon,
    Content,
    ContentButtons,
    LineTitle,
    TitleLineAction,
    ButtonResultAction,
    ColumnWrapper,
    ButtonTitle,
    ButtonText,
} from "./styles";

export function ResultBlock({ numberCount, orderId, buyerName, orderDate, children }) {

    const [panel, setPanel] = useState(false);

    const handlePanel = () => {
        setPanel((prevState) => prevState = !prevState);
    }

    return (
        <Wrapper className="ResultBlock">
            <Head>
                <HeadColumn>
                    <StatusCount>
                        #{numberCount}
                    </StatusCount>
                    <ButtonTag>
                        <ButtonContent>
                            <Title>Nº Pedido:</Title>
                            <Text>{orderId}</Text>
                        </ButtonContent>
                    </ButtonTag>
                    <ButtonTag>
                        <ButtonContent>
                            <Title>Comprador:</Title>
                            <Text>{buyerName}</Text>
                        </ButtonContent>
                    </ButtonTag>
                    <ButtonTag>
                        <ButtonContent>
                            <Title>Data da Compra:</Title>
                            <Text>{orderDate}</Text>
                        </ButtonContent>
                    </ButtonTag>
                </HeadColumn>
                <HeadColumn>
                    <ButtonIcon type="button" onClick={handlePanel}>
                        {panel
                            ? (<Icon icon={["far", "chevron-up"]} />)
                            : (<Icon icon={["far", "chevron-down"]} />)
                        }
                    </ButtonIcon>
                </HeadColumn>
            </Head>
            {panel && (
                <Content>
                    {children}
                </Content>
            )}
        </Wrapper>
    )
}