import React from "react";

import { ListItem, Title, Value } from "./styles";

function ListBlockItem({ title, bgColor, className, children }) {

    return (
        <ListItem className={`ListBlockItem ${className && className}`} bgColor={bgColor}>
            {title && (
                <Title>{title}</Title>
            )}
            <Value>{children}</Value>
        </ListItem>
    )
}

export default ListBlockItem;