import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faSearch,
    faSignOut,
    faBullhorn,
    faEye,
    faStore,
    faShoppingCart,
    faWallet,
    faCog,
    faUserFriends,
    faCreditCardBlank,
    faIdCard,
    faTimes,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faChevronDown,
    faBars,
    faQuestionCircle as regQuestionCircle,
    faHeart as regHeart,
    faCheck,
    faUniversity,
    faHandHoldingUsd,
    faTags as faTagsR,
    faScroll,
    faShieldAlt,
    faMapMarkerAlt as faMapMarkerAltR,
    faEngineWarning,
    faUserHeadset,
    faHomeAlt,
    faEllipsisH,
    faFilter,
    faShoppingBag as faShoppingBagRegular,
    faListAlt,
    faUser,
    faCreditCard,
    faBoxes,
    faTag,
    faComputerClassic,
    faExternalLink,
    faQrcode,
    faShare,
    faBan,
    faFileInvoice,
} from "@fortawesome/pro-regular-svg-icons";

import { faQuestionCircle, faHeart, faShoppingBag, faBagsShopping, faMapMarkerAlt, faTags, faThumbsUp, faTrashAlt } from "@fortawesome/pro-light-svg-icons";

import { faHomeAlt as faHomeAltS, faMapMarkerCheck, faThumbsUp as faThumbsUpSolid, faCheck as faCheckSolid, faLevelUp, faInfo, faExclamation } from "@fortawesome/pro-solid-svg-icons"

library.add(
    faSearch,
    faSignOut,
    faBullhorn,
    faQuestionCircle,
    regQuestionCircle,
    faEye,
    faHeart,
    regHeart,
    faStore,
    faShoppingCart,
    faWallet,
    faCog,
    faUserFriends,
    faCreditCardBlank,
    faIdCard,
    faTimes,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faChevronDown,
    faBars,
    faShoppingBag,
    faShoppingBagRegular,
    faBagsShopping,
    faMapMarkerAlt,
    faMapMarkerAltR,
    faCheck,
    faCheckSolid,
    faUniversity,
    faHandHoldingUsd,
    faTags,
    faTagsR,
    faScroll,
    faShieldAlt,
    faEngineWarning,
    faUserHeadset,
    faHomeAlt,
    faHomeAltS,
    faEllipsisH,
    faMapMarkerCheck,
    faThumbsUp,
    faThumbsUpSolid,
    faFilter,
    faTrashAlt,
    faListAlt,
    faUser,
    faLevelUp,
    faCreditCard,
    faBoxes,
    faTag,
    faInfo,
    faComputerClassic,
    faExclamation,
    faExternalLink,
    faQrcode,
    faShare,
    faBan,
    faFileInvoice,
)