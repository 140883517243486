import Service from './Service';

export default class cart extends Service {

  insertItenCart({ adId }) {
    return this.axiosInstance.post(`/cart`, { adId },);
  }

  findCart() {
    return this.axiosInstance.get(`/cart`);
  }

  deleteItemCart({ adId }) {
    return this.axiosInstance.delete(`/cart`, { data: { adId } });
  }

  deleteCart() {
    return this.axiosInstance.delete(`/cart/delete`);
  }

  checkScamberBuyerBalance({ scamberEmail }) {
    return this.axiosInstance.put(`/cart`, { scamberEmail });
  }

  applyDescontWithUserPoints({ descontPointsApplied }) {
    return this.axiosInstance.put(`/cart/applydescontpoints`, { descontPointsApplied });
  }

  changeStatusItensCart({ status }) {
    return this.axiosInstance.post(`/cart/changeStatus`, { status });
  }

}