import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { Table } from "react-bootstrap";
import { TextField, useMediaQuery } from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import SearchIcon from "@material-ui/icons/Search";

import AnuncioService from './../../services/Anuncios';
import Download from './../../services/Dowload';

function Anuncios() {
  const matches = useMediaQuery("(min-width:600px)");

  const [listAnuncios, setListAnuncios] = useState([])
  const [activeFilter, setActiveFilter] = useState();

  const { handleSubmit, register, errors, reset } = useForm()

  const loadAnuncio = (search) => {
    const anuncioService = new AnuncioService();

    Swal.fire('Carregando lista ... Aguarde');
    Swal.showLoading();
    anuncioService.findCoutsAnunciosbyBrands(search, 'scamb').then((response) => {
      Swal.close();
      setListAnuncios(response.data.payload);
      console.log(response.data.payload);
    }).catch((error) => {
      Swal.fire({
        type: "info",
        text: anuncioService.formatErrorMessage(error.response),
        title: "",
      });
    })
  }

  const getOrdersFile = () => {
    const download = new Download();

    Swal.fire('Gerando arquivo ... Aguarde');
    Swal.showLoading();
    download.getAnuncioByBrandsFile(activeFilter, 'scamb').then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset=utf-8;' }));
      var fileLink = document.createElement('a');
      const newDate = new Date();

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `produtos_marcas_${newDate.getDate()}_${newDate.getMonth() + 1}_${newDate.getFullYear()}.csv`);
      document.body.appendChild(fileLink);

      Swal.close();
      fileLink.click();
    }).catch(error => {
      Swal.close();
      Swal.fire({
        type: "info",
        html: download.formatErrorMessage(error.response),
        title: "",
      });
    });
  }

  const onFilter = () => {
    loadAnuncio(activeFilter);
  }

  const clearFilter = (e) => {
    e.preventDefault();

    setActiveFilter('');
    loadAnuncio();
  }

  useEffect(() => {
    loadAnuncio(activeFilter);
  }, [])

  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">

          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title">Produtos Cadastrados por Marcas</div>
          </div>

          <div className="_wrap-filter-activity">
            <h2 className="title">Filtrar</h2>
            <div className="_filter-activity">
              <form className="_form _search" onSubmit={handleSubmit(onFilter)}>
                <div className="form-group search">

                  <TextField
                    id="outlined-add-description"
                    label="Filtrar *"
                    variant="outlined"
                    size={matches ? "small" : "small"}
                    name="filter"
                    fullWidth
                    value={activeFilter}
                    onChange={(e) => setActiveFilter(e.target.value)}
                    error={!!errors.qtd_points}
                    inputRef={register()}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      style: { height: '39px' }
                    }}
                  />

                  <button type="submit">
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </form>
              <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div>
              <div className="excel-div">
                <button onClick={getOrdersFile} className="excel-button">Exportar .csv</button>
              </div>
            </div>
          </div>

          <Table bordered hover size="lg">
            <thead>
              <tr>
                <th>MARCA</th>
                <th># DE PRODUTOS CADASTRADOS</th>
                <th># DE PRODUTOS ATIVOS</th>
                <th># DE PRODUTOS VENDIDOS</th>
                <th># DE PRODUTOS INATIVOS</th>
                <th>PREÇO MÉDIO (PONTOS) PRODUTOS ATIVOS</th>
                <th>PREÇO MÉDIO (PONTOS) PRODUTOS VENDIDOS</th>
              </tr>
            </thead>
            <tbody>
              {listAnuncios.map((anuncio, index) => (
                <tr key={index}>
                  <td>{anuncio._id.categoryName ? anuncio._id.categoryName : anuncio._id.customMarca}</td>
                  <td>{anuncio.count}</td>
                  <td>{anuncio.ativos}</td>
                  <td>{anuncio.vendidos}</td>
                  <td>{anuncio.inativos}</td>
                  <td>{anuncio.mediaAtivos}</td>
                  <td>{anuncio.mediaVendidos}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </main>
    </>
  );
}

export default Anuncios;
