import React from "react";

import { Wrapper, Value, TagSymbol } from "./styles.js";

function PricePoints({ value = 0, size = "small", className }) {

    return (

        <Wrapper className={className}>
            <Value size={size}>{value}</Value>
            <TagSymbol size={size}>pts</TagSymbol>
        </Wrapper>
    )
}

export default PricePoints;