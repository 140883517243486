//===========================================================
// #1 - Base Imports
//===========================================================
import React, { useState } from "react";

//===========================================================
// #2 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faPlus, faMinus } from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";


export const AccordionCard = (props) => {

    return (
        <div className="_visible-content">
            {props.children}
        </div>
    )
}

export const Accordion = (props) => {

    const [accordion, setAccordion] = useState(false);

    const handleAccordion = () => setAccordion(!accordion);
    
    return (
        <div className="_accordion">
            <div className="_accordion-head" onClick={handleAccordion}>

            <div className="_visible-content">
                {props.child}
            </div>
                
                <div className="_chevron-icon">
                    <Icon icon={ accordion ? faChevronUp : faChevronDown } />
                </div>

                {props.productStatus === "delivered" && (

                    <div className="_tag delivered">
                        <Icon icon={faCircle} />
                        Entregue
                    </div>

                ) || props.productStatus === "canceled" && (

                    <div className="_tag canceled">
                        <Icon icon={faCircle} />
                        Cancelado
                    </div>

                ) || props.productStatus === "returned" && (

                    <div className="_tag returned">
                        <Icon icon={faCircle} />
                        Devolvido
                    </div>

                ) }

            </div>
            
            {accordion && (
            <div className="_accordion-content">
                {props.children}
            </div>
            )}

            
        </div>
    )
}

export const AccordionInside = (props) => {

    const [accordion, setAccordion] = useState(false);

    const handleAccordion = () => setAccordion(!accordion);

    return (
        <div className="_accordion _inside">
            <div className="_accordion-head" onClick={handleAccordion}>
                <div className="_visible-content">
                    {props.title}
                </div>
                <div className="_chevron-icon">
                    <Icon icon={ accordion ? faMinus : faPlus } />
                </div>
            </div>
            
            {!props.open === accordion && (
            <div className="_accordion-content">
                {props.children}
            </div>
            )}
        </div>
    )
}

export const AccordionContent = (props) => {

    return (
        <div className="_accordion-content _inside" style={{backgroundColor: `${props.bgColor}`}}>
            {props.children}
        </div>
    )
}