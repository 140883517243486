import Service from './Service';

class QuickLink extends Service {

  constructor() {
    super();
  }

  createQuickLink(quicklinkObject, website, type) {
    return this.axiosInstance.post(`/quicklink${website? '?website=' + website: '?website=scamb'}${type? '&type=' + type: '&type=link'}`, quicklinkObject, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  updateQuickLink(quicklinkObject) {
    return this.axiosInstance.put(`/quicklink`, quicklinkObject);
  }

  getAllQuickLinks(filter, website, type) {
    return this.axiosInstance.get(`/quicklink?filter=${filter || ''}${website? '&website=' + website: '&website=scamb'}${type? '&type=' + type: '&type=link'}`);
  }

  getOneQuickLinks(id) {
    return this.axiosInstance.get(`/quicklink/onequicklink/${id}`);
  }

  deleteQuickLink(id){
    return this.axiosInstance.delete(`/quicklink`, { data: { quicklinkid: id }});
  }
}
export default QuickLink;