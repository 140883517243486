//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const ButtonDefault = styled.button`
    background-color: ${ props => props.buttonColor };
    padding: ${props => props.buttonSize === "medium" && "0.719rem 1.264rem" || props.buttonSize === "small" && "0.469rem 0.75rem" };
    font-size: 0.875rem;
    font-weight: 700;
    border: 0;
    border: ${ props => props.styleType === "default" && "0" || props.styleType === "bordered" &&  `1px solid ${props.borderColor}` };
    max-width: ${props => props.maxWidth ? props.maxWidth+"px" : `300px`} ;//300px;
    width: 100%;
    border-radius: 0.25rem;
    color: ${props => props.textColor};
    -webkit-box-shadow: 0 6px 8px 0 rgba(110,110,110,0.1);
    box-shadow: 0 6px 8px 0 rgba(110,110,110,0.1);
    font-family: ${props => props.theme.font.nunito} !important;
    font-weight: ${props => props.theme.fontWeight.fw600};
    
    a {
        color: ${props => props.textColor};
        position: relative;
        display: block;
        font-family: ${props => props.theme.font.nunito} !important;

       
        svg {
          height: 18px !important;
          width: auto !important;

          path {
            fill: ${props => props.iconColor};
          }
        }
    }

    &:disabled {
      background-color: #DBDBDB !important;
      color: #B3B3B3  !important;
      border: 0.0625rem solid #DBDBDB !important;
      
      a {
        color: #B3B3B3  !important;
      }
    }
`;

export const ButtonLabel = styled.button`
    border: 0;
    border-radius: 4px;
    background-color: ${props => props.buttonColor ? props.buttonColor : "transparent"};
    padding: 0;
    
    ${props => props.theme.media("xs", `
      padding: 0.25rem 0.4375rem 0.25rem 0;
    `)}
    
    ${props => props.theme.media("sm", `
      padding: 0.25rem 0.4375rem 0.25rem 0.4375rem;
    `)}
    
    .icon {
      line-height: 0;
      
      svg {
        height: 20px;
        width: auto;
        
        path {
          
          fill: ${props => props.theme.color.blue};
        }
      }
    }
    
    .text-label {
        font-family: ${props => props.theme.font.nunito} !important;
      font-size: 9px;
      font-weight: ${props => props.theme.fontWeight.fw700};
      color: ${props => props.theme.color.gray};
      margin-top: -2px;
    }
  `