import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    span {
        color: ${({ theme }) => theme.color.black};
        font-weight: ${({ theme }) => theme.fontWeight.fw700};
    }
`;

export const Value = styled.span`
    font-size: ${props => props.size === "medium" ? "1.125rem" : "1rem"};
`;

export const TagSymbol = styled.span`
    font-size: 0.75rem;
    display: inline-block;
    margin-right: 0.125rem;
    position: relative;
    top: ${props => props.size === "medium" ? "3px" : "2px"};
`;
