import React, { useRef, useEffect, useState } from 'react';

import { Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import SweetAlert from 'sweetalert';
import $ from 'jquery';
import "jquery-mask-plugin";

import {
    TextField,
    useMediaQuery,
    Grid,
    Select,
} from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import SearchIcon from "@material-ui/icons/Search";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import ScamberService from '../../services/Scamber';
import config from '../../configs/config.json';
import { allBanks } from '../../data/Banks';

import ModalDialog from '../../components/ModalDialog';

function ScamberPartner() {
    const matches = useMediaQuery("(min-width:600px)");

    const refModalAddShowcase = useRef();
    const scamber = new ScamberService();

    const [scambers, setScambers] = useState();
    const [selectedScamber, setselectedScamber] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [filter, setFilter] = useState();
    const [titulo, setTitulo] = useState('');

    const { handleSubmit, register, errors, reset, setValue } = useForm();
    const { handleSubmit: handleSubmitFilter, register: registerFilter, errors: errorsFilter, reset: resetFilter } = useForm();


    const openAddShowcaseModal = () => {
        setIsEdit(false);
        setTitulo('');
        reset({});
        
        refModalAddShowcase.current.openModal();
    }

    const openEditScamberModal = (scamberData) => {
        Swal.fire('Carregando Parceiro... Aguarde');
        Swal.showLoading();
        setIsEdit(true);
        setselectedScamber(scamberData._id);

        scamber.listOneScamberPartner(scamberData._id).then((response) => {
            const data = response.data.payload;

            setValue("name", data.nome);
            setValue("email", data.email);
            setValue("login", data.login);
            setValue("cnpj", data.cnpj);
            setValue("cashback", data.params_parceiro.cashback);
            setValue("comissao", data.params_parceiro.comissao);
            setValue("tipo_comissao", data.params_parceiro.tipo_comissao);
            setValue("telephone", data.params_parceiro.iugu.validationObject.telephone);
            setValue("address", data.params_parceiro.iugu.validationObject.address);
            setValue("cep", data.params_parceiro.iugu.validationObject.cep);
            setValue("city", data.params_parceiro.iugu.validationObject.city);
            setValue("state", data.params_parceiro.iugu.validationObject.state);
            setValue("bank", data.params_parceiro.iugu.validationObject.bank);
            setValue("bank_ag", data.params_parceiro.iugu.validationObject.bank_ag);
            setValue("account_type", data.params_parceiro.iugu.validationObject.account_type);
            setValue("bank_cc", data.params_parceiro.iugu.validationObject.bank_cc);
            setValue("titulo", data.minha_loja.titulo);
            setTitulo(data.minha_loja.titulo);

            Swal.close();
            refModalAddShowcase.current.openModal();
        }).catch(err => {
            Swal.fire({
                type: "info",
                html: scamber.formatErrorMessage(err.response),
                title: "",
            });
        });
    }


    const onFilter =(data) => {
        setFilter(data.filter);
        loadScamber(data.filter);
    }

    const handleAddShowcase = (data) => {

        const formatedData = {
            nome: data.name,
            email: data.email,
            login: data.login ? data.login.normalize('NFD').replace(/[^a-zA-Zs]/g, '').toLowerCase() : undefined,
            cnpj: data.cnpj,
            cashback: data.cashback,
            comissao: data.comissao,
            tipo_comissao: data.tipo_comissao,
            titulo: data.titulo,
            url: data.titulo.normalize('NFD').replace(/[^a-zA-Zs]/g, '').toLowerCase(),
            telephone: data.telephone,
            adressObject : {
                address: data.address,
                cep: data.cep,
                city: data.city,
                state: data.state,
            },
            backObject: {
                bank: data.bank,
                bank_ag: data.bank_ag,
                account_type: data.account_type,
                bank_cc: data.bank_cc,
            }
        };

        if(isEdit) {
            formatedData._id = selectedScamber;
        }

        if(isEdit) {
            Swal.fire('Salvando Parceiro... Aguarde');
            Swal.showLoading();
            scamber.updateScamberPartner(formatedData).then((response) => {
                refModalAddShowcase.current.closeModal();
                reset();
                SweetAlert({
                    title: "",
                    text: response.data.message ? response.data.message : 'Scamber editada com sucesso!',
                    icon: "success",
                }).then(() => {
                    loadScamber(filter);
                });
            });
        } else {
            Swal.fire('Criando Scamber... Aguarde');
            Swal.showLoading();
            SweetAlert({
                title: "Scamber",
                text: `Comfirmar a criação do Scamber Parceiro`,
                icon: "info",
                buttons: [, "Confirmar"],
            }).then(function (isConfirm) {
                if (isConfirm) {
                    scamber.createScamberPartner(formatedData).then((response) => {
                        loadScamber(filter);
                        refModalAddShowcase.current.closeModal();
                        reset();
                        SweetAlert({
                            title: "",
                            text: response.data.message ? response.data.message : 'Scamber incluida com sucesso!',
                            icon: "success",
                        })
                    }).catch((error) => {
                        Swal.fire({
                            type: "info",
                            html: scamber.formatErrorMessage(error.response),
                            title: "",
                        });
                    });
                }
            });
        }
    }

    const handleActiveProducts = (products) => {
        const activesProducts = products.filter(product => product.ativo && !product.dados_compra);
        
        return activesProducts.length;
      }

    const loadScamber = (filter) => {
        Swal.fire('Carregando Parceiros ... Aguarde');
        Swal.showLoading();
        scamber.listScamberPartner(filter).then((response) => {
            Swal.close();
            setScambers(response.data.payload);
        }).catch((error) => {
            Swal.fire({
                type: "info",
                html: scamber.formatErrorMessage(error.response),
                title: "",
            });
        });
    }

    useEffect(() => {
        loadScamber();
    }, []);

    useEffect(() => {
        $('.cnpj').mask('00.000.000/0000-00', { reverse: true });
        $('.telephone').mask('(00) 00000-0000');
        $('.cep').mask('00000-000');
        $('.bank_cc').mask('#-0', { reverse: true });
    });

    const getHost = () => {

        if (config.nodejs.server.indexOf('localhost') !== -1) {
            return `https://d1.scamb.com.vc`
        } else if (config.nodejs.server.indexOf('/adm-dev') !== -1 && config.nodejs.server.indexOf('/adm-dev2') === -1 ) {
            return `https://d1.scamb.com.vc`
        } else if (config.nodejs.server.indexOf('/adm-dev2') !== -1) {
            return `https://d2.scamb.com.vc`
        } else if (config.nodejs.server.indexOf('apihml') !== -1 && config.nodejs.server.indexOf('apihml2') === -1 ) {
            return `https://h1.scamb.com.vc`
        } else if (config.nodejs.server.indexOf('apihml2') !== -1) {
            return `https://h2.scamb.com.vc`
        } else {
            return `https://scamb.com.vc`
        }
    }


    const clearFilter = (e) => {
        e.preventDefault();

        setFilter('');
        loadScamber();
    }

    return (
        <>
            <main role="main" id="view_scambers">
                <div className="_fluid-container">
                    <div className="_page-section">
                        <div className="icon">
                            <SubjectIcon />
                        </div>
                        <div className="title"> Parceiros</div>
                    </div>

                    <div className="_wrap-filter-activity">
                        <h2 className="title">Filtrar</h2>
                        <div className="_filter-activity">
                            <form className="_form _search" onSubmit={handleSubmitFilter(onFilter)}>
                                <div className="form-group search">

                                    <TextField
                                        id="filterShowcase"
                                        label="Filtrar *"
                                        variant="outlined"
                                        size={matches ? "small" : "small"}
                                        name="filter"
                                        fullWidth
                                        value={filter}
                                        onChange={(e) => setFilter(e.target.value)}
                                        error={!!errors.qtd_points}
                                        inputRef={registerFilter()}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{
                                            style: { height: '39px' }
                                        }}
                                    />

                                    <button type="submit">
                                        <SearchIcon></SearchIcon>
                                    </button>
                                </div>
                            </form>
                            <div className="excel-div">
                                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
                            </div>
                            <div className="excel-div">
                                <button className="excel-button" onClick={() => openAddShowcaseModal()}>Incluir Parceiro</button>
                            </div>
                        </div>
                    </div>

                    <div className="_wrap-table">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                <th scope={"col"}>NOME</th>
                                <th scope={"col"}>EMAIL</th>
                                <th scope={"col"}>LOGIN</th>
                                <th scope={"col"}>CNPJ</th>
                                <th scope={"col"}>NOME DA LOJA</th>
                                <th scope={"col"}>URL DA LOJA</th>
                                <th scope={"col"}>QUANTIDADE DE PRODUTOS CADASTRADOS</th>
                                <th scope={"col"}>QUANTIDADE DE PRODUTOS ATIVOS</th>
                                <th scope={"col"}>CONTA VALIDADA IUGU?</th>
                                <th scope={"col"}>VISUALIZAR CONTA</th>
                                </tr>
                            </thead>
                            <tbody>
                                {scambers && scambers.map(scamber =>
                                    <tr key={scamber._id}>
                                        <td>{scamber.nome}</td>
                                        <td>{scamber.email}</td>
                                        <td>{scamber.login}</td>
                                        <td>{scamber.cnpj}</td>
                                        <td>{scamber?.minha_loja?.titulo}</td>
                                        {scamber?.minha_loja?.url 
                                            ? <td><a target='_blank' href={`${getHost()}/loja/${scamber?.minha_loja?.url}`}>{`${getHost()}/loja/${scamber?.minha_loja?.url}`}</a></td>
                                            : <td> </td>
                                        }
                                        <td>{scamber?.anuncios.length}</td>
                                        <td>{handleActiveProducts(scamber?.anuncios)}</td>
                                        <td>{scamber.params_parceiro?.iugu?.validation ? 'Sim' : 'Não'}</td>
                                        <td>
                                            <Button
                                                className={"btn-step btn-edit-table"}
                                                color="primary"
                                                autoFocus
                                                style={{ height: matches ? 35 : 40 }}
                                                onClick={() => openEditScamberModal(scamber)}
                                            >
                                                Visualizar
                                            </Button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>


            <ModalDialog
                ref={refModalAddShowcase}
                dialogTitle="Incluir Parceiro"
                onCloseModal={
                    <button
                        type="button"
                        className="btn"
                        onClick={() => {
                            reset({}); refModalAddShowcase.current.closeModal()
                        }}
                    >
                        {matches ? <CloseIcon /> : <ChevronLeftIcon />}
                    </button>
                }>
                <div className="_wrap-form">
                    <form className="_form" onSubmit={handleSubmit(handleAddShowcase)}>
                        <h1 className="_content-title">Preencha as informações para incluir um parceiro</h1>

                        <div id="view_create-ad">
                            <section className="form-body">
                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-add-title"
                                            label="Nome *"
                                            type="text"
                                            variant="outlined"
                                            size={matches ? "small" : "small"}
                                            name="name"
                                            fullWidth
                                            helperText={!!errors.name && "Campo não pode ficar em branco"}
                                            error={!!errors.name}
                                            inputRef={register({
                                                required: true,
                                            })}
                                            inputProps={{
                                                maxLength: 40,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-add-title"
                                            label="Email *"
                                            type="text"
                                            variant="outlined"
                                            size={matches ? "small" : "small"}
                                            name="email"
                                            fullWidth
                                            disabled={isEdit}
                                            helperText={!!errors.email && "Campo não pode ficar em branco"}
                                            error={!!errors.email}
                                            inputRef={register({
                                                required: true,
                                            })}
                                            inputProps={{
                                                maxLength: 40,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-add-title"
                                            label="Login *"
                                            type="text"
                                            variant="outlined"
                                            size={matches ? "small" : "small"}
                                            name="login"
                                            fullWidth
                                            disabled={isEdit}
                                            helperText={!!errors.login && "Campo não pode ficar em branco"}
                                            error={!!errors.login}
                                            inputRef={register({
                                                required: true,
                                            })}
                                            inputProps={{
                                                maxLength: 40,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-add-title"
                                            label="CNPJ *"
                                            type="text"
                                            variant="outlined"
                                            size={matches ? "small" : "small"}
                                            name="cnpj"
                                            fullWidth
                                            disabled={isEdit}
                                            helperText={!!errors.cnpj && "Campo não pode ficar em branco"}
                                            error={!!errors.cnpj}
                                            inputRef={register({
                                                required: true,
                                            })}
                                            inputProps={{
                                                className: 'cnpj'
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <h1 className="_content-title">Dados da Loja</h1>
                                        <div className="form-group">
                                            <TextField
                                                id="outlined-name"
                                                label={"Nome da loja *"}
                                                type="text"
                                                onChange={(e)=>setTitulo(e.target.value)}
                                                helperText={!!errors.titulo && "Campo não pode ficar em branco"}
                                                variant={"outlined"}
                                                size={matches ? "small" : "medium"}
                                                name="titulo"
                                                error={!!errors.titulo}
                                                fullWidth
                                                inputProps={{ className: "titulo" }}
                                                inputRef={register({
                                                    required: true,
                                                })}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <h1 className="_content-title"><b>URL da loja sera:</b> {"https://marcas.scamb.com.vc/loja/" + titulo.normalize('NFD').replace(/[^a-zA-Zs]/g, '').toLowerCase()}</h1>
                                        </div>
                                    </Grid>

                                    <Grid item md={12} xs={12}>
                                        <TextField
                                            id="outlined-login"
                                            label="CashBack *"
                                            type="number"
                                            variant="outlined"
                                            size="small"
                                            name="cashback"
                                            helperText={!!errors.cashback && "Campo não pode ficar em branco"}
                                            error={!!errors.cashback}
                                            fullWidth
                                            inputRef={register({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={6} xs={6}>
                                        <TextField
                                            id="outlined-login"
                                            label="Comissão *"
                                            type="number"
                                            variant="outlined"
                                            size="small"
                                            name="comissao"
                                            helperText={!!errors.comissao && "Campo não pode ficar em branco"}
                                            error={!!errors.comissao}
                                            fullWidth
                                            disabled={isEdit}
                                            inputRef={register({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={6} xs={6} >
                                        <FormControl variant="outlined" size="small" fullWidth>
                                            <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                                                Tipo de Comissão *
                                            </InputLabel>
                                            <Select
                                                native
                                                label="Tipo de Comissão"
                                                name="tipo_comissao"
                                                error={!!errors.tipo_comissao}
                                                disabled={isEdit}
                                                inputRef={register({
                                                    required: true
                                                })}
                                                inputProps={{
                                                    name: "tipo_comissao",
                                                    id: "outlined-transaction"
                                                }}
                                            >
                                                <option value="percentual">Porcentagem</option>
                                                <option value="valor">Valor</option>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={12} xs={12}>
                                        <h1 className="_content-title">Endereço do Parceiro</h1>
                                        <TextField
                                            id="outlined-login"
                                            label="Endereço *"
                                            type="text"
                                            variant="outlined"
                                            size="small"
                                            name="address"
                                            helperText={!!errors.address && "Campo não pode ficar em branco"}
                                            error={!!errors.address}
                                            fullWidth
                                            disabled={isEdit}
                                            inputRef={register({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={6} xs={6}>
                                        <TextField
                                            id="outlined-login"
                                            label="Estado *"
                                            type="text"
                                            variant="outlined"
                                            size="small"
                                            name="state"
                                            helperText={!!errors.state && "Campo não pode ficar em branco"}
                                            error={!!errors.state}
                                            fullWidth
                                            disabled={isEdit}
                                            inputRef={register({
                                                required: true,
                                            })}
                                            inputProps={{
                                                maxLength: 2,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={6} xs={6}>
                                        <TextField
                                            id="outlined-login"
                                            label="Cidade *"
                                            type="text"
                                            variant="outlined"
                                            size="small"
                                            name="city"
                                            helperText={!!errors.city && "Campo não pode ficar em branco"}
                                            error={!!errors.city}
                                            fullWidth
                                            disabled={isEdit}
                                            inputRef={register({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={12} xs={12}>
                                        <TextField
                                            id="outlined-login"
                                            label="CEP *"
                                            type="text"
                                            variant="outlined"
                                            size="small"
                                            name="cep"
                                            helperText={!!errors.cep && "Campo não pode ficar em branco"}
                                            error={!!errors.cep}
                                            fullWidth
                                            disabled={isEdit}
                                            inputRef={register({
                                                required: true,
                                            })}
                                            inputProps={{ className: 'cep' }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={12} xs={12}>
                                        <h1 className="_content-title">Telefone do Parceiro</h1>
                                        <TextField
                                            id="outlined-login"
                                            label="Telefone *"
                                            type="text"
                                            variant="outlined"
                                            size="small"
                                            name="telephone"
                                            helperText={!!errors.telephone && "Campo não pode ficar em branco"}
                                            error={!!errors.telephone}
                                            fullWidth
                                            inputRef={register({
                                                required: true,
                                            })}
                                            inputProps={{ className: 'telephone' }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={12} xs={12}>
                                        <h1 className="_content-title">Informações Bancarias do Parceiro</h1>
                                    </Grid>

                                    <Grid item md={8} xs={8} >
                                        <FormControl variant="outlined" size="small" fullWidth>
                                            <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                                                Banco *
                                            </InputLabel>
                                            <Select
                                                native
                                                label="Banco *"
                                                name="bank"
                                                error={!!errors.bank}
                                                disabled={isEdit}
                                                inputRef={register({
                                                    required: true
                                                })}
                                                inputProps={{
                                                    name: "bank",
                                                    id: "outlined-transaction"
                                                }}
                                            >
                                                <option value=""></option>
                                                {allBanks.map(bank => (
                                                    <option value={bank}>{bank}</option>
                                                ))}
                                                
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={4} xs={4}>
                                        <TextField
                                            id="outlined-login"
                                            label="Agência *"
                                            type="text"
                                            variant="outlined"
                                            size="small"
                                            name="bank_ag"
                                            helperText={!!errors.bank_ag && "Campo não pode ficar em branco"}
                                            error={!!errors.bank_ag}
                                            fullWidth
                                            disabled={isEdit}
                                            inputRef={register({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={6} xs={6} >
                                        <FormControl variant="outlined" size="small" fullWidth>
                                            <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                                                Tipo de Conta *
                                            </InputLabel>
                                            <Select
                                                native
                                                label="Tipo de Conta *"
                                                name="account_type"
                                                error={!!errors.account_type}
                                                disabled={isEdit}
                                                inputRef={register({
                                                    required: true
                                                })}
                                                inputProps={{
                                                    name: "account_type",
                                                    id: "outlined-transaction"
                                                }}
                                            >
                                                <option value="Corrente">Corrente</option>
                                                <option value="Poupança">Poupança</option>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={6} xs={6}>
                                        <TextField
                                            id="outlined-login"
                                            label="Número da Conta *"
                                            type="text"
                                            variant="outlined"
                                            size="small"
                                            name="bank_cc"
                                            helperText={!!errors.bank_cc && "Campo não pode ficar em branco"}
                                            error={!!errors.bank_cc}
                                            fullWidth
                                            disabled={isEdit}
                                            inputRef={register({
                                                required: true,
                                            })}
                                            inputProps={{ className: 'bank_cc' }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Button
                                    type="submit"
                                    className="btn-step"
                                    style={{ height: matches ? 35 : 35, marginBottom: '20px' }}
                                >
                                    Concluir
                                </Button>
                            </section>
                        </div>
                    </form>
                </div>
            </ModalDialog>
        </>
    )
}

export default ScamberPartner;