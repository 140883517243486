import React from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import replaceAll from "string.prototype.replaceall";
import PricePoints from "../../Display/price/points";

import {
    Product,
    WrapperProductImage,
    ProductImage,
    Image,
    AdWeight,
    Content,
    WrapperColumns,
    Column,
    Title,
    Pricing,
    Equivalent,
    RealPrice,
    ButtonAddToCart,
    CheckAddToCart,
    Bottom,
    StoreAvatar,
    WrapAvatar,
    StoreInfo,
    StoreTitle,
    StoreLocation
} from "./styles";


function limitTitle(text = "") {
    let setText = "";
    const textArray = [];
    const textToArray = text.split(" ");

    if (textToArray.length > 6) {
        const sl = textToArray.slice(0, 6);
        textArray.push(sl);
        const joinToStr = textArray.join("|");
        const formatText = replaceAll(joinToStr, ",", " ");

        setText += formatText;

    } else {
        setText += text;
    }

    return setText || "...";
}

export const ProductBox = ({
    image,
    productTitle,
    adWeight,
    productPricePoints,
    productPrice,
    gridMargin,
    alredyInCart,
    handleAddItenCart
}) => {

    return (
        <Product margin={gridMargin}>
            <WrapperProductImage>
                <ProductImage>
                    <Image src={image} imageAlt={productTitle} />
                </ProductImage>
                {adWeight && (
                    <AdWeight>
                        {adWeight}
                    </AdWeight>
                )}
            </WrapperProductImage>

            <Content>
                <Title>
                    {productTitle ? limitTitle(productTitle) : "..."}
                </Title>

                <WrapperColumns>

                    <Column>
                        <Pricing>
                            <RealPrice> R$ {parseFloat(productPrice).toFixed(2)} </RealPrice>
                            {/* <PricePoints value={productPricePoints} /> */}
                        </Pricing>

                        {/* <Equivalent>
                            Equivalente a
                            <RealPrice> R$ {productPrice} </RealPrice>
                        </Equivalent> */}
                    </Column>

                    <Column>
                        <ButtonAddToCart type="button">
                            {alredyInCart && (
                                <CheckAddToCart>
                                    <Icon icon={["fas", "check"]} />
                                </CheckAddToCart>
                            )}
                            <Icon icon={["fal", "shopping-bag"]} onClick={handleAddItenCart} />
                        </ButtonAddToCart>
                    </Column>
                </WrapperColumns>

            </Content>

            {/* <Bottom>
                <WrapAvatar>
                    <StoreAvatar src={sellerAvatar ? sellerAvatar : "https://via.placeholder.com/250x250"} />
                </WrapAvatar>
                <StoreInfo>
                    <StoreTitle>{sellerTitle ? sellerTitle : "..."}</StoreTitle>
                    <StoreLocation>{sellerLocation ? sellerLocation : "..."}</StoreLocation>
                </StoreInfo>
            </Bottom> */}
        </Product>
    )
}

export default ProductBox;