import React, { useEffect, useState, useCallback, useRef } from 'react';
import Swal from 'sweetalert2';
import { Button } from "react-bootstrap";
import { TextField, Grid, } from "@material-ui/core";
import SubjectIcon from '@material-ui/icons/Subject';
import { useForm } from 'react-hook-form';

import GeneralParametersService from '../services/GeneralParameters';

import ModalDialog from '../components/ModalDialog';

function GeneralParameters() {
  const [generalParameters, setGeneralParameters] = useState([]);
  const [editGeneralParameter, setEditGeneralParameter] = useState();

  const refModalEditGeneralParameters = useRef();

  const { handleSubmit, register, setValue, errors } = useForm();

  const handleOpenModalEditGeneralParameters = ({ parameter }) => {
    setEditGeneralParameter(parameter);

    setValue('cashbackBuyer', parameter.cashback_comprador);
    setValue('cashbackSeller', parameter.cashback_vendedor);
    setValue('commission', parameter.comissao);
    setValue('descontPoints', parameter.percentual_desconto_pontos);
    setValue('vlConversionPoints', parameter.valor_conversao_ponto);
    setValue('vlConversionMoney', parameter.valor_conversao_dinheiro);

    refModalEditGeneralParameters.current.openModal();
  }

  const editGeneralParameters = (data) => {
    const generalParametersService = new GeneralParametersService();

    Swal.fire('Editando parâmetros gerais... Aguarde');
    Swal.showLoading();

    generalParametersService.editGeneralParameters({
      generalParametersId: editGeneralParameter._id,
      parameters: data,
    }).then(() => {
      Swal.fire({
        title: "",
        text: "Paramêtros gerais atualizados com sucesso!",
        type: "success",
      }).then(() => {
        refModalEditGeneralParameters.current.closeModal();
        getAllParameters();
      });
    }).catch(err => {
      Swal.fire({
        type: 'error',
        html: generalParametersService.formatErrorMessage(err.response),
        title: '',
      });
    })
  }

  const getAllParameters = () => {
    const generalParametersService = new GeneralParametersService();

    Swal.fire('Buscando parâmetros gerais... Aguarde');
    Swal.showLoading();

    generalParametersService.listGeneralParameters().then(response => {
      setGeneralParameters(response.data.payload);
      Swal.close();
    }).catch(err => {
      Swal.close();
      Swal.fire({
        type: 'error',
        html: generalParametersService.formatErrorMessage(err.response),
        title: '',
      });
    })
  }

  const handleInitialValue = useCallback(() => {
    getAllParameters();
  }, []);

  useEffect(() => {
    handleInitialValue()
  }, [handleInitialValue])


  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">
          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title"> Paramêtros gerais</div>
          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope={"col"}>Cashback Comprador</th>
                <th scope={"col"}>Cashback Vendedor</th>
                <th scope={"col"}>Comissão</th>
                <th scope={"col"}>Desconto em Pontos</th>
                <th scope={"col"}>Conversão para pontos</th>
                <th scope={"col"}>Conversão para dinheiro</th>
                <th scope={"col"}>Editar</th>
              </tr>
            </thead>
            <tbody>
              {generalParameters && generalParameters.map((register) => (
                <tr key={register._id}>
                  <td>{register.cashback_comprador} %</td>
                  <td>{register.cashback_vendedor} %</td>
                  <td>{register.comissao} %</td>
                  <td>{register.percentual_desconto_pontos} %</td>
                  <td>R$ {register.valor_conversao_ponto}</td>
                  <td>R$ {register.valor_conversao_dinheiro}</td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: 45 }}
                      onClick={() => handleOpenModalEditGeneralParameters({ parameter: register })}
                    >
                      Editar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>

      <ModalDialog
        dialogTitle="Editar paramêtros gerais"
        className="_modal-category GridProducts"
        maxWidth="sm"
        ref={refModalEditGeneralParameters}
      >
        <div className="select-product-container">
          <div className="search-products-container">
            <div className="_wrap-form" style={{ marginBottom: '20px', marginTop: '20px' }}>
              <form className="_form" onSubmit={handleSubmit(editGeneralParameters)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Cashback Comprador"
                      type="number"
                      variant="outlined"
                      size={"small"}
                      name="cashbackBuyer"
                      fullWidth
                      helperText={!!errors.cashbackBuyer && "Campo não pode ficar em branco"}
                      error={!!errors.cashbackBuyer}
                      inputRef={register({ required: true })}
                      InputProps={{ inputProps: { min: 0, max: 100 } }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Cashback Vendedor"
                      type="number"
                      variant="outlined"
                      size={"small"}
                      name="cashbackSeller"
                      fullWidth
                      helperText={!!errors.cashbackSeller && "Campo não pode ficar em branco"}
                      error={!!errors.cashbackSeller}
                      inputRef={register({ required: true })}
                      InputProps={{ inputProps: { min: 0, max: 100 } }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Comissão"
                      type="number"
                      variant="outlined"
                      size={"small"}
                      name="commission"
                      fullWidth
                      helperText={!!errors.commission && "Campo não pode ficar em branco"}
                      error={!!errors.commission}
                      inputRef={register({ required: true })}
                      InputProps={{ inputProps: { min: 0, max: 100 } }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Desconto em Pontos"
                      type="number"
                      variant="outlined"
                      size={"small"}
                      name="descontPoints"
                      fullWidth
                      helperText={!!errors.descontPoints && "Campo não pode ficar em branco"}
                      error={!!errors.descontPoints}
                      inputRef={register({ required: true })}
                      InputProps={{ inputProps: { min: 0, max: 100 } }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Valor de conversão para pontos"
                      type="number"
                      variant="outlined"
                      size={"small"}
                      name="vlConversionPoints"
                      fullWidth
                      helperText={!!errors.vlConversionPoints && "Campo não pode ficar em branco"}
                      error={!!errors.vlConversionPoints}
                      inputRef={register({ required: true })}
                      InputProps={{ inputProps: { step: 'any' } }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Valor de conversão para real"
                      type="number"
                      step="0.1"
                      variant="outlined"
                      size={"small"}
                      name="vlConversionMoney"
                      fullWidth
                      helperText={!!errors.vlConversionMoney && "Campo não pode ficar em branco"}
                      error={!!errors.vlConversionMoney}
                      inputRef={register({ required: true })}
                      InputProps={{ inputProps: { step: 'any' } }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  className="btn-step"
                  style={{ height: 35, marginBottom: '20px' }}
                >
                  Concluir
                </Button>
              </form>
            </div>
          </div>
        </div>
      </ModalDialog>
    </>
  )
}

export default GeneralParameters;