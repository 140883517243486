import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import styled from 'styled-components';

import { TextField, Grid, Select, InputAdornment } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import List from '@material-ui/core/List';
import ButtonMaterial from '@material-ui/core/Button';

import SearchIcon from '@material-ui/icons/Search';
import SubjectIcon from '@material-ui/icons/Subject';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { ResultBlock } from "./components/result-block/block";
import { ResultBlockTags, ButtonContentTag } from "./components/result-block/tags";
import { ResultBlockActions, ButtonAction } from "./components/result-block/actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/pro-light-svg-icons";

import ModalDialog from '../../components/ModalDialog';
import GridProduct from '../../components/GridProduct';
import ProductCard from '../../components/ProductCard';

import AdTransferService from '../../services/AdTransfer';
import ScamberService from '../../services/Scamber';
import Domain from '../../services/Domain';
import Categorizacao from '../../services/Categorizacao';
import AnuncioService from '../../services/Anuncios';
import TagService from '../../services/Tag';
import PlugNotasService from '../../services/PlugNotas';

import config from '../../configs/config.json';

const WrapTable = styled.div`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr {
    td {
      color: #14233e;
      
      a {
        color: #14233e;

        svg {

          width: 12px !important;
          height: auto;
          position: relative;
          top: -1px;

          path {
            fill: #14233e;
          }
        }
      }
    }
  }
`;

export default function TrabsferAd() {
  const anuncioService = new AnuncioService();

  const [registros, setRegistros] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedTransfer, setSelectedTransfer] = useState();
  const [stores, setStores] = useState([]);
  const [selectedScamberOrigin, setSelectedScamberOrigin] = useState();
  const [selectedScamberDestiny, setSelectedScamberDestiny] = useState();
  const [category, setCategory] = useState("");
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState({ nome: "" });
  const [conditions, setConditions] = useState([]);
  const [condition, setCondition] = useState({ nome: "" });
  const [listSelectedCategorization, setListSelectedCategorization] = useState([]);
  const [listActiveCategorization, setListActiveCategorization] = useState([]);
  const [allCategorizationLabel, setAllCategorizationLabel] = useState("");
  const [listCategorization, setListCategorization] = useState([]);
  const [searchAds, setSearchAds] = useState();
  const [limit, setLimit] = useState(8);
  const [searchKey, setSearchKey] = useState("");
  const [deliveryType, setDeliveryType] = useState();
  const [storeUrl, setStoreUrl] = useState();
  const [points, setPoints] = useState();
  const [price, setPrice] = useState();
  const [selectedAds, setSelectedAds] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [soldAndReturnItens, setSoldAndReturnItens] = useState([]);
  const [returnItens, setReturnItens] = useState([]);
  const [notReturnItens, setNotReturnItens] = useState([]);

  const refModalAddTransfer = useRef();
  const refModalReturnTransfer = useRef();
  const refModalDialogCategory = useRef();
  const refModalDialogSelectProduct = useRef();
  const refModalTransferDetail = useRef();

  const { handleSubmit, register, errors, reset, setValue } = useForm();
  const { handleSubmit: handleSubmitReturnTransfer, register: registerReturnTransfer } = useForm();
  const { handleSubmit: handleSubmitSearchFilter, register: registerSearchFilter, reset: resetSearchFilter, errors: errorSearchFilter } = useForm();


  const handleOpenModalAddTransfer = () => {
    setSelectedScamberOrigin();
    setSelectedScamberDestiny();
    setIsEdit(false);
    reset({});

    refModalAddTransfer.current.openModal();
  }

  const handleScamebrOrigin = (e, store) => {
    setSelectedScamberOrigin(store);
  };

  const handleScamebrDestiny = (e, store) => {
    setSelectedAds();
    setSelectedScamberDestiny(store);
  };

  const handleSearchTransfer = ({ search }) => {
    Swal.fire('Carregando Transferências ... Aguarde');
    const adTransferService = new AdTransferService();

    adTransferService.listAllAdTransfer({ search: search }).then((response) => {
      setRegistros(response.data.payload);
      Swal.close();
    }).catch((err) => {
      Swal.fire({
        type: 'error',
        html: adTransferService.formatErrorMessage(err.response),
        title: '',
      });
    });
  };

  const openEditTransferModal = ({ adTransfer }) => {
    const adTransferService = new AdTransferService();

    Swal.fire('Carregando Transferência... Aguarde');
    Swal.showLoading();
    setIsEdit(true);
    setSelectedTransfer(adTransfer._id);
    setSelectedAds();

    adTransferService.getOneTranfer({ adTransferId: adTransfer._id }).then((response) => {
      const data = response.data.payload;
      const anunciosOriginal = data.anuncios.map(anuncio => anuncio.original);

      setSelectedScamberOrigin(data.scamber_origem);
      setSelectedScamberDestiny(data.scamber_destino);
      setSelectedAds(anunciosOriginal);

      // setValue('commission', data.comissao);
      // setValue('initialDate', format(new Date(data.started_date), 'yyyy-MM-dd'));
      // setValue('endDate', format(new Date(data.expired_date), 'yyyy-MM-dd'));

      Swal.close();
      refModalAddTransfer.current.openModal();
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: adTransferService.formatErrorMessage(err.response),
        title: "",
      });
    });
  }

  const openTransferDetailModal = ({ adTransferId }) => {
    const adTransferService = new AdTransferService();

    Swal.fire('Carregando Transferência... Aguarde');
    Swal.showLoading();

    adTransferService.getOneTranfer({ adTransferId: adTransferId }).then((response) => {
      const data = response.data.payload;
      const soldItens = data.anuncios.filter(anuncio => anuncio.retornado && anuncio.vendedor_pago);
      const returnItens = data.anuncios.filter(anuncio => anuncio.retornado === true && !anuncio.vendedor_pago);
      const notReturnItens = data.anuncios.filter(anuncio => !anuncio.retornado === true && !anuncio.vendedor_pago === true);

      setSoldAndReturnItens(soldItens);
      setReturnItens(returnItens);
      setNotReturnItens(notReturnItens);

      Swal.close();
      refModalTransferDetail.current.openModal();
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: adTransferService.formatErrorMessage(err.response),
        title: "",
      });
    });
  }

  const createInvoice = ({ adTransferId }) => {
    const plugNotasService = new PlugNotasService();

    Swal.fire('Criando Nota Fiscal... Aguarde');
    Swal.showLoading();

    plugNotasService.createEntryInvoice({ adTransferId: adTransferId }).then((response) => {
      handleSearchTransfer({});

      Swal.fire({
        title: "",
        text: response.data.message,
        type: "success",
      });
    }).catch(err => {
      Swal.fire({
        type: "info",
        text: plugNotasService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }

  const clearFilter = () => {
    setSearch("");
    handleSearchTransfer({});
  }

  const getHost = ({ productId }) => {

    if (config.nodejs.server.indexOf('localhost') !== -1) {
      return `https://d1.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('/adm-dev') !== -1 && config.nodejs.server.indexOf('/adm-dev2') === -1) {
      return `https://d1.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('/adm-dev2') !== -1) {
      return `https://d2.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('apihml') !== -1 && config.nodejs.server.indexOf('apihml2') === -1) {
      return `https://h1.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('apihml2') !== -1) {
      return `https://h2.scamb.com.vc`
    } else {
      return `https://scamb.com.vc`
    }

  }

  const handleOpenModalReturnTransfer = ({ transfer }) => {
    setSelectedTransfer(transfer);
    refModalReturnTransfer.current.openModal();
  }

  const handleGenerateTags = ({ anuncios }) => {
    const tagService = new TagService();

    const justIdsAds = [];
    anuncios.map(ad => {
      return justIdsAds.push(ad.replicado);
    });

    Swal.fire('Gerando etiquetas... Aguarde');
    tagService.generateTags({ anuncios: justIdsAds }).then((response) => {
      var blob = new Blob([response.data], { type: "application/pdf" });
      var win = window.open('', '_blank');
      var URL = window.URL || window.webkitURL;
      var dataUrl = URL.createObjectURL(blob);
      win.location = dataUrl;

      Swal.fire({
        title: "",
        text: 'Etiquetas geradas com sucesso!',
        type: "success",
      });
    }).catch(err => {
      Swal.fire({
        type: "info",
        text: tagService.formatErrorMessage(err.response),
        title: "",
      });
    })

  }

  const handleReturnTransfer = (data) => {
    const adTransferService = new AdTransferService();

    Swal.fire('Estornando transferência... Aguarde');
    adTransferService.returnAdTransfer({
      adTransfer: selectedTransfer._id,
      optionReturn: data.status
    }).then((response) => {
      if (response.data.type === 'success') {
        handleSearchTransfer({ search });
        refModalReturnTransfer.current.closeModal();

        Swal.fire({
          title: "",
          text: response.data.message,
          type: "success",
        });
      }
    }).catch(err => {
      Swal.fire({
        type: "info",
        text: adTransferService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }

  const handleAddTransfer = (data) => {
    const adTransferService = new AdTransferService();

    if (!selectedAds && selectedAds.length !== 0) {
      return Swal.fire({
        type: 'info',
        text: 'É nescessário escolher pelo menos um anúncio',
        title: '',
      });
    }

    const justIdsAds = [];
    selectedAds.map(ad => {
      return justIdsAds.push(ad._id);
    });

    if (!isEdit) {
      Swal.fire('Criando transferência... Aguarde');
      adTransferService.createAdTransfer({
        scamberOrigem: selectedScamberOrigin._id,
        scamberDestino: selectedScamberDestiny._id,
        anunciosIds: justIdsAds,
        // commission: data.commission,
        // initialDate: data.initialDate,
        // endDate: data.endDate
      }).then((response) => {
        if (response.data.type === 'success') {
          handleSearchTransfer({ search });
          refModalAddTransfer.current.closeModal();

          Swal.fire({
            title: "",
            text: response.data.message,
            type: "success",
          });
        }
      }).catch(err => {
        Swal.fire({
          type: "info",
          text: adTransferService.formatErrorMessage(err.response),
          title: "",
        });
      })
    } else {
      Swal.fire('Editando transferência... Aguarde');
      adTransferService.editAdTransfer({
        adTransfer: selectedTransfer,
        allAds: justIdsAds,
        // commission: data.commission,
        // initialDate: data.initialDate,
        // endDate: data.endDate
      }).then((response) => {
        if (response.data.type === 'success') {
          handleSearchTransfer({ search });
          refModalAddTransfer.current.closeModal();

          Swal.fire({
            title: "",
            text: response.data.message,
            type: "success",
          });
        }
      }).catch(err => {
        Swal.fire({
          type: "info",
          text: adTransferService.formatErrorMessage(err.response),
          title: "",
        });
      })
    }
  }

  const handleSearchStores = () => {
    const scamberService = new ScamberService();

    scamberService.listAllScamberWithStore().then((response) => {
      setStores(response.data.payload);
    })
  }

  const handleCategorizationBack = (oldCategorizationList, index) => {
    const listSelected = listSelectedCategorization.slice(0, index);
    setListSelectedCategorization(listSelected);

    setListActiveCategorization(oldCategorizationList);
  };

  const handleCategorization = (categorization) => {
    setCategory(categorization.nome);

    let listSelected;
    if (categorization.categorias && categorization.categorias.length > 0) {
      setListActiveCategorization(categorization.categorias);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      setListActiveCategorization(categorization.tipos_produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      setListActiveCategorization(categorization.produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else {
      listSelected = listSelectedCategorization;
      const lastIndex = listSelected.length > 0 ? listSelected.length - 1 : 0;

      if (listSelected[lastIndex] && listSelected[lastIndex].lastLevel) {
        listSelected[lastIndex] = {
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        };
        setListSelectedCategorization(listSelected);
      } else {
        listSelected.push({
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        });
        setListSelectedCategorization(listSelected);
      }
    }
  };

  const onSelectCategorization = (categorizationlist) => {
    let selectedCategorizations = categorizationlist
      ? categorizationlist
      : listSelectedCategorization;

    let categorizationIdsSelected = selectedCategorizations.map(
      (selectedCategorization) => {
        return selectedCategorization.categorization._id;
      }
    );
  }

  const hasChindren = (categorization) => {
    if (categorization.categorias && categorization.categorias.length > 0) {
      return true;
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      return true;
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      return true;
    }

    return false;
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start'
  });

  const handleCondition = (e, condition) => {
    setCondition(condition);
  };

  const handleBrand = (e, brand) => {
    setBrand(brand);
  };

  const searchAnuncio = ({ limit, key, deliveryType, points, price, storeUrl }) => {
    const anuncioService = new AnuncioService();

    if (key) {
      if (key.indexOf('&') !== -1) key = key.replace(/&/g, 'ampersand');
    }

    anuncioService.findAdsByUserSearch({
      searchString: key,
      limit: limit,
      brand: brand ? brand._id : null,
      category: listSelectedCategorization.length > 0 ? listSelectedCategorization[listSelectedCategorization.length - 1].categorization._id : null,
      condition: condition ? condition._id : null,
      deliveryType: deliveryType ? deliveryType : null,
      points: points ? points : null,
      price: price ? price : null,
      website: 'shopping',
      storeUrl: storeUrl ? storeUrl : null,
      statusAd: 'ativo'
    }).then(response => {
      setSearchAds(response.data.payload.ad);
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: anuncioService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }

  const onSearch = (data) => {
    setSearchKey(data.filterby);
    setDeliveryType(data.frete_type);
    setPoints(`${data.pontosMinimos}-${data.pontosMaximos}`);
    setPrice(data.order);

    searchAnuncio({
      limit: limit,
      key: data.filterby,
      deliveryType: data.frete_type,
      points: `${data.pontosMinimos}-${data.pontosMaximos}`,
      price: data.order,
      storeUrl: storeUrl,
    });
  }

  const loadMoreAds = () => {
    const newLimit = limit + 8;
    setLimit(newLimit);

    searchAnuncio({
      limit: newLimit,
      key: searchKey,
      deliveryType: deliveryType ? deliveryType : null,
      points: points ? points : null,
      price: price ? price : null,
      storeUrl: storeUrl,
    });
  }

  const addAnuncios = (e, anuncio) => {
    e.preventDefault();

    if (selectedAds) {
      const existAnuncio = selectedAds.find((a) => a._id === anuncio._id);

      if (!existAnuncio) {
        setSelectedAds([...selectedAds, anuncio]);
      }
    } else {
      setSelectedAds([anuncio]);
    }
  }

  const removeAnuncios = (e, anuncio) => {
    e.preventDefault();

    const excludesAnuncio = selectedAds.filter((a) => a._id !== anuncio._id);
    setSelectedAds(excludesAnuncio);
  }

  const onCleanFilter = () => {
    resetSearchFilter({})
    setBrand([]);
    setCondition([]);
    setListSelectedCategorization([]);
    setLimit(8);
    setSearchKey("");
    setDeliveryType();
    setPoints();
    setPrice();

    searchAnuncio({ limit: 8, key: "", storeUrl: storeUrl })
  }

  const openSelectProductModal = ({ scamber }) => {
    setBrand([]);
    setCondition([]);
    setListSelectedCategorization([]);
    resetSearchFilter({});
    setLimit(8);
    setSearchKey("");
    setDeliveryType();
    setPoints();
    setPrice();
    setStoreUrl(scamber.minha_loja?.url);

    searchAnuncio({ limit: limit, key: "", storeUrl: scamber.minha_loja?.url })
    refModalDialogSelectProduct.current.openModal();
  }

  const openCategorizationModal = () => {
    setListActiveCategorization(listCategorization);
    setListSelectedCategorization([]);
    setAllCategorizationLabel("");

    refModalDialogCategory.current.openModal();
  };

  const loadInfo = () => {
    const categorization = new Categorizacao();
    const domain = new Domain();

    categorization.list().then((response) => {
      if (response.status === 200) {
        setListCategorization(response.data.payload);
        setListActiveCategorization(response.data.payload);
      }
    }).catch((error) =>
      categorization.formatErrorMessage(error.response, 'CALICT'),
    );

    domain.listBrands().then((response) => {
      if (response.status === 200) {
        setBrands(response.data.payload);
      }
    }).catch((error) =>
      domain.formatErrorMessage(error.response, 'CALIBDO'),
    );

    domain.listConditions().then((response) => {
      if (response.status === 200) {
        setConditions(response.data.payload);
      }
    }).catch((error) =>
      domain.formatErrorMessage(error.response, 'CALICDO'),
    );
  };

  const handleInitialValue = useCallback(() => {
    handleSearchStores();
    handleSearchTransfer({});
  }, []);

  useEffect(() => {
    handleInitialValue();
    loadInfo();
  }, [handleInitialValue]);

  return (
    <>
      <main role="main" id="view_account-details">
        <div className="_fluid-container">

          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title">
              Transferência de Acervo
            </div>
          </div>

          <div className="_wrap-filter-activity">
            <div className="_filter-activity">
              <div className="_form _search" >
                <div className="form-group search">
                  <TextField
                    id="filterShowcase"
                    label="Filtrar"
                    variant="outlined"
                    size={"small"}
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { height: '39px' } }}
                  />

                  <button onClick={() => handleSearchTransfer({ search: search })}>
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </div>
              <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div>
              <div className="excel-div">
                <button className="excel-button" onClick={() => handleOpenModalAddTransfer()}>Criar Transferência</button>
              </div>
            </div>
          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope={"col"}>Scamber Origem</th>
                <th scope={"col"}>Scamber Destino</th>
                <th scope={"col"}>Gerar etiquetas</th>
                <th scope={"col"}>Retornar Transferência</th>
                <th scope={"col"}>Detalhes da Transferência</th>
                <th scope={"col"}>NFe de entrada</th>
                <th scope={"col"}>Editar</th>
              </tr>
            </thead>
            <tbody>
              {registros.length > 0 ? registros.map((registro) => {
                return (
                  <tr key={registro._id}>
                    <td>{registro.scamber_origem[0].nome}</td>
                    <td>{registro.scamber_destino[0].nome}</td>
                    <td>
                      <Button
                        className={"btn-step btn-edit-table"}
                        color="primary"
                        autoFocus
                        style={{ height: 45 }}
                        onClick={() => handleGenerateTags({ anuncios: registro.anuncios })}
                      >
                        Gerar
                      </Button>
                    </td>
                    <td>
                      <Button
                        className={"btn-step btn-edit-table"}
                        color="primary"
                        autoFocus
                        style={{ height: 45 }}
                        onClick={() => handleOpenModalReturnTransfer({ transfer: registro })}
                      >
                        Ações
                      </Button>
                    </td>
                    <td>
                      <Button
                        className={"btn-step btn-edit-table"}
                        color="primary"
                        autoFocus
                        style={{ height: 45 }}
                        onClick={() => openTransferDetailModal({ adTransferId: registro._id })}
                      >
                        Detalhes
                      </Button>
                    </td>
                    <td>
                      <Button
                        className={"btn-step btn-edit-table"}
                        color="primary"
                        autoFocus
                        style={{ height: 45 }}
                        disabled={registro.nota_fiscal ? true : false}
                        onClick={() => createInvoice({ adTransferId: registro._id })}
                      >
                        Gerar NFe
                      </Button>
                    </td>
                    <td>
                      <Button
                        className={"btn-step btn-edit-table"}
                        color="primary"
                        autoFocus
                        style={{ height: 45 }}
                        disabled={registro.nota_fiscal ? true : false}
                        onClick={() => openEditTransferModal({ adTransfer: registro })}
                      >
                        Editar
                      </Button>
                    </td>
                  </tr>
                )
              }) :
                <>
                </>
              }
            </tbody>
          </table>
        </div>
      </main>

      <ModalDialog
        ref={refModalReturnTransfer}
        dialogTitle="Retornar Transferência"
        onCloseModal={
          <button
            type="button"
            className="btn"
            onClick={() => refModalReturnTransfer.current.closeModal()}
          >
            {<CloseIcon />}
          </button>
        }>
        <div className="_wrap-form">
          <form className="_form" onSubmit={handleSubmitReturnTransfer(handleReturnTransfer)}>
            <h1 className="_content-title">Selecione uma ação</h1>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel shrink={true} id="change-order-status--outlined-label">
                    Como estornar a transferência
                  </InputLabel>
                  <Select
                    native
                    label="Status"
                    name="status"
                    inputRef={registerReturnTransfer({ required: true })}
                    inputProps={{ name: "status" }}
                  >
                    <option value="">Selecione</option>
                    <option value="justStatus">Alterar somente status</option>
                    <option value="allData">Alterar todos os dados</option>
                    {/* <option value="pontuateSeller">Transferência de Pontos</option> */}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Button
              className={"btn-step"}
              color="primary"
              autoFocus
              type="submit"
              style={{ height: 35 }}
            >
              Concluir
            </Button>
          </form>
        </div>
      </ModalDialog>

      <ModalDialog
        ref={refModalAddTransfer}
        dialogTitle="Incluir Transferência"
        className="GridProduct"
        onCloseModal={
          <button
            type="button"
            className="btn"
            onClick={() => refModalAddTransfer.current.closeModal()}
          >
            {<CloseIcon />}
          </button>
        }>
        <div className="_wrap-form">
          <form className="_form" onSubmit={handleSubmit(handleAddTransfer)}>
            <h1 className="_content-title">Preencha as informações</h1>
            <Grid container spacing={2}>
              {/* <Grid item md={12} xs={12}>
                <TextField
                  label="Comissão do Scamber"
                  type="number"
                  variant="outlined"
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  size="small"
                  inputRef={register({
                    required: true,
                  })}
                  inputProps={{
                    maxLength: 3,
                  }}
                  name="commission"
                  fullWidth
                />
              </Grid> */}
              {/* <Grid item md={6} xs={12}>
                <TextField
                  label="Data: Inicial *"
                  type="date"
                  variant="outlined"
                  size="small"
                  name="initialDate"
                  helperText={!!errors.initialDate && "Campo não pode ficar em branco"}
                  error={!!errors.initialDate}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  label="Data: Final *"
                  type="date"
                  variant="outlined"
                  size="small"
                  name="endDate"
                  helperText={!!errors.endDate && "Campo não pode ficar em branco"}
                  error={!!errors.endDate}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid> */}
              <Grid item xs={12}>
                <Autocomplete
                  value={selectedScamberOrigin || null}
                  filterOptions={filterOptions}
                  options={stores}
                  includeInputInList
                  freeSolo
                  disabled={isEdit}
                  getOptionLabel={(option) => option.minha_loja? String(option.minha_loja.titulo): ''}
                  getOptionSelected={(option) => option._id}
                  fullWidth
                  onChange={(e, value) => handleScamebrOrigin(e, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Scamber de Origem"
                      name="scamber_origem"
                      variant="outlined"
                      size={"small"}
                      error={!!errors.scamber_origem}
                      inputRef={register({ required: true })}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  filterOptions={filterOptions}
                  id="outlined-brand"
                  options={stores}
                  includeInputInList
                  freeSolo
                  disabled={isEdit}
                  getOptionLabel={(option) => option.minha_loja? String(option.minha_loja.titulo): ''}
                  getOptionSelected={(option) => option._id}
                  fullWidth
                  value={selectedScamberDestiny || null}
                  onChange={(e, value) => handleScamebrDestiny(e, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Scamber de Destino"
                      name="scamber_destino"
                      variant="outlined"
                      size={"small"}
                      error={!!errors.scamber_destino}
                      inputRef={register({ required: true })}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              {selectedScamberOrigin && (
                <Grid item xs={12}>
                  <h1 className="_content-title">Seleção de Produtos</h1>
                  <Button onClick={() => openSelectProductModal({ scamber: selectedScamberOrigin })}>
                    Selecionar Produtos
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  className="btn-step"
                  style={{ height: 35, marginBottom: '20px' }}
                >
                  Concluir
                </Button>
              </Grid>

            </Grid>
          </form>
        </div>
      </ModalDialog>

      <ModalDialog
        dialogTitle="Seleção de Produtos"
        className="_modal-category GridProducts"
        maxWidth="lg"
        btnTitle="Aplicar"
        ref={refModalDialogSelectProduct}
      >
        <div className="select-product-container">
          <div className="search-products-container">
            <div className="_wrap-form" style={{ marginBottom: '20px', marginTop: '20px' }}>
              <form className="_form" onSubmit={handleSubmitSearchFilter(onSearch)}>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-add-title"
                      label="Filtrar por"
                      type="text"
                      variant="outlined"
                      size={"small"}
                      name="filterby"
                      fullWidth
                      inputRef={registerSearchFilter({ required: false })}
                      inputProps={{ maxLength: 40 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                        Ordenar por
                      </InputLabel>
                      <Select
                        native
                        label="Ordernar por"
                        inputRef={registerSearchFilter({ required: false })}
                        inputProps={{ name: "order" }}
                      >
                        <option value=""></option>
                        <option value={-1}>Maior Preço</option>
                        <option value={1}>Menor Preço</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-category"
                      label="Categoria"
                      type="text"
                      variant="outlined"
                      size={"small"}
                      name="add_category"
                      value={allCategorizationLabel}
                      fullWidth
                      error={!!errorSearchFilter.add_category}
                      inputRef={registerSearchFilter({ required: false })}
                      InputLabelProps={{ shrink: true }}
                      onClick={() => openCategorizationModal()}
                      inputProps={{
                        endadornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon
                              icon={faChevronRight}
                            ></FontAwesomeIcon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <Autocomplete
                      filterOptions={filterOptions}
                      id="outlined-brand"
                      options={brands}
                      includeInputInList
                      freeSolo
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      fullWidth
                      value={brand}
                      onChange={(e, value) => handleBrand(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Marca"
                          name="brand"
                          variant="outlined"
                          size={"small"}
                          error={!!errorSearchFilter.brand}
                          inputRef={registerSearchFilter({
                            required: false,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <Autocomplete
                      id="outlined-condition"
                      autoComplete={true}
                      options={conditions}
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      size="small"
                      fullWidth
                      value={condition}
                      onChange={(e, value) => handleCondition(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Condição"
                          name="add_condition"
                          variant="outlined"
                          size={"small"}
                          error={!!errorSearchFilter.add_condition}
                          inputRef={registerSearchFilter({
                            required: false,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                        Tipo de Frete
                      </InputLabel>
                      <Select
                        native
                        label="Tipo de Frete"
                        inputRef={registerSearchFilter({
                          required: false
                        })}
                        inputProps={{
                          name: "frete_type",
                        }}
                      >
                        <option value=""></option>
                        <option value={"operador_logistico"}>Correios</option>
                        <option value={"entrega_maos"}>Entrega em mãos</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-login"
                      label="Pontos: Mínimo"
                      type="number"
                      variant="outlined"
                      size="small"
                      name="pontosMinimos"
                      defaultValue={0}
                      fullWidth
                      inputRef={registerSearchFilter({ required: true })}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-login"
                      label="Pontos: Máximo"
                      type="number"
                      variant="outlined"
                      size="small"
                      name="pontosMaximos"
                      defaultValue={9999}
                      fullWidth
                      inputRef={registerSearchFilter({ required: true })}
                    />
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <Button type='submit' style={{ marginTop: '3px' }}>
                      Aplicar
                    </Button>
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <Button color="secondary" style={{ marginTop: '3px' }} onClick={() => onCleanFilter()}>
                      Limpar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>

            <Grid container spacing={2}>
              {searchAds && searchAds.map((ad, index) => (
                <Grid item lg={3} md={4} sm={4} xs={6} key={index}>
                  <GridProduct
                    link={ad.url_encurtada}
                    image={
                      anuncioService.loadImage(ad.fotos[0])
                        ? anuncioService.loadImage(ad.fotos[0])
                        : ""
                    }
                    weight={ad.tamanho_exibicao?.nome}
                    title={ad.titulo}
                    price={ad.valor_por}
                    // type={ad.tipo_anuncio}
                    // cashback={ad.cashback}
                    // cashDiscount={ad.valor_de}
                    cashPrice={ad.valor_por}
                    website='shopping'
                    onClick={(e) => addAnuncios(e, ad)}
                  />
                </Grid>
              ))}

            </Grid>

            <div className="_wrap-load-more">
              {limit <= searchAds?.length ? (<button type="submit" onClick={() => loadMoreAds()} className="btn btn-red">Carregar mais</button>) : null}
            </div>
          </div>

          {selectedAds?.length > 0 && (
            <div className="alredy-select-container">
              <div className="_title-select-product">Produtos já selecionados</div>

              <List className="alredy-select-list">
                {selectedAds && selectedAds.map((ad, index) => (
                  <ProductCard
                    key={ad._id}
                    image={anuncioService.loadImage(ad.fotos[0])}
                    title={ad.titulo}
                    // subtitle={`R$${ad?.taxa_scamb?.$numberDecimal || 0}`}
                    url={ad.url_encurtada}
                  >
                    {!isEdit && (
                      <ButtonMaterial
                        endIcon={<DeleteIcon color="secondary" />}
                        onClick={(e) => { removeAnuncios(e, ad) }}
                      />
                    )}
                  </ProductCard>
                ))}
              </List>
            </div>
          )}
        </div>
      </ModalDialog>

      <ModalDialog
        customMethod={onSelectCategorization}
        dialogTitle="Categorias"
        btnTitle="Aplicar"
        className="_modal-category"
        maxWidth="xs"
        ref={refModalDialogCategory}
      >
        <span className="_content">
          <a href="#!" className="tag-selected-radio">
            {listSelectedCategorization.map(
              (selectedCategorization, index) => {
                if (index === 0) {
                  return (
                    <div
                      onClick={() =>
                        handleCategorizationBack(
                          selectedCategorization.oldCategorizationList,
                          index
                        )
                      }
                      key={index}
                      className="_title-selected _only"
                    >
                      <FontAwesomeIcon
                        className="icon"
                        icon={faChevronLeft}
                      ></FontAwesomeIcon>
                      <span class="_text">

                        {selectedCategorization.categorization.nome}
                      </span>
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={() =>
                        handleCategorizationBack(
                          selectedCategorization.oldCategorizationList,
                          index
                        )
                      }
                      key={index}
                      className="_title-selected _only _multiple"
                    >
                      <span class="_text">

                        {selectedCategorization.categorization.nome}
                      </span>
                    </div>
                  );
                }
              }
            )}
          </a>

          <ul id="drilldown">
            {listActiveCategorization.map((categorization, index) => (
              <li key={index}>
                <label>
                  <input
                    type="radio"
                    name="select_category_lvl_1"
                    checked={
                      category === categorization.nome ? "checked" : ""
                    }
                    onChange={() => handleCategorization(categorization)}
                  />
                  <span className="_name">{categorization.nome}</span>
                  {hasChindren(categorization) ? (
                    <div className="icon">
                      <FontAwesomeIcon
                        icon={faChevronRight}
                      ></FontAwesomeIcon>
                    </div>
                  ) : (
                    <></>
                  )}
                </label>
              </li>
            ))}
          </ul>
        </span>
      </ModalDialog>

      <ModalDialog
        ref={refModalTransferDetail}
        dialogTitle="Produtos"
        onCloseModal={
          <button
            type="button"
            className="btn"
            onClick={() => refModalTransferDetail.current.closeModal()}
          >
            <ChevronLeftIcon />
          </button>
        }
      >


        <ResultBlock numberCount={1} key={0} title="Itens Pontuados" show={soldAndReturnItens.length > 0 ? true : false}>
          <>
            <WrapTable>
              <table>
                <thead>
                  <th>
                    Link do produto
                  </th>
                  <th>
                    Valor em Pontos
                  </th>
                  {soldAndReturnItens?.find((item) => item.data_pagamento) && (
                    <th>
                      Data
                    </th>
                  )}
                </thead>

                <tbody>
                  {soldAndReturnItens?.map((item, index) => (
                    <tr key={index}>
                      <td><a href={getHost({ productId: item.original._id })} target="_blank">{item.replicado.titulo} <FontAwesomeIcon icon={["far", "external-link"]} /></a></td>
                      <td>{item.replicado.pontos_de?.$numberDecimal ?? item.replicado.pontos_por.$numberDecimal}</td>
                      {soldAndReturnItens?.find((item) => item.data_pagamento) && (
                        <td>{item?.data_pagamento?.split("T")[0]}</td>
                      )}
                    </tr>
                  ))}

                </tbody>
              </table>
            </WrapTable>
            {/* <ResultBlockTags>
              {soldAndReturnItens?.map((item, index) => (
                <>
                  <ButtonContentTag
                    key={index}
                    date={item?.data_pagamento?.split("T")[0]}
                    tagKey={item.replicado.pontos_de?.$numberDecimal ?? item.replicado.pontos_por.$numberDecimal}
                    value={item.replicado.titulo}
                    link={getHost({ productId: item.original._id })}
                  />
                </>
              ))}
            </ResultBlockTags> */}
          </>

        </ResultBlock>

        <ResultBlock numberCount={2} key={1} title="Itens Retornados" show={returnItens.length > 0 ? true : false}>

          <WrapTable>
            <table>
              <thead>
                <th>
                  Link do produto
                </th>
                <th>
                  Valor em Pontos
                </th>
              </thead>

              <tbody>
                {returnItens?.map((item, index) => (
                  <tr key={index}>
                    <td><a href={getHost({ productId: item.original._id })} target="_blank">{item.replicado.titulo} <FontAwesomeIcon icon={["far", "external-link"]} /></a></td>
                    <td>{item.replicado.pontos_de?.$numberDecimal ?? item.replicado.pontos_por.$numberDecimal}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </WrapTable>

          {/* <ResultBlockTags>
            {returnItens?.map((item, index) => (
              <ButtonContentTag
                key={index}
                tagKey={item.replicado.pontos_de?.$numberDecimal ?? item.replicado.pontos_por.$numberDecimal}
                value={item.replicado.titulo}
                link={getHost({ productId: item.original._id })}
              />
            ))}
          </ResultBlockTags> */}

        </ResultBlock>

        <ResultBlock numberCount={3} key={0} title="Itens Não Retornados" show={notReturnItens.length > 0 ? true : false}>

          <WrapTable>
            <table>
              <thead>
                <th>
                  Link do produto
                </th>
                <th>
                  Valor em Pontos
                </th>
              </thead>

              <tbody>
                {notReturnItens?.map((item, index) => (
                  <tr key={index} >
                    <td><a href={getHost({ productId: item.original._id })} target="_blank">{item.replicado.titulo} <FontAwesomeIcon icon={["far", "external-link"]} /></a></td>
                    <td>{item.replicado.pontos_de?.$numberDecimal ?? item.replicado.pontos_por.$numberDecimal}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </WrapTable>

          {/* <ResultBlockTags>
            {notReturnItens?.map((item, index) => (
              <ButtonContentTag key={index} 
              tagKey={item.replicado.pontos_de?.$numberDecimal ?? item.replicado.pontos_por.$numberDecimal} value={item.replicado.titulo} link={getHost({ productId: item.original._id })} />
            ))}
          </ResultBlockTags> */}

        </ResultBlock>


        {/* {soldAndReturnItens.length > 0 && (
          <div className="_wrap-form">
            <h6 style={{ marginTop: '10px', textAlign: 'center' }}>Itens retornados e Vendedor pago</h6>
            {soldAndReturnItens?.map((item, index) => (
              <div key={index}>
                <p style={{ paddingTop: '15px', marginBottom: 0 }}><strong>Produto Original: </strong><a href={getHost({ productId: item.original._id })} target="_black">{item.original.titulo}</a></p>
                <p><strong>Produto Replicado: </strong><a href={getHost({ productId: item.original._id })} target="_black">{item.replicado.titulo}</a></p>
              </div>
            ))}
          </div>
        )} */}

        {/* {returnItens.length > 0 && (
          <div className="_wrap-form">
            <h6 style={{ marginTop: '10px', textAlign: 'center' }}>Itens retornados</h6>
            {returnItens?.map((item, index) => (
              <div key={index}>
                <p style={{ paddingTop: '15px', marginBottom: 0 }}><strong>Produto Original: </strong><a href={getHost({ productId: item.original._id })} target="_black">{item.original.titulo}</a></p>
                <p><strong>Produto Replicado: </strong><a href={getHost({ productId: item.original._id })} target="_black">{item.replicado.titulo}</a></p>
              </div>
            ))}
          </div>
        )} */}

        {/* {notReturnItens.length > 0 && (
          <div className="_wrap-form">
            <h6 style={{ marginTop: '10px', textAlign: 'center' }}>Itens não retornados</h6>
            {notReturnItens?.map((item, index) => (
              <div key={index}>
                <p style={{ paddingTop: '15px', marginBottom: 0 }}><strong>Produto Original: </strong><a href={getHost({ productId: item.original._id })} target="_black">{item.original.titulo}</a></p>
                <p><strong>Produto Replicado: </strong><a href={getHost({ productId: item.original._id })} target="_black">{item.replicado.titulo}</a></p>
              </div>
            ))}
          </div>
        )} */}

      </ModalDialog>
    </>
  )
}