// React
import React, { useState, useEffect, useCallback, useRef } from 'react';

// Css
// import styles from './../styles/Dashboard.module.css'
import Swal from 'sweetalert2';
import { Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';

import SearchIcon from '@material-ui/icons/Search';
import SubjectIcon from '@material-ui/icons/Subject';
import Pagination from '@material-ui/lab/Pagination';
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import TextField from '@material-ui/core/TextField';
import { useMediaQuery } from "@material-ui/core";

import Report from './../../services/Report';
import Download from './../../services/Dowload';

import ModalDialog from '../../components/ModalDialog';

import config from '../../configs/config.json';


export default function Contas_detalhe() {

  let [registros, setRegistros] = useState([]);
  let [startDate, setStartDate] = useState('');
  let [endDate, setEndDate] = useState('');
  let [pagination, setPagination] = useState();

  const [filter, setFilter] = useState();
  const [selectedScamberEnd, setSelectedScamberEnd] = useState();
  const [selectedScamberTel, setSelectedScamberTel] = useState();

  const matches = useMediaQuery("(min-width:600px)");
  const { reset } = useForm();
  const refModalDetail = useRef();
  const limitofData = 20;

  useEffect(() => {
    if (!Boolean(localStorage.getItem('token'))) window.location.href = "/"

    const report = new Report();

    report.contasDetalhe({ limit: limitofData })
      .then((response) => {
        if (response.status !== 200) return Swal.fire({ icon: 'info', 'text': 'Não foi possível carregar a tabela.' });
        setRegistros(response.data.payload.result);
        setPagination(response.data.payload.pagination);
      })
      .catch((error) => {
        Swal.fire({ icon: 'info', 'text': 'Não foi possível carregar a tabela.' });
      })

  }, []);

  let submitFilter = (e) => {
    e.preventDefault();
    let requestObject = {}

    Swal.fire('Aguarde...');
    Swal.showLoading();
    if ((!startDate && !endDate) && !filter) return Swal.fire({ icon: 'info', title: '', text: 'Informe os filtros novamente' })

    if(startDate && endDate){
      let startDateSplited = startDate;
      let endDateSplited = endDate;

      startDateSplited = startDateSplited.split('-')
      endDateSplited = endDateSplited.split('-')

      requestObject = {
        "startDate": {
          "day": startDateSplited[2],
          "month": startDateSplited[1],
          "year": startDateSplited[0]
        },
        "endDate": {
          "day": endDateSplited[2],
          "month": endDateSplited[1],
          "year": endDateSplited[0]
        },
        "limit": limitofData,
        "filter": filter
      }
    } else {
      requestObject = {
        "limit": limitofData,
        "filter": filter
      }
    }
    
    const report = new Report();

    report.contasDetalhe(requestObject)
      .then((response) => {
        if (response.status !== 200) return Swal.fire({ icon: 'info', 'text': 'Token inválido ou não informado.' });
        setRegistros(response.data.payload.result);
        setPagination(response.data.payload.pagination);
        Swal.close();
      })
      .catch((error) => {
        Swal.fire({ icon: 'info', title: 'Não foi possível carregar a tabela.', text: 'Confira se as informações de filtro estão corretas' });
      })
  }

  let formatterDate = (date) => {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  }

  const handleActiveProducts = (products) => {
    const activesProducts = products.filter(product => product.ativo && !product.dados_compra);
    
    return activesProducts.length;
  }

  let getHost = () => {

    if (config.nodejs.server.indexOf('localhost') !== -1) {
      return `https://d1.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('/adm-dev') !== -1 && config.nodejs.server.indexOf('/adm-dev2') === -1 ) {
      return `https://d1.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('/adm-dev2') !== -1) {
        return `https://d2.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('apihml') !== -1 && config.nodejs.server.indexOf('apihml2') === -1 ) {
      return `https://h1.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('apihml2') !== -1) {
        return `https://h2.scamb.com.vc`
    } else {
      return `https://scamb.com.vc`
    }

  }

  const handlePageChange = useCallback((page) => {
    const report = new Report();

    report.contasDetalhe({ page: page, limit: limitofData })
      .then((response) => {
        if (response.status !== 200) return Swal.fire({ icon: 'info', 'text': 'Não foi possível carregar a tabela.' });
        setRegistros(response.data.payload.result);

      })
      .catch((error) => {
        Swal.fire({ icon: 'info', 'text': 'Não foi possível carregar a tabela.' });
      });

  }, []);


  const getDowload = () => {
    const dowload = new Download();
    let requestObject = {}

    if (startDate && endDate) {
      let startDateSplited = startDate;
      let endDateSplited = endDate;

      startDateSplited = startDateSplited.split('-')
      endDateSplited = endDateSplited.split('-')

      requestObject = {
        "startDate": {
          "day": startDateSplited[2],
          "month": startDateSplited[1],
          "year": startDateSplited[0]
        },
        "endDate": {
          "day": endDateSplited[2],
          "month": endDateSplited[1],
          "year": endDateSplited[0]
        },
        "filter": filter
      }
    }
    else {
      requestObject = {
        "limit": limitofData,
        "filter": filter
      }
    }

    Swal.fire('Gerando arquivo ... Aguarde');
    Swal.showLoading();
    dowload.getAccountFile(requestObject).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset=utf-8;' }));
      var fileLink = document.createElement('a');
      const newDate  = new Date();

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `contas_detalhe_${newDate.getDate()}_${newDate.getMonth() + 1}_${newDate.getFullYear()}.csv`);
      document.body.appendChild(fileLink);

      Swal.close();
      fileLink.click();
    }).catch(error => {
      Swal.close();
      Swal.fire({
        type: "info",
        html: dowload.formatErrorMessage(error.response),
        title: "",
      });
    });
  };

  const loadContas = () => {
    const report = new Report();

    Swal.fire('Carregando lista ... Aguarde');
    Swal.showLoading();
    report.contasDetalhe({ limit: limitofData}).then((response) => {
      setRegistros(response.data.payload.result);
      setPagination(response.data.payload.pagination);
      Swal.close();
    }).catch((error) => {
      Swal.fire({
        type: "info",
        text: report.formatErrorMessage(error.response),
        title: "",
      });
    })
  }

  const clearFilter = (e) => {
    e.preventDefault();

    setStartDate('');
    setEndDate('');
    
    loadContas();
  }

  return (
    <>
      <main role="main" id="view_account-details">
        <div className="_fluid-container">

          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title">
              Contas detalhe
          </div>
          </div>

          <div className="_wrap-filter-activity">
            <h2 className="title">Filtrar por data</h2>
            <div className="_filter-activity">
              <form className="_form" onSubmit={(e) => submitFilter(e)}>
                <div className="_wrap-dates">
                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Data inicial</label>
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="00/00/0000"
                        value={startDate}
                        aria-describedby="cc-name"
                        onChange={(e) => setStartDate(e.target.value)}
                      />

                    </div>
                  </div>

                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Data final</label>
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="00/00/0000"
                        value={endDate}
                        aria-describedby="cc-name"
                        onChange={(e) => setEndDate(e.target.value)}
                      />

                    </div>
                  </div>

                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Filtrar</label>
                    <div className="input-group">
                      <TextField
                        type="text"
                        className="form-control"
                        name="filterEmail"
                        aria-describedby="cc-name"
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    </div>
                  </div>

                </div>
                <div className="_wrap-input-group _submit">
                  <button type="submit" className="submit-filter">
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </form>
              <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div>
              <div className="excel-div">
                <button onClick={getDowload} className="excel-button">Exportar .csv</button>
              </div>
            </div>
          </div>


          <div className="">
            <form style={{ display: 'none' }} onSubmit={(e) => submitFilter(e)}>
              <div className="form-group row">

                <label htmlFor="example-date-input" className="col-2 col-form-label">Data inicial</label>
                <div className="col-10">
                  <input onChange={(e) => setStartDate(e.target.value)} className="form-control" type="date" id="example-date-input" />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="example-date-input" className="col-2 col-form-label">Data final</label>
                <div className="col-10">
                  <input onChange={(e) => setEndDate(e.target.value)} className="form-control" type="date" id="example-date-input" />
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block">Filtrar</button>
            </form>
          </div>
          <div className="_wrap-table">
            <h2 className="title">Novas contas</h2>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope={"col"}>DIA</th>
                  <th scope={"col"}>NOME</th>
                  <th scope={"col"}>NOME E SOBRENOME</th>
                  <th scope={"col"}>EMAIL</th>
                  <th scope={"col"}>CPF</th>
                  <th scope={"col"}>NOME LOJA</th>
                  <th scope={"col"}>URL DA LOJA</th>
                  <th scope={"col"}>QUANTIDADE DE PRODUTOS CADASTRADOS</th>
                  <th scope={"col"}>QUANTIDADE DE PRODUTOS ATIVOS</th>
                  <th scope={"col"}>PREÇO MÉDIO (PONTOS)</th>
                  <th scope={"col"}>CONTA VALIDADA?</th>
                  <th scope={"col"}>CADASTRO COMPLETO?</th>
                  <th scope={"col"}>DETALHES DA CONTA</th>
                </tr>
              </thead>
              <tbody>
                {registros.length ? registros.map((registro) => {
                  return (
                    <tr key={registro._id}>
                      <th scope="row">{formatterDate(registro.data_inclusao)}</th>
                      <td>{registro.nome.split(' ')[0]}</td>
                      <td>{registro.nome}</td>
                      <td>{registro.email}</td>
                      <td>{registro.cpf}</td>
                      <td>{registro?.minha_loja?.titulo}</td>
                      {registro?.minha_loja?.url ?
                        <td><a target='_blank' href={`${getHost()}/loja/${registro?.minha_loja?.url}`}>{`${getHost()}/loja/${registro?.minha_loja?.url}`}</a></td>
                        : <td> </td>}
                      <td>{registro?.anuncios.length}</td>
                      <td>{handleActiveProducts(registro?.anuncios)}</td>
                      <td>{registro?.meanAd}</td>
                      <td>{registro?.validated ? 'Sim' : 'Não'}</td>
                      <td>{registro?.completeRegister ? 'Sim' : 'Não'}</td>
                      <td>
                        <Button
                          className={"btn-step btn-edit-table"}
                          color="primary"
                          autoFocus
                          style={{ height: matches ? 45 : 50 }}
                          onClick={() => { setSelectedScamberEnd(registro.endereco); setSelectedScamberTel(registro.telefone); refModalDetail.current.openModal() }}
                        >
                          Ver Detalhes
                        {/*<AddIcon fontSize="inherit" />*/}
                        </Button>
                      </td>
                    </tr>
                  )
                }) :
                  <>
                  </>
                }
              </tbody>

            </table>
          </div>
        </div>
        <div class="pagination-style">
          {pagination && pagination.totalPages ? (
            <Pagination
              shape="rounded"
              count={pagination.totalPages}
              variant="outlined"
              color="primary"
              hidePrevButton
              hideNextButton
              size="large"
              onChange={(_, p) => handlePageChange(p)}
            />
          ) : undefined}
        </div>
      </main>
      <ModalDialog
        ref={refModalDetail}
        dialogTitle="Detalhes"
        onCloseModal={
          <button
            type="button"
            className="btn"
            onClick={() => {
              reset({});
              refModalDetail.current.closeModal()
            }}>
            {matches ? <CloseIcon /> : <ChevronLeftIcon />}
          </button>
        }>
        <div className="_wrap-form">

        {selectedScamberTel && selectedScamberTel.length > 0 ? selectedScamberTel.map(telefone => (
              <div key={telefone._id}>
                <p style={{ paddingBottom: '15px', paddingTop: '15px', marginBottom: 0 }}><strong>Telefone</strong></p>
                <p style={{ marginBottom: 0 }}><strong>Tipo:</strong> {telefone.tipo}</p>
                <p style={{ marginBottom: 0 }}><strong>DDD:</strong> {telefone.ddd}</p>
                <p style={{ marginBottom: 0 }}><strong>Número:</strong> {telefone.numero}</p>
              </div>
            )) : (
              <div>
                <p><strong>Não possui telefone cadastrado</strong></p>
              </div>
            )}

          {selectedScamberEnd && selectedScamberEnd.length > 0 ? selectedScamberEnd.map(endereco => (
            endereco.principal ? (
              <div key={endereco._id}>
                <p style={{ paddingBottom: '15px', paddingTop: '15px', marginBottom: 0 }}><strong>Endereço</strong></p>
                {endereco.apelido ? <p style={{ marginBottom: 0 }}><strong>Apelido:</strong> {endereco.apelido}</p> : undefined}
                <p style={{ marginBottom: 0 }}><strong>CEP:</strong> {endereco.cep}</p>
                <p style={{ marginBottom: 0 }}><strong>Logradouro:</strong> {endereco.logradouro}</p>
                <p style={{ marginBottom: 0 }}><strong>Número:</strong> {endereco.numero}</p>
                <p style={{ marginBottom: 0 }}><strong>Bairro:</strong> {endereco.bairro}</p>
                <p style={{ marginBottom: 0 }}><strong>Cidade:</strong> {endereco.cidade}</p>
                <p style={{ marginBottom: 0 }}><strong>Estado:</strong> {endereco.estado}</p>
                {endereco.complemento ? <p><strong>Complemento:</strong> {endereco.complemento}</p> : undefined}
              </div>
            ) : (
                <div key={endereco._id}>
                  <p style={{ paddingBottom: '15px', paddingTop: '15px', marginBottom: 0 }}><strong>Endereço</strong></p>
                  {endereco.apelido ? <p style={{ marginBottom: 0 }}><strong>Apelido:</strong> {endereco.apelido}</p> : undefined}
                  <p style={{ marginBottom: 0 }}><strong>CEP:</strong> {endereco.cep}</p>
                  <p style={{ marginBottom: 0 }}><strong>Logradouro:</strong> {endereco.logradouro}</p>
                  <p style={{ marginBottom: 0 }}><strong>Número:</strong> {endereco.numero}</p>
                  <p style={{ marginBottom: 0 }}><strong>Bairro:</strong> {endereco.bairro}</p>
                  <p style={{ marginBottom: 0 }}><strong>Cidade:</strong> {endereco.cidade}</p>
                  <p style={{ marginBottom: 0 }}><strong>Estado:</strong> {endereco.estado}</p>
                  {endereco.complemento ? <p><strong>Complemento:</strong> {endereco.complemento}</p> : undefined}
                </div>
              ))) : (
              <div>
                <p><strong>Não possui endereço principal cadastrado</strong></p>
              </div>
            )}
        </div>
      </ModalDialog>
    </>
  )
}
