//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React from "react";

//=====================================================================================
// #2 - Import * React Router Dom
//=====================================================================================
import { Link } from "react-router-dom";

//=====================================================================================
// #3 - Import * Styled Components
//=====================================================================================
import { ButtonDefault, ButtonLabel as StyledButtonLabel } from "./styles";

export const ButtonLabel = ({ buttonColor, icon, text, onClick, style }) => {

    return (
        <StyledButtonLabel onClick={onClick} buttonColor={buttonColor} style={style}>
            <div className="icon">
                {icon}
            </div>
            <div className="text-label">
                {text}
            </div>
        </StyledButtonLabel>
    )
}

function Button({ buttonText, type = "button", styleType, borderColor, buttonColor, textColor, buttonSize, buttonLink = false, maxWidth, iconColor, onClick, target, disabled, children }) {

    // <Button
    //     type="bordered"
    //     buttonSize="small"
    //     buttonColor="blue"
    //     textColor="red"
    //     buttonText="Hello"
    //     borderColor="red"
    //     buttonLink="/login"
    //     maxWidth={200}
    // />

    return (
        <>


            <ButtonDefault
                type={type}
                styleType={styleType}
                borderColor={borderColor}
                buttonColor={buttonColor}
                textColor={textColor}
                buttonSize={buttonSize}
                maxWidth={maxWidth}
                onClick={onClick}
                iconColor={iconColor}
                disabled={disabled || false}
            >

                {buttonLink ? (
                    buttonLink && !disabled ? !target ?
                        <Link to={buttonLink ? buttonLink : "#link-not-defined"}>
                            {buttonText}
                        </Link>
                        :
                        <a href={buttonLink ? buttonLink : "#link-not-defined"} target={target}>{buttonText}</a>
                        : null
                ) : buttonText }
                
                {children && children}


            </ButtonDefault>
        </>
    )
}

export default Button;