export const theming = {
    carrefour: {
        colors: {
            location_icon_color: "#1852CC",
            active_offers_color: "#1852CC",
        }
    },
    atacadao: {
        colors: {
            location_icon_color: "#FF5A00",
            active_offers_color: "#FF5A00",
        }
    }
}