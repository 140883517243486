import React, { useEffect, useState, useCallback, useRef } from 'react';
import Swal from 'sweetalert2';
import { Button } from "react-bootstrap";
import { TextField, Grid, } from "@material-ui/core";
import SubjectIcon from '@material-ui/icons/Subject';
import { useForm } from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { format } from 'date-fns';

import CashbackService from '../../services/Cashback';

import ModalDialog from '../../components/ModalDialog';

function Cashback() {
  const [cashbacks, setCashback] = useState([]);
  const [editCashback, setEditCashback] = useState();
  const [check, setCheck] = useState({ pause_adjustment: true });

  const refModalEditCashback = useRef();

  const { handleSubmit, register, setValue, errors } = useForm();

  const handleOpenModalEditCashback = ({ parameter }) => {
    setEditCashback(parameter);

    setValue('cashbackValue', parameter.valor);
    setValue('startedDate', format(new Date(parameter.data_inicio), 'yyyy-MM-dd'));
    setValue('expiredDate', format(new Date(parameter.data_fim), 'yyyy-MM-dd'));
    setCheck({ pause_adjustment: parameter.ativo })

    refModalEditCashback.current.openModal();
  }

  const handleChangeCheckbox = (event) => {
    const { target } = event;
    setCheck({ ...check, [target.name]: target.checked });
  };

  const handleEditCashback = (data) => {
    const cashbackService = new CashbackService();

    Swal.fire('Editando cashback... Aguarde');
    Swal.showLoading();

    data.active = check.pause_adjustment;

    cashbackService.editCashback({
      cashbackId: editCashback._id,
      cashback: data,
    }).then(() => {
      Swal.fire({
        title: "",
        text: "Cashback atualizado com sucesso!",
        type: "success",
      }).then(() => {
        refModalEditCashback.current.closeModal();
        getAllCashbacks();
      });
    }).catch(err => {
      Swal.fire({
        type: 'error',
        html: cashbackService.formatErrorMessage(err.response),
        title: '',
      });
    })
  }

  const getAllCashbacks = () => {
    const cashbackService = new CashbackService();

    Swal.fire('Buscando cashback... Aguarde');
    Swal.showLoading();

    cashbackService.listCashback().then(response => {
      setCashback(response.data.payload);
      Swal.close();
    }).catch(err => {
      Swal.close();
      Swal.fire({
        type: 'error',
        html: cashbackService.formatErrorMessage(err.response),
        title: '',
      });
    })
  }

  const handleInitialValue = useCallback(() => {
    getAllCashbacks();
  }, []);

  useEffect(() => {
    handleInitialValue()
  }, [handleInitialValue])


  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">
          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title"> Cashback</div>
          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope={"col"}>Nome</th>
                <th scope={"col"}>Cashback</th>
                <th scope={"col"}>Data de Inicio</th>
                <th scope={"col"}>Data fim</th>
                <th scope={"col"}>Ativo</th>
                <th scope={"col"}>Editar</th>
              </tr>
            </thead>
            <tbody>
              {cashbacks && cashbacks.map((register) => (
                <tr key={register._id}>
                  <td>{register.nome}</td>
                  <td>{register.valor}%</td>
                  <td>{format(new Date(register.data_inicio), 'dd/MM/yyyy')}</td>
                  <td>{format(new Date(register.data_fim), 'dd/MM/yyyy')}</td>
                  <td>{register.ativo ? 'Sim' : 'Não'}</td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: 45 }}
                      onClick={() => handleOpenModalEditCashback({ parameter: register })}
                    >
                      Editar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>

      <ModalDialog
        dialogTitle="Editar cashback"
        className="_modal-category GridProducts"
        maxWidth="sm"
        ref={refModalEditCashback}
      >
        <div className="select-product-container">
          <div className="search-products-container">
            <div className="_wrap-form" style={{ marginBottom: '20px', marginTop: '20px' }}>
              <form className="_form" onSubmit={handleSubmit(handleEditCashback)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Cashback"
                      type="number"
                      variant="outlined"
                      size={"small"}
                      name="cashbackValue"
                      fullWidth
                      helperText={!!errors.cashbackValue && "Campo não pode ficar em branco"}
                      error={!!errors.cashbackValue}
                      inputRef={register({ required: true })}
                      InputProps={{ inputProps: { min: 0, max: 100 } }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-login"
                      label="Data: Inicial *"
                      type="date"
                      variant="outlined"
                      size="small"
                      name="startedDate"
                      helperText={!!errors.startedDate && "Campo não pode ficar em branco"}
                      error={!!errors.startedDate}
                      fullWidth
                      inputRef={register({ required: true })}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-login"
                      label="Data: Final *"
                      type="date"
                      variant="outlined"
                      size="small"
                      name="expiredDate"
                      helperText={!!errors.expiredDate && "Campo não pode ficar em branco"}
                      error={!!errors.expiredDate}
                      fullWidth
                      inputRef={register({ required: true })}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      label="Ativar cashback"
                      control={
                        <Switch
                          checked={check.pause_adjustment}
                          onChange={handleChangeCheckbox}
                          name="pause_adjustment"
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  className="btn-step"
                  style={{ height: 35, marginBottom: '20px' }}
                >
                  Concluir
                </Button>
              </form>
            </div>
          </div>
        </div>
      </ModalDialog>
    </>
  )
}

export default Cashback;