import React from "react";

import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

export const HistoryCategory = ({ children }) => {

    return (
        <div className="wrap-back-list">
            <div className="back-list">
                {children}
            </div>
        </div>
    )
}

export const HistoryItem = ({ text, onClick }) => {

    return (
        <button type="button" className="back-list-item" onClick={onClick}>
            <div className="icon">
                <Icon icon={["far", "chevron-left"]} />
            </div>
            <div className="text">
                {text}
            </div>
        </button>
    )
}

export const MenuCategory = ({ children }) => {

    return (
        <ul className="menu-list">
            {children}
        </ul>
    )
}

export const MenuCategoryItem = ({ text, inputType, inputName, onClick }) => {

    return (
        <li>
            <label className="select-item" onClick={onClick}>
                <input type={inputType ? inputType : "radio"} name={inputName} />
                <div className="text">
                    {text}
                </div>
                <div className="icon">
                <Icon icon={["far", "chevron-right"]} />
                </div>
            </label>
        </li>
    )
}