import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    /* display: flex; */
    border: 1px solid ${({theme}) => theme.color.light};
    background-color:${({theme}) => theme.color.white};
    border-radius: 0.375rem;
    -webkit-box-shadow: 0 4px 8px 4px rgba(200,200,200,0.5);
    box-shadow: 0 4px 8px 4px rgba(200,200,200,0.5);
`;

export const Head = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0.625rem 0.9375rem;
`;

export const HeadColumn = styled.div`
    display: flex;
    align-items: center;

    &:first-child {
        margin-top:0;
        display: table;
    }
`;

export const Column = styled.div``;

export const StatusCount = styled.button`
    font-family: ${({theme}) => theme.font.nunito};
    font-weight: ${({theme}) => theme.fontWeight.fw700};
    margin-right: 0.9375rem;
    border: 0;
    background-color: ${({theme}) => theme.color.transparent};
`;

export const ButtonTag = styled.button`
    display: inline-block;
    background-color: ${({theme}) => theme.color.extraLight};
    border: 1px solid #F4F4F4;
    padding: 4px 10px;
    margin: 5px;
    border-radius: 3px;
`;

export const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.div`
    font-family: ${({theme}) => theme.font.nunito};
    font-weight: ${({theme}) => theme.fontWeight.fw700};
    font-size: 13px;
    margin-right: 10px;
    color: ${({theme}) => theme.color.black};
`;

export const Text = styled.div`
    font-size: 13px;
    font-weight: ${({theme}) => theme.fontWeight.fw700};
    color: ${({theme}) => theme.color.black};
`;

export const ButtonIcon = styled.button`
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: ${({theme}) => theme.color.transparent};
`;

export const Content = styled.div`
    padding: 0 0.9375rem 0.625rem 0.9375rem;
    
`;

export const ContentButtons = styled.div``;

export const LineTitle = styled.div`
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;

    &::before {
        content: "";
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 1px;
        width: 100%;
        background-color: ${({theme}) => theme.color.light};
    }
`;

export const TitleLineAction = styled(Title)`
    text-transform: uppercase;
`;

export const ButtonResultAction = styled.button`
    display: inline-block;
    background-color: ${({theme}) => theme.color.extraLight};
    border: 1px solid #F4F4F4;
    padding: 12px 10px;
    margin: 5px;
    border-radius: 3px;
    min-height: 77px;

    &:disabled {
        opacity: 0.7 !important;
        cursor: not-allowed;
    }
`;

export const ColumnWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .last-column {
        padding-left: 10px;

        div {
            svg {
                path {
                    fill: ${({theme}) => theme.color.gray}
                }
            }
        }
    }
`;

export const ButtonTitle = styled(Title)`
    text-align: left;
`;

export const ButtonText = styled(Title)`
    text-align: left;
    font-family: ${({theme}) => theme.font.comfortaa};
    color: ${({theme}) => theme.color.grayBold};
    font-size: 10px;
    margin-top: 4px;
    max-width: 120px;
    width: 100%;
    white-space: pre-line; 
`;
