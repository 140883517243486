import Service from './Service';

class OrderShopping extends Service {

    constructor() {
        super();
    }

    orderFinish({ order, scamber }) {
        return this.axiosInstance.post(`/ordershopping/finish?orderId=${order}&scamber=${scamber}`);
    }

    loadOrdersValues(page, email, ordenation, limit) {
        return this.axiosInstance.get(`/ordervalue/report?page=${page}&limit=${limit}${email? '&email=' + email: ''}${ordenation? '&ordenation=' + ordenation: ''}`);
    }

    loadOrdersTotals() {
        return this.axiosInstance.get(`/ordervalue/totals`);
    }

    loadOrderVendorInfo(orderID) {
        return this.axiosInstance.get(`/ordervalue/vendor?orderID=${orderID}`);
    }
}

export default OrderShopping;