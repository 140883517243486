//===========================================================
// #1 - Base Imports
//===========================================================
import React from "react";
import { useHistory } from "react-router-dom";

//===========================================================
// #2 - Import * UI Components
//===========================================================
import { ListContent, ListItem } from "../list-content";

//===========================================================
// #3 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faTruck, faHandHoldingBox } from "@fortawesome/pro-light-svg-icons";


export const ProductThumbnail = (props) => {

    // * History
    const history = useHistory();

    // * Redirect user to checkout
    const redirectCheckout = (e) => {
        e.preventDefault();
        history.push({pathname: `${props.productUrl}`});
        history.go(0);
    }

    return (

        <div className={"_product-thumbnail " +props.className}>
            <a href={props.productUrl} target="_blank" className="_col" onClick={props.onClick || null}>
        
                <div className="_image">
                    <img src={props.image} alt="" />
                </div>
                <div className="_product-info">
                    
                    <div className="title">
                        {props.title}
                    </div>
                    <div className="date">
                        <div className="title">
                            {props.dateTitle}
                        </div>
                        {props.date}
                    </div>
                    <div className="_product-price _1x">
                        {props.oldPrice && (
                        <div className="ui-item__discount-price">
                            <span className="price-tag-fraction"> {props.oldPrice} </span>
                            <span className="price-tag-symbol">pts</span>
                        </div>
                        )}

                        {props.price && (
                        <div className="ui-item__price">
                            <span className="price-tag-fraction"> {props.price} </span>
                            <span className="price-tag-symbol">pts</span>
                        </div>
                        )}

                    </div>

                    {props.freightType == "correios" && (
                        <ListContent bgColor="#FFFFFF">

                            <ListItem title="" className="flex">
                                <div className="_frete">
                                <Icon icon={faTruck} />
                                Correios
                                </div>
                            </ListItem>

                        </ListContent>
                    ) || props.freightType === "entrega_maos" && (
                        <ListContent bgColor="#FFFFFF">

                            <ListItem title="" className="flex">
                                <div className="_frete">
                                <Icon icon={faHandHoldingBox} />
                                    Entrega em mãos
                                </div>
                            </ListItem>

                        </ListContent>
                    )}

                </div>

            </a>

            <div className="_col">
                {props.children && (props.children) }
            </div>
        </div>
    )
}