import Service from './Service';

export default class Commissions extends Service {

  listCommission() {
    return this.axiosInstance.get(`/commissions`);
  }

  editCommission({ commissionId, comission }) {
    return this.axiosInstance.put(`/commissions`, { commissionId, comission });
  }
}