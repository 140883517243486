//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

export default function Container({ id, containerType, children }) {

    return (
        <main role="main" id={id} className={containerType === "boxed" && "_med-container" || containerType === "fluid" && "_fluid-container"} >
            {children}
        </main>
    )

}