import React, { Component } from 'react';
import Routes from "./Routes";
import { BrowserRouter } from 'react-router-dom';
import "./styles/index.scss";

import "./fontawesome";

import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./styled/1-base/global";
import { base } from "./styled/1-base";
import { theme } from "./styled/5-theme";

// Component
import Navbar from "./components/Header";
import config from './configs/config.json';


import 'bootstrap/dist/css/bootstrap.min.css';

const theming = { ...theme, ...base }

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {checkedRedirect: false}
        if(window.location.search.indexOf('id=')  !== -1
            && window.location.pathname.indexOf('/pdv/checkout') !== -1
            && !localStorage.getItem('token')) {

            let searchParams = new URLSearchParams(window.location.search);
            window.location.href = `${this.getHost()}/produto?id=${searchParams.get('id')}`
        } else {
            this.state.checkedRedirect = true;
        }
    }

    getHost = () => {
        if (config.nodejs.server.indexOf('localhost') !== -1) {
          return `https://d1.scamb.com.vc`
        } else if (config.nodejs.server.indexOf('/adm-dev') !== -1 && config.nodejs.server.indexOf('/adm-dev2') === -1 ) {
          return `https://d1.scamb.com.vc`
        } else if (config.nodejs.server.indexOf('/adm-dev2') !== -1) {
            return `https://d2.scamb.com.vc`
        } else if (config.nodejs.server.indexOf('apihml') !== -1 && config.nodejs.server.indexOf('apihml2') === -1 ) {
          return `https://h1.scamb.com.vc`
        } else if (config.nodejs.server.indexOf('apihml2') !== -1) {
            return `https://h2.scamb.com.vc`
        } else {
          return `https://scamb.com.vc`
        }
    }
    

    render() {

        return (
            <BrowserRouter >
            {this.state.checkedRedirect &&
                <ThemeProvider theme={theming}>
                    <GlobalStyles />
                    <Navbar />
                    <Routes />
                </ThemeProvider>
            }   
            </BrowserRouter>
        )

    }

}

export default App;