import React, { useRef, useEffect, useState } from 'react';

import { Table, Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import SweetAlert from 'sweetalert';

import { 
  TextField, 
  useMediaQuery,
  Grid,
} from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import SearchIcon from "@material-ui/icons/Search";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { faCamera } from "@fortawesome/pro-solid-svg-icons";

import QuickLInkService from '../../services/QuickLink';
import config from '../../configs/config.json';

import ModalDialog from '../../components/ModalDialog';

function PartnerBanner() {
  const matches = useMediaQuery("(min-width:600px)");

  const refModalAddQuickLink = useRef();
  const quickLink = new QuickLInkService();

  const [quickLinks, setQuickLink] = useState();
  const [selectedQuickLink, setselectedQuickLink] = useState();
  const [selectedImages, setselecImagens] = useState([0, 0, 0, 0]);
  const [imagens, setImanges] = useState();
  const [check, setCheck] = useState({ pause_adjustment: true });
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState();
  const [titulo, setTitulo] = useState('');

  const { handleSubmit, register, errors, reset, setValue } = useForm();
  const { handleSubmit: handleSubmitFilter, register: registerFilter, errors: errorsFilter, reset: resetFilter } = useForm();


  const openAddQuickLinkseModal = () => {
    setIsEdit(false);
    reset({});
    setCheck({ pause_adjustment: true });
    setTitulo('');
    setImanges([]);
    setselecImagens([0,0,0,0]);
    
    refModalAddQuickLink.current.openModal();
  }

  const openEditQuickLinkModal = (quickLinkData) => {
    Swal.fire('Carregando Banners dos Parceiros... Aguarde');
    Swal.showLoading();
    setIsEdit(true);
    setselectedQuickLink(quickLinkData._id);

    quickLink.getOneQuickLinks(quickLinkData._id).then((response) => {
      const data = response.data.payload;

      setValue("titulo", data.title);
      setValue("url", data.url);
      setTitulo(data.url);
      setCheck({ pause_adjustment: data.disabled });

      data.banners.map((foto, index) =>
        quickLink.loadImageFile(foto).then((file) => {
          setSelectedImages({ target: { files: [file] } }, index);
        })
      );
      Swal.close();
      refModalAddQuickLink.current.openModal();
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: quickLink.formatErrorMessage(err.response),
        title: "",
      });
    });
  }

  const deleteQuickLink = (quickLinkData) => {
    SweetAlert({
      title: "Deletar Banner do Parceiro ?",
      text: `Ao clicar em confirmar, O Banner será excluida permanentemente`,
      icon: "warning",
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        quickLink.deleteQuickLink(quickLinkData._id).then((response)=>{
          loadQuickLink(filter);
          SweetAlert({
              title: "Tudo pronto!",
              text: `Banner do parceiro excluido`,
              icon: "success",
              buttons: [false, "Ok"],
              dangerMode: true,
            })
        }).catch((error)=> {
          Swal.fire({
            type: "info",
            html: quickLink.formatErrorMessage(error.response, 'DELS'),
            title: "",
          });
          
        });    
      }
    });
  }

  const onFilter =(data) => {
    setFilter(data.filter);
    loadQuickLink(data.filter);
  }

  const setSelectedImages = (e, index) => {
    var reader = new FileReader();
    var file = e.target.files[0];
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      var image = new Image();
      image.src = e.target.result;
      image.onload = function () {
        if (this.width < this.height) {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_portrait' });
        } else if (this.width > this.height) {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_landscape' });
        } else {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_square' });
        }
        setImanges([...selectedImages]);
      }
    }
  };

  const removeImage = (e, index) => {
    selectedImages.splice(index, 1, 0);
    setImanges([...selectedImages]);
  };

  const handleChangeCheckbox = (event) => {
    const { target } = event;
    setCheck({ ...check, [target.name]: target.checked });
  };

  const handleAddQuickLink = (data) => {
    
    const formatedData = {
      selectedImages: imagens,
      title: data.titulo,
      url: data.url.normalize('NFD').replace(/[^a-zA-Zs]/g, '').toLowerCase(),
      disabled: check.pause_adjustment,
    };
    
    if(isEdit) {
      formatedData._id = selectedQuickLink;
    }

    const formDataSc = new FormData();
    formDataSc.append("quickLink", JSON.stringify(formatedData));

    imagens.map((image, index) => {
      if (image !== 0) {
        formDataSc.append("image" + (index + 1), image.file);
      }
    });

    if(isEdit) {
      Swal.fire('Salvando Banner do Parceiro... Aguarde');
      Swal.showLoading();
      quickLink.updateQuickLink(formDataSc).then((response) => {
        refModalAddQuickLink.current.closeModal();
        reset();
        SweetAlert({
          title: "",
          text: response.data.message ? response.data.message : 'Banner do Parceiro editada com sucesso!',
          icon: "success",
        }).then(() => {
          loadQuickLink(filter);
        });
      });
    } else {
      Swal.fire('Criando Banner do Parceiro... Aguarde');
      Swal.showLoading();
        if(check.pause_adjustment) {
          SweetAlert({
            title: "Banner do Parceiro",
            text: `Lembrando! O Banner do Parceiro está sendo criada desativada`,
            icon: "info",
            buttons: [, "Confirmar"],
          }).then(function (isConfirm) {
            if (isConfirm) {
              quickLink.createQuickLink(formDataSc, 'shopping', 'banner').then((response) => {
                loadQuickLink(filter);
                refModalAddQuickLink.current.closeModal();
                reset();
                SweetAlert({
                  title: "",
                  text: response.data.message ? response.data.message : 'Banner do parceiro incluido com sucesso!',
                  icon: "success",
                })
              }).catch((error) => {
                Swal.fire({
                  type: "info",
                  html: quickLink.formatErrorMessage(error.response),
                  title: "",
                });
              });
            }
          });
        } else {
          SweetAlert({
            title: "Banner do Parceiro",
            text: `O Banner do Parceiro está sendo criada ativada`,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          }).then(function (isConfirm) {
            if (isConfirm) {
              quickLink.createQuickLink(formDataSc, 'shopping', 'banner').then((response) => {
                loadQuickLink(filter);
                refModalAddQuickLink.current.closeModal();
                reset();
                SweetAlert({
                  title: "",
                  text: response.data.message ? response.data.message : 'Banner do Parceiro incluido com sucesso!',
                  icon: "success",
                })
              }).catch((error) => {
                Swal.fire({
                  type: "info",
                  html: quickLink.formatErrorMessage(error.response),
                  title: "",
                });
              });
            }
          });
        } 
    }
  }

  const loadQuickLink = (filter) => {
    Swal.fire('Carregando Banner dos Parceiros ... Aguarde');
    Swal.showLoading();
    quickLink.getAllQuickLinks(filter, 'shopping', 'banner').then((response) => {
      Swal.close();
      setQuickLink(response.data.payload);
    }).catch((error) => {
      Swal.fire({
        type: "info",
        html: quickLink.formatErrorMessage(error.response),
        title: "",
      });
    });
  }

  const getHost = () => {

    if (config.nodejs.server.indexOf('localhost') !== -1) {
      return `https://marcas.d1.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('/adm-dev') !== -1 && config.nodejs.server.indexOf('/adm-dev2') === -1) {
      return `https://marcas.d1.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('/adm-dev2') !== -1) {
      return `https://marcas.d2.scamb.com.vc`
    } if (config.nodejs.server.indexOf('apihml') !== -1 && config.nodejs.server.indexOf('apihml2') === -1) {
      return `https://marcas.h1.scamb.com.vc`
    } if (config.nodejs.server.indexOf('apihml2') !== -1) {
      return `https://marcas.h2.scamb.com.vc`
    } else {
      return `https://marcas.scamb.com.vc`
    }
}

  useEffect(() => {
    loadQuickLink();
  }, []);

  const clearFilter = (e) => {
    e.preventDefault();

    setFilter('');
    loadQuickLink();
  }

  

  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">
          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title">Banners dos Parceiros</div>
          </div>

          <div className="_wrap-filter-activity">
            <h2 className="title">Filtrar</h2>
            <div className="_filter-activity">
              <form className="_form _search" onSubmit={handleSubmitFilter(onFilter)}>
                <div className="form-group search">

                  <TextField
                    id="filterQuickLink"
                    label="Filtrar *"
                    variant="outlined"
                    size={matches ? "small" : "small"}
                    name="filter"
                    fullWidth
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    error={!!errors.qtd_points}
                    inputRef={registerFilter()}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      style: { height: '39px' }
                    }}
                  />

                  <button type="submit">
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </form>
              <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div>
              <div className="excel-div">
                <button className="excel-button" onClick={() => openAddQuickLinkseModal()}>Criar Banner do Parceiro</button>
              </div>
            </div>
          </div>

          <Table bordered hover size="lg">
            <thead>
              <tr>
                <th>Título</th>
                <th>Link</th>
                <th>Ativo</th>
                <th>Editar</th>
                <th>Deletar</th>
              </tr>
            </thead>
            <tbody>
              {quickLinks && quickLinks.map(quickLink =>
                <tr key={quickLink._id}>
                  <td>{quickLink.title}</td>
                  <td><a target='_blank' href={`${getHost()}/loja/${quickLink.url}`}>{`${getHost()}/loja/${quickLink.url}`}</a></td>
                  <td>{quickLink.disabled ? 'Não' : 'Sim'}</td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 35 : 40 }}
                      onClick={() => openEditQuickLinkModal(quickLink)}
                    >
                      Editar
                    </Button>
                  </td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 35 : 40 }}
                      onClick={() => deleteQuickLink(quickLink)}
                    >
                      Deletar
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </main>


      <ModalDialog
        ref={refModalAddQuickLink}
        dialogTitle="Incluir Banner do Parceiro"
        onCloseModal={
          <button
            type="button"
            className="btn"
            onClick={() => {
              reset({}); refModalAddQuickLink.current.closeModal()
            }}
          >
            {matches ? <CloseIcon /> : <ChevronLeftIcon />}
          </button>
        }>
        <div className="_wrap-form">
          <form className="_form" onSubmit={handleSubmit(handleAddQuickLink)}>
          <h1 className="_content-title">Preencha as informações para incluir o Banner do Parceiro</h1>

          <section className="_add-photos">
            <div className="form-group _photos">
              <div className="_label">
                Fotos *
              </div>
              <div className="_wrap-photos">
                <div className="_wrap-photo-item">
                  <div style={{ width: "150px", height: "120px" }} className="_photo-item">
                    <input
                      type="file"
                      accept="image/x-png, image/jpeg"
                      name={`photo-upload1`}
                      id={`photo-upload1`}
                      className="form-control-file"
                      onChange={(e) => setSelectedImages(e, 0)}
                    />
                      {selectedImages[0] ? (
                        <div className="_image-selected">
                        <img
                          src={selectedImages[0].show}
                          alt=""
                          className={"my-image "}
                        />

                        <button
                          type="button"
                          className="btn btn-transparent _delete-image"
                          onClick={(e) => removeImage(e, 0)}
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                          ></FontAwesomeIcon>
                        </button>
                      </div>
                      ) : (
                        <></>
                      )}
                        
                    
                    <label htmlFor={`photo-upload1`}>
                      <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>

                      <div className="_title-label">Adicionar foto principal</div>

                      <div className="_accept-formats-label">
                        JPG, GIF E PNG Somente
                        </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </section>
          
            <div id="view_create-ad"> 
              <section className="form-body">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-add-title"
                      label="Título *"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="titulo"
                      fullWidth
                      helperText={!!errors.titulo && "Campo não pode ficar em branco"}
                      error={!!errors.titulo}
                      inputRef={register({
                        required: true,
                      })}
                      inputProps={{
                        maxLength: 40,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div className="form-group">
                      <TextField
                        id="outlined-name"
                        label={"Nome da loja *"}
                        type="text"
                        onChange={(e)=>setTitulo(e.target.value)}
                        helperText={!!errors.url && "Campo não pode ficar em branco"}
                        variant={"outlined"}
                        size={matches ? "small" : "medium"}
                        name="url"
                        error={!!errors.url}
                        fullWidth
                        inputProps={{ className: "titulo" }}
                        inputRef={register({
                            required: true,
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                      />
                  </div>
                  <div className="form-group">
                      <h1 className="_content-title"><b>URL da loja sera:</b> {"https://marcas.scamb.com.vc/loja/" + titulo.normalize('NFD').replace(/[^a-zA-Zs]/g, '').toLowerCase()}</h1>
                  </div>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={<Switch checked={check.pause_adjustment} onChange={handleChangeCheckbox} name="pause_adjustment" color="primary" />}
                      label="Desativar Banner do parceiro"
                    />
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  className="btn-step"
                  style={{ height: matches ? 35 : 35, marginBottom: '20px' }}
                >
                  Concluir
                </Button>
              </section>
            </div>
          </form>
        </div>
      </ModalDialog>
    </>
  )
}

export default PartnerBanner;