export const colors = {
    white                   :   "#ffffff",
    light                   :   "#F9F9F9",
    iceLight                :   "#fbfcfc",
    iceBold                 :   "#f1f1f1",
    nude                    :   "#e3b487",
    orange                  :   "#f25e35",
    violet                  :   "#681e36",
    brown_lt                :   "#87604a",
    brown                   :   "#94847c",
    concrete                :   "#f2f2f2",
    alto                    :   "#dadada",
    yellow                  :   "#f3c14c",
    shock                   :   "#e185ae",
    silver                  :   "#9e9e9e",
    grayBold                :   "#515157",
    green                   :   "#27ae60",
    blue_bolder             :   "#032778",
    blue_light              :   "#557ea1",
    border                  :   "#eaeaea",
    box                     :   "#fbfbfb",
    transparent             :   "transparent",
    
    // Client Pallet
    blue                    :   "#14233e",
    red                     :   "#EF3742",
    xLight                  :   "#FBFBFB",
    extraLight              :   "#F9F9F9",
    light                   :   "#F1F1F1",
    iceLight                :   "#FCFCFC",
    ice                     :   "#DBDBDB",
    gray                    :   "#8B8B8B",
    black                   :   "#2F3640",
}