import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBag, faPlus } from '@fortawesome/pro-light-svg-icons';

import formatNumberToBRL from '../utils/FormatNumberToBRL';
import convert from '../utils/ConvertPointsToPrice';

const GridProduct = ({ title, link, image, cashback, website, discount, price, cashDiscount, cashPrice, weight, onClick }) => {

  return (
    <div className="_ui-item-wrapper">
      <div className="_ui-item">
          <a href={link} target="_black" style={{ backgroundImage: `url(${image})` }} className="_ui-item-image">
            {weight && (
                <div className="tag-weight">
                    {weight}
                </div>
            )}
          </a>
              
          <div className={`_ui-item-bottom ${cashback ? "cashback" : ""}`}>
              <div className="_col-left">
                  <Link to={link} className="_product-title">
                      {title}
                  </Link>
              </div>

              <div className="_col-right">

                  {website === 'scamb' ?
                      (
                          <div className="_product-price _1x">
                              <div className="ui-item__discount-price _is-hidden">
                                  <span className="price-tag-fraction">{discount}</span>
                                  <span className="price-tag-symbol">pts</span>
                              </div>
                              <div className="ui-item__price">
                                  <span className="price-tag-fraction">{price}</span>
                                  <span className="price-tag-symbol">pts</span>
                              </div>

                          </div>
                      ) :
                      (
                          <div className="_product-price _1x real">
                              <div className="ui-item__discount-price _is-hidden">
                                  <span className="price-tag-symbol">R$&nbsp;</span>
                                  <span className="price-tag-fraction">{cashDiscount}</span>
                              </div>
                              <div className="ui-item__price">
                                  <span className="price-tag-symbol">R$&nbsp;</span>
                                  <span className="price-tag-fraction">{formatNumberToBRL(cashPrice)}</span>
                              </div>

                          </div>
                      )
                  }

                  <div className="actions">
                      <button
                          type="button"
                          className="btn btn-transparent"
                          onClick={onClick}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                  </div>

              </div>

              <div className="_col-bottom">
                  {website === 'scamb' ?
                      (
                          <>
                              <div className="text">
                                  Equivalente a
                              </div>
                              <div className="price">
                                  R${convert({ points: Number(price) })}
                              </div>
                          </>
                      ) :
                      cashback ?
                          (
                              <div className="wrap-cashback">
                              <div className="cashback-tag">
                                  <div className="text">
                                      Ganhe
                                  </div>
                                  <div className="price">
                                      { cashback }  pts
                                  </div>
                              </div>
                              </div>
                          ) : (
                            <div className="wrap-cashback">
                              <div className="cashback-tag no">
                                <div className="text">Não possui pointsback</div>
                              </div>
                            </div>
                          )
                  }

              </div>
          </div>
      </div>
    </div>
  )
}

export default GridProduct;