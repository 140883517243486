import Service from './Service';

export default class GeneralParameters extends Service {

  listGeneralParameters() {
    return this.axiosInstance.get(`/parameters`);
  }

  editGeneralParameters({ generalParametersId, parameters }) {
    return this.axiosInstance.put(`/parameters`, { generalParametersId, parameters });
  }
}