//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React from "react";

//===============================================================================================================
// #2 - Import * { Button } Bootstrap
//===============================================================================================================
import { Button } from "react-bootstrap";
  
function NotFound () {

    const redirect = () => window.location.href = "/";

    return (
        <main 
        role="main" 
        id="view_err404" 
        style={{ 
            display: 'flex', 
            width: '100%', 
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100vh' 
          }}
        >
            <div className="_med-container">
                <div className="content-error">
                    <h1 className="title">
                        404
                    </h1>
                    <div className="content">
                        <h2 className="subtitle">
                            <span>Oops..</span>
                            Página não encontrada!
                        </h2>
                        <p className="text">
                        O endereço da página mudou ou a página<br/> não existe.
                        </p>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default NotFound;