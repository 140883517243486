import React, { useState, useCallback, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import {
  TextField,
  InputLabel,
  Grid,
  FormControl,
  Select,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import SubjectIcon from '@material-ui/icons/Subject';
import SearchIcon from '@material-ui/icons/Search';

import ModalDialog from '../../components/ModalDialog';

import CouponsService from '../../services/Coupons';

function Coupons() {
  const [allCoupons, setAllCoupons] = useState([]);
  const [search, setSearch] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [couponId, setCouponId] = useState();
  const [check, setCheck] = useState(false);
  const [code, setCode] = useState("");

  const refModalCoupon = useRef();

  const { handleSubmit, register, errors, reset, setValue } = useForm();

  const handleOpenModalCoupon = () => {
    setIsEdit(false);
    reset({});
    refModalCoupon.current.openModal();
  }

  const handleEditCoupon = ({ cuponId }) => {
    const couponsService = new CouponsService();

    Swal.fire('Carregando Vitrine... Aguarde');
    Swal.showLoading();
    setIsEdit(true);
    setCouponId(cuponId);

    couponsService.getCouponById(cuponId).then((response) => {
      const data = response.data.payload;
      console.log(data);

      setValue("coupomCode", data.cupom_code);
      setValue("initialData", format(new Date(data.started_date), 'yyyy-MM-dd'));
      setValue("finalDate", format(new Date(data.expired_date), 'yyyy-MM-dd'));
      setValue("couponType", data.tipo_cupom);
      setValue("descont", data.descont);
      setValue("description", data.description);
      setValue("limitUser", data.limit_user);
      setValue("limitGeral", data.limit_geral);
      setValue("valueMin", data.value_min);
      setValue("valueMax", data.value_max);
      // setValue("website", data.website);
      setCheck(data.disabled);

      Swal.close();
      refModalCoupon.current.openModal();
    }).catch((err) => {
      console.log(err)
      Swal.fire({
        type: 'error',
        html: couponsService.formatErrorMessage(err.response),
        title: '',
      });
    });
  }

  const handleAddShowcase = (data) => {
    const couponsService = new CouponsService();
    data.initialData = new Date(data.initialData);
    data.finalDate = new Date(data.finalDate);
    console.log(data);

    let formatedData = {
      cupomCode: data.coupomCode,
      startedDate: data.initialData,
      expiredDate: data.finalDate,
      tipoCupom: data.couponType,
      descont: data.descont,
      description: data.description,
      limitUser: data.limitUser,
      limitGeral: data.limitGeral,
      valueMin: data.valueMin,
      valueMax: data.valueMax,
      disabled: check,
      website: data.website
    };

    if (isEdit) {
      formatedData = {
        cuponId: couponId,
        newstartedDate: data.initialData,
        newexpiredDate: data.finalDate,
        cupomCode: data.coupomCode,
        tipoCupom: data.couponType,
        descont: data.descont,
        description: data.description,
        limitUser: data.limitUser,
        limitGeral: data.limitGeral,
        valueMin: data.valueMin,
        valueMax: data.valueMax,
        disabled: check,
        website: 'shopping'//data.website
      };
    }

    console.log(formatedData);


    if (isEdit) {
      Swal.fire('Salvando Cupom... Aguarde');
      Swal.showLoading();

      couponsService.updateCoupon(formatedData).then((response) => {
        refModalCoupon.current.closeModal();

        Swal.fire({
          title: '',
          text: 'Cupom editado com sucesso!',
          icon: 'success',
        }).then(() => {
          reset();
          handleSearchCoupons({});
        });
      }).catch(err => {
        Swal.fire({
          type: 'error',
          html: couponsService.formatErrorMessage(err.response),
          title: '',
        });
      });
    } else {
      Swal.fire('Criando Cupom... Aguarde');
      Swal.showLoading();

      couponsService.createCoupon(formatedData).then((response) => {
        refModalCoupon.current.closeModal();

        Swal.fire({
          title: '',
          text: 'Cupom criado com sucesso!',
          icon: 'success',
        }).then(() => {
          reset();
          handleSearchCoupons({});
        });
      }).catch(err => {
        Swal.fire({
          type: 'error',
          html: couponsService.formatErrorMessage(err.response),
          title: '',
        });
      });
    }
  }

  const handleSearchCoupons = ({ search }) => {
    Swal.fire('Carregando Cupons ... Aguarde');
    const couponsService = new CouponsService();

    couponsService.getAllCoupon(search).then((response) => {
      setAllCoupons(response.data.payload);
      Swal.close();
    }).catch((err) => {
      Swal.fire({
        type: 'error',
        html: couponsService.formatErrorMessage(err.response),
        title: '',
      });
    });
  };

  const handleGenerateCode = () => {
    const code = (Math.random() + 1).toString(36).substring(7);
    setCode(code.toUpperCase());
  }

  const clearFilter = () => {
    setSearch("");
    handleSearchCoupons({});
  }

  const handleInitialValue = useCallback(() => {
    handleSearchCoupons({});
  }, []);

  useEffect(() => {
    handleInitialValue();
  }, [handleInitialValue]);

  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">
          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title"> Cupons</div>
          </div>

          <div className="_wrap-filter-activity">
            <h2 className="title">Filtrar</h2>
            <div className="_filter-activity _form _search">
              <div className="_form _search" >
                <div className="form-group search">
                  <TextField
                    id="filterShowcase"
                    label="Filtrar"
                    variant="outlined"
                    size={"small"}
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { height: '39px' } }}
                  />

                  <button onClick={() => handleSearchCoupons({ search: search })}>
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </div>

              <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div>
              <div className="excel-div">
                <button className="excel-button" onClick={() => handleOpenModalCoupon()}>Criar Cupom</button>
              </div>
            </div>
          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope={"col"}>Código</th>
                <th scope={"col"}>Tipo de Cupom</th>
                <th scope={"col"}>Desconto</th>
                <th scope={"col"}>Data Inicial</th>
                <th scope={"col"}>Data Final</th>
                <th scope={"col"}>Editar</th>
              </tr>
            </thead>
            <tbody>
              {allCoupons && allCoupons.map((cupom) => (
                <tr key={cupom._id}>
                  <td>{cupom.cupom_code}</td>
                  <td>{cupom.tipo_cupom}</td>
                  <td>{cupom.descont}</td>
                  <td>{format(new Date(cupom.started_date), 'dd/MM/yyyy')}</td>
                  <td>{format(new Date(cupom.expired_date), 'dd/MM/yyyy')}</td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: 45 }}
                      onClick={() => handleEditCoupon({ cuponId: cupom._id })}
                    >
                      Editar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>

      <ModalDialog
        dialogTitle="Criar Novo Cupom"
        className="_modal-category GridProducts"
        ref={refModalCoupon}
      >
        <div className="_wrap-form">
          <form className="_form" onSubmit={handleSubmit(handleAddShowcase)}>
            <h1 className="_content-title">Selecione uma ação</h1>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField
                  id="outlined-add-title"
                  label="Code *"
                  type="text"
                  variant="outlined"
                  size={"small"}
                  name="coupomCode"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  fullWidth
                  helperText={!!errors.coupomCode && "Campo não pode ficar em branco"}
                  error={!!errors.coupomCode}
                  inputRef={register({ required: true })}
                  inputProps={{ maxLength: 40 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={4}>
                <Button
                  color="primary"
                  autoFocus
                  type="submit"
                  onClick={() => handleGenerateCode()}
                  style={{ minHeight: 40 }}
                >
                  Gerar Código
                </Button>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  id="outlined-login"
                  label="Data: Inicial"
                  type="date"
                  variant="outlined"
                  size="small"
                  helperText={!!errors.initialData && "Campo não pode ficar em branco"}
                  error={!!errors.initialData}
                  inputRef={register({ required: true })}
                  name="initialData"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  id="outlined-login"
                  label="Data: Final"
                  type="date"
                  variant="outlined"
                  size="small"
                  helperText={!!errors.finalDate && "Campo não pode ficar em branco"}
                  error={!!errors.finalDate}
                  inputRef={register({ required: true })}
                  name="finalDate"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* <Grid item md={12} xs={12} style={{ marginBottom: '5px' }}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                    Website *
                  </InputLabel>
                  <Select
                    native
                    label="Tipo"
                    helperText={!!errors.website && "Campo não pode ficar em branco"}
                    error={!!errors.website}
                    inputRef={register({ required: true })}
                    inputProps={{
                      name: "website",
                      id: "outlined-transaction"
                    }}
                  >
                    <option value="scamb">Pessoas</option>
                    <option value="shopping">Marcas</option>
                  </Select>
                </FormControl>
              </Grid> */}

              <Grid item md={12} xs={12} style={{ marginBottom: '5px' }}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                    Tipo *
                  </InputLabel>
                  <Select
                    native
                    label="Tipo"
                    helperText={!!errors.couponType && "Campo não pode ficar em branco"}
                    error={!!errors.couponType}
                    inputRef={register({ required: true })}
                    inputProps={{
                      name: "couponType",
                      id: "outlined-transaction"
                    }}
                  >
                    <option value="percentual">Percentual</option>
                    <option value="valor">Valor/Pontos</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  id="outlined-login"
                  label="Desconto *"
                  type="number"
                  variant="outlined"
                  size="small"
                  helperText={!!errors.descont && "Campo não pode ficar em branco"}
                  error={!!errors.descont}
                  // InputProps={{ inputProps: { min: 0, max: 100 } }}
                  inputRef={register({ required: true })}
                  inputProps={{ maxLength: 3 }}
                  name="descont"
                  defaultValue={0}
                  fullWidth
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  id="outlined-login"
                  label="Limite por Usuário *"
                  type="number"
                  variant="outlined"
                  size="small"
                  helperText={!!errors.limitUser && "Campo não pode ficar em branco"}
                  error={!!errors.limitUser}
                  // InputProps={{ inputProps: { min: 0, max: 1000 } }}
                  inputRef={register({ required: true })}
                  inputProps={{ maxLength: 3 }}
                  name="limitUser"
                  defaultValue={0}
                  fullWidth
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  id="outlined-login"
                  label="Limite Geral *"
                  type="number"
                  variant="outlined"
                  size="small"
                  helperText={!!errors.limitGeral && "Campo não pode ficar em branco"}
                  error={!!errors.limitGeral}
                  // InputProps={{ inputProps: { min: 0, max: 1000 } }}
                  inputRef={register({ required: true })}
                  inputProps={{ maxLength: 3 }}
                  name="limitGeral"
                  defaultValue={0}
                  fullWidth
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  id="outlined-login"
                  label="Valor mínimo *"
                  type="number"
                  variant="outlined"
                  size="small"
                  helperText={!!errors.valueMin && "Campo não pode ficar em branco"}
                  error={!!errors.valueMin}
                  // InputProps={{ inputProps: { min: 0, max: 1000 } }}
                  inputRef={register({ required: true })}
                  inputProps={{ maxLength: 3 }}
                  name="valueMin"
                  defaultValue={0}
                  fullWidth
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  id="outlined-login"
                  label="Valor Máximo *"
                  type="number"
                  variant="outlined"
                  size="small"
                  helperText={!!errors.valueMax && "Campo não pode ficar em branco"}
                  error={!!errors.valueMax}
                  // InputProps={{ inputProps: { min: 0, max: 1000 } }}
                  inputRef={register({ required: true })}
                  inputProps={{ maxLength: 3 }}
                  name="valueMax"
                  defaultValue={0}
                  fullWidth
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                  Descrição *
                </InputLabel>
                <FormControl variant="outlined" size="small" fullWidth>
                  <textarea
                    maxLength="100"
                    style={{ resize: 'none', borderColor: '#eaeaea', borderRadius: '6px' }}
                    native
                    rows="3"
                    name="description"
                    helperText={!!errors.description && "Campo não pode ficar em branco"}
                    error={!!errors.description}
                    ref={register({ required: true })}
                    inputProps={{
                      name: "description",
                      id: "outlined-transaction"
                    }}
                  >
                  </textarea>
                </FormControl>
              </Grid>

              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={check}
                      onChange={() => setCheck(!check)}
                      name="pause_adjustment"
                      color="primary"
                    />
                  }
                  label="Desativar Cupom"
                />
              </Grid>
            </Grid>

            <Button
              className={"btn-step"}
              color="primary"
              autoFocus
              type="submit"
              style={{ height: 35 }}
            >
              Concluir
            </Button>
          </form>
        </div>
      </ModalDialog>
    </>
  )
}

export default Coupons;