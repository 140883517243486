import Service from './Service';

class CancelOrderShopping extends Service {

    constructor() {
        super();
    }

    reversalOrder({orderId, scamber}){
        return this.axiosInstance.post(`/cordershopping/reversalorder`, { orderId: orderId, scamber: scamber });
    }
}

export default CancelOrderShopping;