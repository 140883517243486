//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #2 - Import * Material UI
//===============================================================================================================
import SubjectIcon from '@material-ui/icons/Subject';

export default function PageHeadline({ title }) {

    return (
        <div id="page-section">
            <div className="icon">
                <SubjectIcon />
            </div>
            <div className="title">
                {title}
            </div>
        </div>
    )

}