import React, { useState, forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { subMonths, addMonths, format } from 'date-fns';
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faChevronLeft, faTimes, faCoins, faBookmark, faCalendarAlt, faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { faCheck, } from "@fortawesome/pro-solid-svg-icons";
import { Dialog, DialogContent, DialogTitle, useMediaQuery, Slide, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "jquery-mask-plugin";
import $ from "jquery";

import { PricePoints, PricePointsIcon, Price } from "./ui/price";
import { ListContent, ListItem } from "./ui/list-content";
import { TimelinePoints, StatusExtract } from "./ui/order-timeline";
import { ProductThumbnail } from "./ui/product-thumbnail";
import IconLabel from "./ui/form/icon-label";

import formatTitle from "../utils/FormatTitle";

import Extract from './../services/Scamber';
import Pedido from './../services/Order';
import Anuncios from './../services/Anuncios'

const DetailModal = forwardRef((props, ref) => {
  const matches = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className={`DialogExtractDetail`}
      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            <button type="button" className="btn" onClick={handleClose}>
              <Icon
                icon={matches ? faTimes : faChevronLeft}
              ></Icon>
            </button>
            <div className="_title-view">{props.dialogTitle}</div>
          </div>
        </DialogTitle>
        <DialogContent className={props.className}>
          <Typography component={"span"} variant={"body2"}>
            <div className="subtitle">
              {props.subTitle}
            </div>

            {props.children}

          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const HistoryBlock = (props) => {
  const checkStatus = (status) => {
    switch (status) {
      case 'PAGAMENTO_PENDENTE':
        return 'Pagamento Pendente';
      case 'POSTADO':
        return 'Postado';
      case 'ETIQUETA_GERADA':
        return 'Aguardando Envio';
      case 'PAGO':
        return 'Pago';
      case 'PONTUADO':
        return 'Pontuado';
      case 'FINALIZADO':
        return 'Finalizado';
      case 'CANCELADO':
        return 'Cancelado';
      default:
        return 'Status Não Mapeado'
    }
  }

  return (
    <div className="_history-block" onClick={props.onClick}>
      <div className={`punctuated ${props.punctuated ? "active" : "no-active"}`}>
        <div className="icon">
          <Icon icon={faCoins} />
          <div className="check">
            <Icon icon={faCheck} />
          </div>
        </div>
      </div>
      <div className="_cols">
        <div className="_col">
          <h1 className="title"> {formatTitle(props.title)} </h1>

          <div className="items">
            <div className="item">
              <div className="icon">
                <Icon icon={faBookmark} />
              </div>
              <h4 className="title">
                {formatTitle(props.type)}
              </h4>
            </div>
            <div className="item">
              <div className="icon">
                <Icon icon={faCalendarAlt} />
              </div>
              <h4 className="title">
                {props.date}
              </h4>
            </div>
            <div className="item">
              <div className="icon">
                <Icon icon={faCoins} />
              </div>
              <h4 className="title">
                <PricePoints value={props.points} className={props.color} />
              </h4>
              <div className="_col">
                <button type="button" className="btn">
                  <Icon icon={faEllipsisV} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const DateField = forwardRef((props, ref) => {
  const [fieldDate, setFieldDate] = useState(false);

  const handleField = () => setFieldDate(true);

  useImperativeHandle(ref, () => ({
    changeTypeField: () => handleField(),
  }));

  return (
    <div className="_wrap-input-group">
      <label className="_title-label-input"> {props.title} </label>
      <div className="input-group">

        {props.children}

        {!fieldDate && (
          <div className="input-group-prepend">
            <span className="input-group-text" id="start-date">
              <span className="icon" />
            </span>
          </div>
        )}
      </div>
    </div>
  )
})

const SelectFilter = ({ title, children }) => {

  return (
    <div className="select-filter">
      <h2 className="title">{title}</h2>
      <div className="wrap-filters-button">
        {children}
      </div>
    </div>
  )
}

const ButtonFilter = ({ inputType, inputName, buttonText, onClick, onChange, isChecked, id }) => {

  return (
    <div className="filter-button">
      <label>
        <input type={inputType} name={inputName} defaultChecked={isChecked} id={id ? id : ""} onChange={onChange} onClick={onClick} />
        <div className="text">
          {buttonText}
        </div>
        <span className="overlay"></span>
      </label>
    </div>
  )
}


const ExtractModal = ({ scamber, contaLoyalty, scamberSaldo }) => {
  const refDateStart = useRef();
  const refDateEnd = useRef();
  const refDialogModalPoints = useRef();
  const refDetailModalSale = useRef();
  const refDetailModalBuy = useRef();
  const refDetailModalBuyPoints = useRef();
  const refDetailModalBonusPoints = useRef();

  const [filterExtract, setFilterExtract] = useState(false);
  const [filterPending, setFilterPending] = useState(false);
  const [filterExpire, setFilterExpire] = useState(false);
  const [extractHistoric, setExtractHistoric] = useState([]);
  const [limit, setLimit] = useState(3);
  const [tabValue, setTabValue] = useState(0);
  const [modalValue, setmodalValue] = useState();
  const [findOrder, setFindOrder] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [historyFilter, setHistoryFilter] = useState();
  const [hideSeeMore, setHideSeeMore] = useState(false);
  const [extractItens, setExtractItens] = useState([]);
  const [extractExpirationItens, setExtractExpirationItens] = useState([]);
  const [pointsAwaitingApproval, setPointsAwaitingApproval] = useState(0);
  const [awpHistoric, setAwpHistoric] = useState([]);
  const [limitToShow, setLimitToShow] = useState(4);
  const [limitToShowAwp, setLimitToShowAwp] = useState(4);
  const [lastUpdate, setlastUpdate] = useState(new Date());
  const [limitToShowExpiration, setLimitToShowExpiration] = useState(4);
  const [isLoading, setIsLoading] = useState(false);
  const [productUrl, setProductUrl] = useState('');

  const { handleSubmit, register, formState, errors, setValue } = useForm({ mode: "onChange" });


  React.useEffect(() => {
    $(".money").mask("#.##0,00", { reverse: true });
  });

  const extract = new Extract();
  const pedido = new Pedido();

  const openDialogModalPoints = () => refDialogModalPoints.current.openModal();

  const loadPointsExtract = (limit, startDate, endDate, filter) => {
    extract.loadPointExtract(limit, startDate, endDate, filter).then((extractData) => {
      if (extractData.status === 200 && extractData.data.payload) {
        setExtractHistoric(extractData.data.payload);

        if (extractData.data.payload.length < limit) {
          setHideSeeMore(true);
        } else {
          setHideSeeMore(false);
        }
      }

      if (extractData.data.payload.length === 0) return setIsLoading(true);


    }).catch((error) => {
      extract.formatErrorMessage(error.response, 'MWLOPEX');
    })

    extract.loadPointsAwaitingApproval().then((extractData) => {
      if (extractData.status === 200 && extractData.data.payload) {
        setPointsAwaitingApproval(extractData.data.payload);
      }
    }).catch((error) => {
      extract.formatErrorMessage(error.response, 'MWLOPAEX')
    })

  }

  const loadLoyaltyExtract = () => {
    const actualDate = new Date();

    extract.loadLoyaltyExtract(scamber, undefined, undefined, 'ALL').then((extractData) => {
      if (extractData.status === 200 && extractData.data.payload && extractData.data.payload.listExtractItems) {
        setExtractItens(extractData.data.payload.listExtractItems);
      }
    }).catch((error) => {
      extract.formatErrorMessage(error.response, 'MWLOEXALL')
    })

    let dataInicial = new Date();
    let dataFinal = addMonths(dataInicial, 12);
    dataInicial = format(dataInicial, 'yyyy-MM-dd 00:00:00');
    dataFinal = format(dataFinal, 'yyyy-MM-dd 23:59:59');

    extract.loadLoyaltyExtractExpiration(dataInicial, dataFinal, undefined, contaLoyalty).then((extractExpirationData) => {
      if (extractExpirationData.status === 200 && extractExpirationData.data.payload) {
        setExtractExpirationItens(extractExpirationData.data.payload);
      }
    }).catch((error) => {
      extract.formatErrorMessage(error.response, 'MWLOEXEX')
    })

    extract.loadExtractAwaitingApproval(undefined, undefined).then((extractData) => {
      if (extractData.status === 200 && extractData.data.payload) {
        setAwpHistoric(extractData.data.payload);
        setLimitToShowAwp(4);
      }
    }).catch((error) => {
      extract.formatErrorMessage(error.response, 'MWLOAEX')
    })

  }

  useEffect(() => {
    loadPointsExtract(limit);
    loadLoyaltyExtract();

    setlastUpdate(new Date());
    $('.date').mask('00/00/0000');

  }, []); //eslint-disable-line

  const onSubmitFilter = (values) => {
    setStartDate(values.start_date);
    setEndDate(values.end_date);

    applyFilter(values.filter_type, null, true, values.start_date, values.end_date);

    setHistoryFilter(values.filter);
    setLimit(3)
  }

  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleRadio = (e) => {

    const { id } = e.target;

    if (id === "extrato") {
      setFilterExtract(true);
    } else if (id === "pendentes") {
      setFilterPending(true);
    } else if (id === "expirar") {
      setFilterExpire(true);
    } else {
      setFilterExtract(false);
      setFilterPending(false);
      setFilterExpire(false);
    }
  }

  const openExtractItemDetail = (extractItem) => {
    setmodalValue(extractItem);

    switch (extractItem.type) {
      case 'Compra':
        pedido.findOrder(extractItem.orderID).then(response => {
          setFindOrder(response.data.payload);
        });
        return refDetailModalBuy.current.openModal();

      case 'Devolução':
        pedido.findOrder(extractItem.orderID).then(response => {
          setFindOrder(response.data.payload);
        });
        return refDetailModalBuy.current.openModal();

      case 'Venda':
        pedido.findOrder(extractItem.orderID).then(response => {
          if (!response.data.payload) {
            const anuncios = new Anuncios();

            anuncios.findAdById({ adId: extractItem.orderID }).then(responseAd => {
              if (responseAd.data.payload) {
                setFindOrder({ itens: [responseAd.data.payload] })
              }
            })
          } else {
            setFindOrder(response.data.payload);
          }
        });

        return refDetailModalSale.current.openModal();

      case 'Pontos':
        return refDetailModalBuyPoints.current.openModal();

      case 'Bonificação':
        return refDetailModalBonusPoints.current.openModal();

      default:
    }
  }

  const applyFilter = (filterType, months, isCustom, customStartDate, customEndDate) => {

    if (isCustom) {

      let dataInicial = customStartDate ? customStartDate + ' 00:00:00' : undefined;
      let dataFinal = customEndDate ? customEndDate + ' 23:59:59' : undefined;

      Swal.fire('Carregando... Aguarde')
      Swal.showLoading();

      if (filterType === 'extract') {
        extract.loadLoyaltyExtract(scamber, dataInicial, dataFinal, 'ALL').then((extractData) => {
          Swal.close();
          if (extractData.status === 200 && extractData.data.payload && extractData.data.payload.listExtractItems) {
            setLimitToShow(4);
            setExtractItens(extractData.data.payload.listExtractItems);
          }
        }).catch((error) => {
          extract.formatErrorMessage(error.response, 'MWLOEXALLFIC')
        })
      } else if (filterType === 'pending') {
        //PENDENTES
        extract.loadExtractAwaitingApproval(dataInicial, dataFinal).then((extractData) => {
          Swal.close();
          if (extractData.status === 200 && extractData.data.payload) {
            setAwpHistoric(extractData.data.payload);
            setLimitToShowAwp(4);
          }
        }).catch((error) => {
          extract.formatErrorMessage(error.response, 'MWLOAEX')
        })
      } else {
        // A EXPIRAR
        extract.loadLoyaltyExtractExpiration(dataInicial, dataFinal, undefined, contaLoyalty).then((extractExpirationData) => {
          Swal.close();
          if (extractExpirationData.status === 200 && extractExpirationData.data.payload) {
            setExtractExpirationItens(extractExpirationData.data.payload);
            setLimitToShowExpiration(4);
          }
        }).catch((error) => {
          extract.formatErrorMessage(error.response, 'MWLOEXEX')
        })
      }


    } else {

      let dataInicial, dataFinal = undefined;

      Swal.fire('Carregando... Aguarde')
      Swal.showLoading();

      if (filterType === 'extract') {

        if (months) {
          dataFinal = new Date();
          dataInicial = subMonths(dataFinal, months);

          dataInicial = format(dataInicial, 'yyyy-MM-dd 00:00:00');
          dataFinal = format(dataFinal, 'yyyy-MM-dd 23:59:59');
        }

        extract.loadLoyaltyExtract(scamber, dataInicial, dataFinal, 'ALL').then((extractData) => {
          Swal.close();
          if (extractData.status === 200 && extractData.data.payload && extractData.data.payload.listExtractItems) {
            setLimitToShow(4);
            setExtractItens(extractData.data.payload.listExtractItems);
          }
        }).catch((error) => {
          extract.formatErrorMessage(error.response, 'MWLOEXALLFIC')
        })
      } else if (filterType === 'pending') {
        //PENDENTES
        if (months) {
          dataFinal = new Date();
          dataInicial = subMonths(dataFinal, months);

          dataInicial = format(dataInicial, 'yyyy-MM-dd 00:00:00');
          dataFinal = format(dataFinal, 'yyyy-MM-dd 23:59:59');
        }
        extract.loadExtractAwaitingApproval(scamber, dataInicial, dataFinal).then((extractData) => {
          Swal.close();

          if (extractData.status === 200 && extractData.data.payload) {
            setAwpHistoric(extractData.data.payload);
            setLimitToShowAwp(4);
          }
        }).catch((error) => {
          extract.formatErrorMessage(error.response, 'MWLOAEX')
        })

      } else {
        // A EXPIRAR
        if (months) {
          dataInicial = new Date();
          dataFinal = addMonths(dataInicial, months);

          dataInicial = format(dataInicial, 'yyyy-MM-dd 00:00:00');
          dataFinal = format(dataFinal, 'yyyy-MM-dd 23:59:59');
        }


        extract.loadLoyaltyExtractExpiration(dataInicial, dataFinal, undefined, contaLoyalty).then((extractExpirationData) => {
          Swal.close();
          if (extractExpirationData.status === 200 && extractExpirationData.data.payload) {
            setExtractExpirationItens(extractExpirationData.data.payload);
            setLimitToShowExpiration(4);
          }
        }).catch((error) => {
          extract.formatErrorMessage(error.response, 'MWLOEXEX')
        })
      }

    }
  }

  const formatDateToShow = (date, format) => {
    if (date) {

      let onlyDate = date?.split(' ')[0];
      let onlyHour = date?.split(' ')[1];

      let [year, month, day] = onlyDate.split('-');
      let [hours, minutes, seconds] = onlyHour.split(':');

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      if (hours.length < 2)
        hours = '0' + hours;
      if (minutes.length < 2)
        minutes = '0' + minutes;
      if (seconds.length < 2)
        seconds = '0' + seconds;

      if (format === 'date-hour') return `${[day, month, year].join('/')} ${[hours, minutes, seconds].join(':')}`;
      if (format === 'date') return `${[day, month, year].join('/')}`;
      if (format === 'hour') return `${[hours, minutes, seconds].join(':')}`;
    } else {
      return '';
    }
  }

  const checkExpiration = (date) => {
    const expirationDate = new Date(date);
    const actualDate = new Date();

    if (actualDate <= expirationDate) {
      return false;
    } else {
      return true;
    }
  }


  const redirectWebSite = () => {

    const url = window.location.href;

    const subdomains = {
      localhost: 'http://localhost:3000',
      d1: 'https://d1.scamb.com.vc',
      h1: 'https://h1.scamb.com.vc',
      prod: 'https://scamb.com.vc'
    };

    if (url.indexOf('localhost') >= 0) {
      setProductUrl(product_url => product_url = subdomains['localhost']);
      return false;
    } else if (url.indexOf('d1') >= 0) {
      setProductUrl(product_url => product_url = subdomains['d1']);
    } else if (url.indexOf('h1') >= 0) {
      setProductUrl(product_url => product_url = subdomains['h1']);
    } else {
      setProductUrl(product_url => product_url = subdomains['prod']);
    }

  }
  return (
    <>

      <DetailModal ref={refDetailModalSale} dialogTitle="Venda de produto">
        <h2 className="title">Detalhes</h2>
        {modalValue && (
          <ListContent bgColor="#FFFFFF">
            <ListItem title="Total da venda">
              <PricePointsIcon
                value={modalValue.points}
                color={modalValue.operation === 'C' ? "green" : "red"} />
            </ListItem>
            <ListItem title="Tipo">
              Venda de produto
            </ListItem>
            <ListItem title="Data da venda">
              {modalValue.date.split(" ")[0]}
            </ListItem>

            <ListItem title="Produto">
              {findOrder && findOrder.itens.map(item => (
                <>
                  <ProductThumbnail
                    key={item._id}
                    image={pedido.loadImage(item.fotos[0])}
                    title={item.titulo}
                    productUrl={`${productUrl}/produto?id=${item._id}`}
                    onClick={() => redirectWebSite()}
                  />
                </>
              ))}
            </ListItem>
          </ListContent>
        )}
      </DetailModal>

      <DetailModal ref={refDetailModalBuy} dialogTitle="Compra de produto">
        <h2 className="title">Detalhes</h2>
        {modalValue && (
          <ListContent bgColor="#FFFFFF">
            <ListItem title="Valor da compra">
              <PricePointsIcon
                value={modalValue.points}
                color={modalValue.operation === 'C' ? "green" : "red"} />
            </ListItem>
            <ListItem title="Tipo">
              Compra de produto
            </ListItem>
            <ListItem title="Data da compra">
              {modalValue.date.split(" ")[0]}
            </ListItem>
            <ListItem title="Produtos">
              {findOrder && findOrder.itens.map(item => (
                <ProductThumbnail
                  key={item._id}
                  image={pedido.loadImage(item.fotos[0])}
                  title={item.titulo}
                  productUrl={`${productUrl}/produto?id=${item._id}`}
                  onClick={() => redirectWebSite()}
                />
              ))}
            </ListItem>
          </ListContent>
        )}
      </DetailModal>

      <DetailModal ref={refDetailModalBuyPoints} dialogTitle="Compra de pontos">
        <h2 className="title">Detalhes</h2>
        {modalValue && (
          <ListContent bgColor="#FFFFFF">
            <ListItem title="Total da compra">
              <PricePointsIcon
                value={modalValue.points}
                color={modalValue.operation === 'C' ? "green" : "red"} />
            </ListItem>
            <ListItem title="Tipo">
              Compra de pontos
            </ListItem>
            <ListItem title="Data da compra">
              {modalValue.date.split(" ")[0]}
            </ListItem>
          </ListContent>
        )}
      </DetailModal>

      <DetailModal ref={refDetailModalBonusPoints} dialogTitle="Pontos bonificados">
        <h2 className="title">Detalhes</h2>

        {modalValue && (
          <ListContent bgColor="#FFFFFF">
            <ListItem title={`Quantidade de Pontos`}>
              <PricePointsIcon
                value={modalValue.points}
                color={modalValue.operation === 'C' ? "green" : "red"} />
            </ListItem>
            <ListItem title="Tipo">
              Pontos bonificados
            </ListItem>
            <ListItem title="Data da compra">
              {modalValue.date.split(" ")[0]}
            </ListItem>
          </ListContent>
        )}
      </DetailModal>

      <div id="view_my-wallet">
        <section className="_columns">
          <div className="_column _points">
            <section className="_block">

              <ListContent bgColor="#F9F9F9">
                <ListItem title="Saldo disponível" onClick={openDialogModalPoints}>

                  <PricePoints value={scamberSaldo ? scamberSaldo : 0} />

                </ListItem>
              </ListContent>

              <div className="last-update">
                {lastUpdate && `Atualizado às ${format(lastUpdate, 'HH:mm')} de ${format(lastUpdate, 'dd/MM/yyyy')}`}
              </div>

            </section>
          </div>

          <div className="_column _actions">
            <section className="_block">
              <div className="_med-container">

                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="Tabs My Sales"
                  className="_material-tabs"
                >
                  <Tab label="Extrato" {...tabProps(0)} />
                  <Tab label="Pendentes" {...tabProps(1)} />
                  <Tab label="A expirar" {...tabProps(2)} />
                </Tabs>

                <TabPanel value={tabValue} index={0} className="tab-panel">
                  <div className="_filters">
                    <SelectFilter title="Selecione o período:">
                      <ButtonFilter inputType="radio" isChecked={true} inputName="perido" buttonText="Todos" onChange={(event) => { handleRadio(event); applyFilter('extract', null, false); }} />
                      <ButtonFilter inputType="radio" inputName="perido" buttonText="1 mês" onChange={(event) => { handleRadio(event); applyFilter('extract', 1, false); }} />
                      <ButtonFilter inputType="radio" inputName="perido" buttonText="3 meses" onChange={(event) => { handleRadio(event); applyFilter('extract', 3, false); }} />
                      <ButtonFilter inputType="radio" inputName="perido" buttonText="6 meses" onChange={(event) => { handleRadio(event); applyFilter('extract', 6, false); }} />
                      <ButtonFilter inputType="radio" inputName="perido" buttonText="1 ano" onChange={(event) => { handleRadio(event); applyFilter('extract', 12, false); }} />
                      <ButtonFilter
                        inputType="radio"
                        inputName="perido"
                        id="extrato"
                        buttonText="Personalizado"
                        onChange={(event) => handleRadio(event)}
                      />
                    </SelectFilter>

                    {filterExtract && (
                      <div className="_filter-activity-extract">
                        <form className="_form form _search" onSubmit={handleSubmit(onSubmitFilter)}>
                          <div className="dates">
                            <DateField ref={refDateStart} title="Início">
                              <input type='hidden' name="filter_type" value="extract" ref={register} />
                              <input
                                type="date"
                                className="form-control"
                                placeholder="00/00/0000"
                                name="start_date"
                                aria-describedby="start-date"
                                ref={register}
                              />
                            </DateField>

                            <DateField ref={refDateEnd} title="Fim">
                              <input
                                type="date"
                                className="form-control"
                                placeholder="00/00/0000"
                                name="end_date"
                                aria-describedby="start-date"
                                ref={register}
                              />
                            </DateField>
                          </div>

                          <Button type="submit" className="apply-filters btn-scamb-action color-blue">
                            Aplicar <Icon icon={faCheck} />
                          </Button>

                        </form>
                      </div>
                    )}
                  </div>

                  <div className="content">
                    {extractItens?.slice(0, limitToShow).map((extractItem, index) => (
                      <HistoryBlock
                        key={index}
                        punctuated={true}
                        type={extractItem.type}
                        title={extractItem.title}
                        date={extractItem.date.split(" ")[0]}
                        points={extractItem.points}
                        color={extractItem.operation === 'C' ? "green" : "red"}
                        status={extractItem.status}
                        onClick={() => openExtractItemDetail(extractItem)}
                      />

                    ))}

                    {limitToShow < extractItens?.length && (
                      <div className="wrap-button-load-more">
                        {limitToShow < extractItens?.length ? (<button onClick={() => setLimitToShow(limitToShow + 4)} type="button" className="btn load-more">
                          Carregar mais
                        </button>) : <></>}
                      </div>
                    )}
                  </div>
                </TabPanel>

                <TabPanel value={tabValue} index={1} className="tab-panel">
                  <div className="_filters">
                    <SelectFilter title="Selecione o período:">
                      <ButtonFilter inputType="radio" isChecked={true} inputName="perido" buttonText="Todos" onChange={(event) => { handleRadio(event); applyFilter('pending', null, false); }} />
                      <ButtonFilter inputType="radio" inputName="perido" buttonText="1 mês" onChange={(event) => { handleRadio(event); applyFilter('pending', 1, false); }} />
                      <ButtonFilter inputType="radio" inputName="perido" buttonText="3 meses" onChange={(event) => { handleRadio(event); applyFilter('pending', 3, false); }} />
                      <ButtonFilter inputType="radio" inputName="perido" buttonText="6 meses" onChange={(event) => { handleRadio(event); applyFilter('pending', 6, false); }} />
                      <ButtonFilter inputType="radio" inputName="perido" buttonText="1 ano" onChange={(event) => { handleRadio(event); applyFilter('pending', 12, false); }} />
                      <ButtonFilter
                        inputType="radio"
                        inputName="perido"
                        id="pendentes"
                        buttonText="Personalizado"
                        onChange={(event) => handleRadio(event)}
                      />
                    </SelectFilter>

                    {filterPending && (
                      <div className="_filter-activity-extract">
                        <form className="_form form _search" onSubmit={handleSubmit(onSubmitFilter)}>
                          <div className="dates">
                            <DateField ref={refDateStart} title="Início">
                              <input
                                type="date"
                                className="form-control"
                                placeholder="00/00/0000"
                                name="start_date"
                                aria-describedby="start-date"
                                ref={register}
                              />
                            </DateField>

                            <DateField ref={refDateEnd} title="Fim">
                              <input
                                type="date"
                                className="form-control"
                                placeholder="00/00/0000"
                                name="end_date"
                                aria-describedby="start-date"
                                ref={register}
                              />
                            </DateField>
                          </div>

                          <Button type="submit" className="apply-filters btn-scamb-action color-blue">
                            Aplicar <Icon icon={faCheck} />
                          </Button>
                        </form>
                      </div>
                    )}
                  </div>

                  <div className="content">
                    {awpHistoric?.slice(0, limitToShowAwp).map((extractItem, index) => (
                      <HistoryBlock
                        key={index}
                        punctuated={true}
                        type={extractItem.type}
                        title="Venda de Produto"
                        date={extractItem.date.split(" ")[0]}
                        points={extractItem.points}
                        color={extractItem.operation === 'C' ? "green" : "red"}
                        status={extractItem.status}
                        onClick={() => openExtractItemDetail(extractItem)}
                      />
                    ))}

                    {limitToShowAwp < awpHistoric?.length && (
                      <div className="wrap-button-load-more">
                        {limitToShowAwp < awpHistoric?.length ? (<button onClick={() => setLimitToShowAwp(limitToShowAwp + 4)} type="button" className="btn load-more">
                          Carregar mais
                        </button>) : <></>}
                      </div>
                    )}
                  </div>
                </TabPanel>

                <TabPanel value={tabValue} index={2} className="tab-panel">
                  <div className="_filters">
                    <SelectFilter title="Selecione o período:">
                      <ButtonFilter inputType="radio" inputName="perido" buttonText="Todos" onChange={(event) => { handleRadio(event); applyFilter('expiration', null, false); }} />
                      <ButtonFilter inputType="radio" inputName="perido" buttonText="1 mês" onChange={(event) => { handleRadio(event); applyFilter('expiration', 1, false); }} />
                      <ButtonFilter inputType="radio" inputName="perido" buttonText="3 meses" onChange={(event) => { handleRadio(event); applyFilter('expiration', 3, false); }} />
                      <ButtonFilter inputType="radio" inputName="perido" buttonText="6 meses" onChange={(event) => { handleRadio(event); applyFilter('expiration', 6, false); }} />
                      <ButtonFilter inputType="radio" inputName="perido" buttonText="1 ano" isChecked={true} onChange={(event) => { handleRadio(event); applyFilter('expiration', 12, false); }} />
                      <ButtonFilter
                        inputType="radio"
                        inputName="perido"
                        id="expirar"
                        buttonText="Personalizado"
                        onChange={(event) => handleRadio(event)}
                      />
                    </SelectFilter>

                    {filterExpire && (
                      <div className="_filter-activity-extract">
                        <form className="_form form _search" onSubmit={handleSubmit(onSubmitFilter)}>

                          <div className="dates">
                            <DateField ref={refDateStart} title="Início">
                              <input type='hidden' name="filter_type" value="expiration" ref={register} />
                              <input
                                type="date"
                                className="form-control"
                                placeholder="00/00/0000"
                                name="start_date"
                                aria-describedby="start-date"
                                ref={register}
                              />
                            </DateField>

                            <DateField ref={refDateEnd} title="Fim">
                              <input
                                type="date"
                                className="form-control"
                                placeholder="00/00/0000"
                                name="end_date"
                                aria-describedby="start-date"
                                ref={register}
                              />
                            </DateField>
                          </div>

                          <Button type="submit" className="apply-filters btn-scamb-action color-blue">
                            Aplicar <Icon icon={faCheck} />
                          </Button>
                        </form>
                      </div>
                    )}
                  </div>

                  <div className="content">
                    <TimelinePoints>
                      {extractExpirationItens?.slice(0, limitToShowExpiration).map((itemExpiration) => (
                        <StatusExtract
                          key={itemExpiration.transactionID}
                          hasExpired={checkExpiration(itemExpiration.expirationPeriod)}
                          icon={<Icon icon={faCalendarAlt} />}
                          title="Finalizado"
                          className={checkExpiration(itemExpiration.expirationPeriod) ? 'no-active' : '_active'}
                          date={formatDateToShow(itemExpiration.expirationPeriod, 'date')}
                          hour="11:54:16"
                          points={itemExpiration.points} />
                      ))}
                    </TimelinePoints>

                    <div className="wrap-button-load-more">
                      {limitToShowExpiration < extractExpirationItens?.length ? (<button onClick={() => setLimitToShowExpiration(limitToShowExpiration + 4)} type="button" className="btn load-more">
                        Carregar mais
                      </button>) : <></>}
                    </div>

                  </div>
                </TabPanel>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  )
}

export default ExtractModal;