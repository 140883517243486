import styled from "styled-components";

export const ListItem = styled.li`
    background-color: ${props => props.bgColor ? props.bgColor : "#ffffff" };
`;

export const Title = styled.div`
    font-size: 0.75rem;
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.grayBold};
    margin-bottom: 0;
`;

export const Value = styled.div``;
