import React from "react";

import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import PricePoints from "../../Display/price/points";
import Button from "../../Form/button";

import { Product, Column, ProductImage, Image, Content, Title, Equivalent, RealPrice } from "./styles";

function ProductInline({ productTitle, image, productPricePoints, productPrice, handleRemoveItem }) {

    return (
        <>
            <Product className="ProductInlineBox">
                <Column className="Column">
                    <ProductImage className="ProductInlineImage">
                        <Image src={image ? image : "https://via.placeholder.com/450x450"} />
                    </ProductImage>
                    <Content>
                        <Title>{productTitle}</Title>
                        <RealPrice> R$ {productPrice} </RealPrice>
                        {/* <Equivalent>
                            Equivalente a
                            <RealPrice> R$ {productPrice} </RealPrice>
                        </Equivalent> */}
                    </Content>
                </Column>
                <Column>
                    <Button
                        styleType="default"
                        buttonSize="small"
                        buttonColor="#F1F1F1"
                        borderColor="#F9F9F9"
                        onClick={handleRemoveItem}
                    ><Icon icon={["fal", "trash-alt"]} /></Button>
                </Column>
            </Product>
        </>
    )
}

export default ProductInline;

// productTitle
// image
// productPricePoints
// productPrice