import React from "react";

import Button from "../../../../../components/Form/button";

import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import {
    DrawerHead,
    DColumn,
    DrawerTitle,
} from "./styles";

export const DrawerHeader = ({ title, onClick, icon, cartCount }) => {

    return (
        <DrawerHead>
            <DColumn>
                <DrawerTitle>
                    {icon} {title} {cartCount}
                </DrawerTitle>
            </DColumn>
            <DColumn>
                <Button
                    styleType="default"
                    buttonSize="small"
                    buttonColor="#F1F1F1"
                    borderColor="#F9F9F9"
                    onClick={onClick}
                ><Icon icon={["far", "times"]} /></Button>
            </DColumn>
        </DrawerHead>
    )

}