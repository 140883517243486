import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import SearchIcon from "@material-ui/icons/Search";

import OrderService from './../../services/OrderPoints';
import Download from './../../services/Dowload';
import Swal from 'sweetalert2';

function OrdersPoints() {
  const [listOrders, setListOrders] = useState([])
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filterEmail, setFilterEmail] = useState();

  const loadOrders = (initialDate, endDate, filter) => {
    const orderService = new OrderService();

    Swal.fire('Carregando lista ... Aguarde');
    Swal.showLoading();
    orderService.loadOrdersPoints(initialDate, endDate, filter).then((response) => {
      Swal.close();
      console.log(response.data.payload);
      setListOrders(response.data.payload);
    }).catch((error) => {
      Swal.fire({
        type: "info",
        text: orderService.formatErrorMessage(error.response),
        title: "",
      });
    })
  }

  const getOrdersFile = () => {
    const download = new Download();

    Swal.fire('Gerando arquivo ... Aguarde');
    Swal.showLoading();
    download.getOrderPointsrFile(startDate, endDate, filterEmail).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset=utf-8;' }));
      var fileLink = document.createElement('a');
      const newDate = new Date();

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `venda_pontos_${newDate.getDate()}_${newDate.getMonth() + 1}_${newDate.getFullYear()}.csv`);
      document.body.appendChild(fileLink);

      Swal.close();
      fileLink.click();
    }).catch(error => {
      Swal.close();
      Swal.fire({
        type: "info",
        html: download.formatErrorMessage(error.response),
        title: "",
      });
    });
  }

  const onFilter = (e) => {
    e.preventDefault();
    const actualDate = new Date();
    const actualStartDate = actualDate.getFullYear() + '-01-01';
    const actualEndDate = actualDate.getFullYear() + '-12-31';
    
    loadOrders(startDate ? startDate : actualStartDate, endDate ? endDate : actualEndDate, filterEmail);
  }

  useEffect(() => {
    const actualDate = new Date();
    const actualStartDate = actualDate.getFullYear() + '-01-01';
    const actualEndDate = actualDate.getFullYear() + '-12-31';

    loadOrders(actualStartDate, actualEndDate, filterEmail);
  }, [])

  let formatterDate = (date) => {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear(),
      hora = data.getUTCHours().toString(),
      horaF = (hora.length == 1) ? '0' + hora : hora,
      minuto = data.getMinutes().toString(),
      minutoF = (minuto.length == 1) ? '0' + minuto : minuto,
      segundo = data.getSeconds().toString(),
      segundoF = (segundo.length == 1) ? '0' + segundo : segundo;

    return diaF + "/" + mesF + "/" + anoF + " " + horaF + ":" + minutoF + ":" + segundoF;
  }

  const clearFilter = (e) => {
    e.preventDefault();

    setStartDate('');
    setEndDate('');
    setFilterEmail('');
    
    loadOrders();
  }

  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">

          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title">Venda de pontos</div>
          </div>

          <div className="_wrap-filter-activity">
            <h2 className="title">Filtros</h2>
            <div className="_filter-activity">
              <form className="_form" onSubmit={(e) => onFilter(e)}>
                <div className="_wrap-dates">
                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Data inicial</label>
                    <div className="input-group">
                      <TextField
                        type="date"
                        className="form-control"
                        name="startDate"
                        placeholder="00/00/0000"
                        aria-describedby="cc-name"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Data final</label>
                    <div className="input-group">
                      <TextField
                        type="date"
                        className="form-control"
                        name="endDate"
                        placeholder="00/00/0000"
                        value={endDate}
                        aria-describedby="cc-name"
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Filtrar</label>
                    <div className="input-group">
                      <TextField
                        type="text"
                        className="form-control"
                        name="filterEmail"
                        value={filterEmail}
                        aria-describedby="cc-name"
                        onChange={(e) => setFilterEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="_wrap-input-group _submit">
                  <button type="submit" className="submit-filter">
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </form>
              <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div>
              <div className="excel-div">
                <button onClick={getOrdersFile} className="excel-button">Exportar .csv</button>
              </div>
            </div>
          </div>

          <Table bordered hover size="lg">
            <thead>
              <tr>
                <th>Nº do Pedido</th>
                <th>Nome comprador</th>
                <th>Scamber comprador</th>
                <th>Valor pontos</th>
                <th>Valor Reais</th>
                <th>Status do pedido</th>
                <th>Forma de Pagamento</th>
                <th>Data do pedido</th>
                <th>Hora do pedido</th>
              </tr>
            </thead>
            <tbody>
              {listOrders.map((order, index) => (
                <tr key={index}>
                  <td>{order._id}</td>
                  <td>{order.scamber[0].nome}</td>
                  <td>{order.scamber[0].email}</td>
                  <td>{order.valorEmPontos}</td>
                  <td>{order.valorEmReais}</td>
                  <td>{order.status_pedido}</td>
                  <td>{order.tipo_forma_pagamento}</td>
                  <td>{formatterDate(order.data_inclusao).split(' ')[0]}</td>
                  <td>{formatterDate(order.data_inclusao).split(' ')[1]}</td>
                </tr>
              ))}

            </tbody>
          </Table>

        </div>
      </main>
    </>
  );
}

export default OrdersPoints;
