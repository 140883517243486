import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';


export default function FolderList({ children, image, title, subtitle, url }) {
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <a href={url} target="_black">
            <Avatar variant="rounded">
              <img src={image} alt="" />
            </Avatar>
          </a>
        </ListItemAvatar>
        <ListItemText primary={title} secondary={subtitle} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        </ListItemText>
        {children}
      </ListItem>
    </>
  );
}