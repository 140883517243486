import React, { useState } from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import {
    Wrapper,
    Head,
    HeadColumn,
    Column,
    StatusCount,
    ButtonTag,
    ButtonContent,
    Title,
    Text,
    ButtonIcon,
    Content,
    ContentButtons,
    LineTitle,
    TitleLineAction,
    ButtonResultAction,
    ColumnWrapper,
    ButtonTitle,
    ButtonText,
} from "./styles";

export function ResultBlock({ title, value, numberCount, orderId, buyerName, orderDate, children, show }) {

    const [panel, setPanel] = useState(false);

    const handlePanel = () => {
        setPanel((prevState) => prevState = !prevState);
    }

    return (
        <>
        {show && (
        <Wrapper className="ResultBlock">
            <Head>
                <HeadColumn>
                    {/* <StatusCount>
                        #{numberCount}
                    </StatusCount> */}
                    <ButtonTag>
                        <ButtonContent>
                            <Title>{title}</Title>
                            {/* <Text>{title}</Text> */}
                        </ButtonContent>
                    </ButtonTag>
      
                </HeadColumn>
                <HeadColumn>
                    <ButtonIcon type="button" onClick={handlePanel}>
                        {panel
                            ? (<Icon icon={["far", "chevron-up"]} />)
                            : (<Icon icon={["far", "chevron-down"]} />)
                        }
                    </ButtonIcon>
                </HeadColumn>
            </Head>
            {panel && (
                <Content>
                    {children}
                </Content>
            )}
        </Wrapper>
        )}
        </>
    )
}