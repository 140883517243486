import Service from './Service';

export default class Coupons extends Service {

  createCoupon(couponObject) {
    return this.axiosInstance.post(`/coupons`, couponObject,);
  }

  updateCoupon(couponObject) {
    return this.axiosInstance.put(`/coupons`, couponObject);
  }

  getCoupon(userId) {
    return this.axiosInstance.get(`/coupons/${userId}`);
  }

  getAllCoupon(search) {
    return this.axiosInstance.get(`/coupons?search=${search ? search : ''}`);
  }

  getCouponById(cuponId) {
    return this.axiosInstance.get(`/coupons/byid/${cuponId}`);
  }

  validateCouponCodeAndUpdataCart({ couponCode, cartId, website }) {
    return this.axiosInstance.get(`/coupons/cart?couponCode=${couponCode}&cartId=${cartId}&website=${website}`);
  }

  findCoupons(couponsArray, website) {
    return this.axiosInstance.get(`/coupons/cart/find?couponsArray=${couponsArray}&website=${website}`);
  }

  deleteCoupon(cartId, couponId) {
    return this.axiosInstance.delete(`/coupons/cart`, { data: { cartId, couponId } }); 
  }
}