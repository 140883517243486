import Service from './Service';

class Showcase extends Service {

  constructor() {
    super();
  }

  createShowcase(showcaseObject, website) {
    return this.axiosInstance.post(`/showcase${website? '?website=' + website: '?website=scamb'}`, showcaseObject, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  updateShowcase(showcaseObject) {
    return this.axiosInstance.put(`/showcase`, showcaseObject);
  }

  getAllShowcases(filter, website) {
    return this.axiosInstance.get(`/showcase?filter=${filter || ''}${website? '&website=' + website: '&website=scamb'}`);
  }

  getOneShowcases(id) {
    return this.axiosInstance.get(`/showcase/oneshowcase/${id}`);
  }

  deleteShowcase(id){
    return this.axiosInstance.delete(`/showcase`, { data: { showcaseid: id }});
  }
}
export default Showcase;