import Service from './Service';
import axios from 'axios';

export default class Address extends Service {
  
  findCep(cep) {
    return axios.get(`https://viacep.com.br/ws/${cep}/json`);
  }

}
