import React, { useState } from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import {
    Wrapper,
    Head,
    HeadColumn,
    Column,
    StatusCount,
    ButtonTag,
    ButtonContent,
    Title,
    Text,
    ButtonIcon,
    Content,
    ContentButtons,
    LineTitle,
    TitleLineAction,
    ButtonResultAction,
    ColumnWrapper,
    ButtonTitle,
    ButtonText,
} from "./styles";

export const ButtonContentTag = ({ tagKey, value }) => {

    return (
        <ButtonTag>
            <ButtonContent>
                <Title>{tagKey}:</Title>
                <Text>{value}</Text>
            </ButtonContent>
        </ButtonTag>
    )
}

export function ResultBlockTags({ children }) {

    return (
        <>
            <ContentButtons>
                {children}
            </ContentButtons>
        </>
    )
}