import React, { useRef, useEffect, useState } from 'react';

import { Table, Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import SweetAlert from 'sweetalert';

import { 
  TextField, 
  useMediaQuery, 
  Grid,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  List, 
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import SubjectIcon from "@material-ui/icons/Subject";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ButtonMaterial from '@material-ui/core/Button';

import {
  faTrashAlt,
  faChevronRight,
  faChevronLeft,
  faTruck,
  faMobile,
  faHandHoldingBox,
  faCheck,
} from "@fortawesome/pro-light-svg-icons";
import { faCamera } from "@fortawesome/pro-solid-svg-icons";

import Domain from '../../services/Domain';
import Categorizacao from '../../services/Categorizacao';
import QuickLInkService from '../../services/QuickLink';
import AnuncioService from '../../services/Anuncios';

import ModalDialog from '../../components/ModalDialog';
import GridProduct from '../../components/GridProduct';
import ProductCard from '../../components/ProductCard';

function QuickLink() {
  const matches = useMediaQuery("(min-width:600px)");

  const refModalAddQuickLink = useRef();
  const refModalDialogCategory = useRef();
  const refModalDialogSelectProduct = useRef();
  const quickLink = new QuickLInkService();

  const [quickLinks, setQuickLink] = useState();
  const [selectedQuickLink, setselectedQuickLink] = useState();
  const [selectedImages, setselecImagens] = useState([0, 0, 0, 0]);
  const [imagens, setImanges] = useState();
  const [category, setCategory] = useState("");
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState({ nome: "" });
  const [conditions, setConditions] = useState([]);
  const [condition, setCondition] = useState({ nome: "" });
  const [listSelectedCategorization, setListSelectedCategorization] = useState([]);
  const [listActiveCategorization, setListActiveCategorization] = useState([]);
  const [allCategorizationLabel, setAllCategorizationLabel] = useState("");
  const [listCategorization, setListCategorization] = useState([]);
  const [check, setCheck] = useState({ pause_adjustment: true });
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState();

  const [tipoFiltro, setTipoFiltro] = useState("filters");
  const [searchAds, setSearchAds] = useState();
  const [selectedAds, setSelectedAds] = useState();
  const [limit, setLimit] = useState(8);
  const [searchKey, setSearchKey] = useState("");
  const [deliveryType, setDeliveryType] = useState();
  const [points, setPoints] = useState();
  const [price, setPrice] = useState();
  const [store, setStore] = useState();

  const [runSearch, setRunSearch] = useState(0);

  const { handleSubmit, register, errors, reset, setValue } = useForm();
  const { handleSubmit: handleSubmitFilter, register: registerFilter } = useForm();
  const { handleSubmit: handleSubmitSearchFilter, register: registerSearchFilter, reset: resetSearchFilter } = useForm();


  const openAddQuickLinkseModal = () => {
    setIsEdit(false);
    reset({});
    setBrand([]);
    setCondition([]);
    setCheck({ pause_adjustment: true });
    setListSelectedCategorization([]);
    setImanges([]);
    setTipoFiltro("filters");
    setLimit(8);
    setselecImagens([0,0,0,0]);
    setSelectedAds();
    
    refModalAddQuickLink.current.openModal();
  }

  const openEditQuickLinkModal = (quickLinkData) => {
    Swal.fire('Carregando Links Rápidos... Aguarde');
    Swal.showLoading();
    setIsEdit(true);
    setselectedQuickLink(quickLinkData._id);

    quickLink.getOneQuickLinks(quickLinkData._id).then((response) => {
      const data = response.data.payload;

      setValue("titulo", data.title);
      setValue("filterby", data.filters.filterbyKey);
      setValue("filterType", data.filter_type);
      setValue("order", data.filters.orderBy);
      setValue("pontosMinimos", data.filters.points? data.filters.points.split('-')[0]: 0);
      setValue("pontosMaximos", data.filters.points? data.filters.points.split('-')[1]: 9999);
      setValue("operador_logistico", data.filters.operador_logistico);
      setValue("entrega_maos", data.filters.entrega_maos);
      setValue("storeUrl", data.filters.storeUrl);
      setBrand(data.filters.brand[0]);
      setCondition(data.filters.condition[0]);
      setTipoFiltro(data.filter_type? data.filter_type: 'filters');
      setSelectedAds(data.anuncios);
      setCheck({ pause_adjustment: data.disabled });
      setLimit(8);
      setListSelectedCategorization(
        data.filters.categoryList.map((cat) => {
          return { categorization: cat };
        })
      );

      data.banners.map((foto, index) =>
        quickLink.loadImageFile(foto).then((file) => {
          setSelectedImages({ target: { files: [file] } }, index);
        })
      );
      Swal.close();
      refModalAddQuickLink.current.openModal();
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: quickLink.formatErrorMessage(err.response),
        title: "",
      });
    });
  }

  const deleteQuickLink = (quickLinkData) => {

    SweetAlert({
      title: "Deletar Links Rápidos ?",
      text: `Ao clicar em confirmar, O Link Rápido será excluida permanentemente`,
      icon: "warning",
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        quickLink.deleteQuickLink(quickLinkData._id).then((response)=>{
          loadQuickLink(filter);
          SweetAlert({
              title: "Tudo pronto!",
              text: `Links Rápidos excluida`,
              icon: "success",
              buttons: [false, "Ok"],
              dangerMode: true,
            })
        }).catch((error)=> {
          Swal.fire({
            type: "info",
            html: quickLink.formatErrorMessage(error.response, 'DELS'),
            title: "",
          });
          
        });    
      }
    });
  }

  const openCategorizationModal = () => {
    setListActiveCategorization(listCategorization);
    setListSelectedCategorization([]);
    setAllCategorizationLabel("");

    refModalDialogCategory.current.openModal();
  };

  const onFilter =(data) => {
    setFilter(data.filter);
    loadQuickLink(data.filter);
  }

  const loadInfo = () => {
    const categorization = new Categorizacao();
    const domain = new Domain();
  
    categorization
      .list()
      .then((response) => {
        if (response.status === 200) {
          setListCategorization(response.data.payload);
          setListActiveCategorization(response.data.payload);
        }
      })
      .catch((error) =>
        categorization.formatErrorMessage(error.response, 'CALICT'),
      );
  
    domain
      .listBrands()
      .then((response) => {
        if (response.status === 200) {
          setBrands(response.data.payload);
        }
      })
      .catch((error) =>
        domain.formatErrorMessage(error.response, 'CALIBDO'),
      );
  
    domain
      .listConditions()
      .then((response) => {
        if (response.status === 200) {
          setConditions(response.data.payload);
        }
      })
      .catch((error) =>
        domain.formatErrorMessage(error.response, 'CALICDO'),
      );
  };

  const onSelectCategorization = (categorizationlist) => {
    let selectedCategorizations = categorizationlist
      ? categorizationlist
      : listSelectedCategorization;

    let categorizationIdsSelected = selectedCategorizations.map(
      (selectedCategorization) => {
        return selectedCategorization.categorization._id;
      }
    );
  }

  const hasChindren = (categorization) => {
    if (categorization.categorias && categorization.categorias.length > 0) {
      return true;
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      return true;
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      return true;
    }

    return false;
  };

  const setSelectedImages = (e, index) => {
    var reader = new FileReader();
    var file = e.target.files[0];
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      var image = new Image();
      image.src = e.target.result;
      image.onload = function () {
        if (this.width < this.height) {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_portrait' });
        } else if (this.width > this.height) {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_landscape' });
        } else {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_square' });
        }
        setImanges([...selectedImages]);
      }
    }
  };

  const removeImage = (e, index) => {
    selectedImages.splice(index, 1, 0);
    setImanges([...selectedImages]);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start'
  });

  const handleCondition = (e, condition) => {
    setCondition(condition);
  };

  const handleBrand = (e, brand) => {
    setBrand(brand);
  };

  const handleChangeCheckbox = (event) => {
    const { target } = event;
    setCheck({ ...check, [target.name]: target.checked });
  };

  const handleCategorizationBack = (oldCategorizationList, index) => {
    const listSelected = listSelectedCategorization.slice(0, index);
    setListSelectedCategorization(listSelected);

    setListActiveCategorization(oldCategorizationList);
  };

  const handleCategorization = (categorization) => {
    setCategory(categorization.nome);

    let listSelected;
    if (categorization.categorias && categorization.categorias.length > 0) {
      setListActiveCategorization(categorization.categorias);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      setListActiveCategorization(categorization.tipos_produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      setListActiveCategorization(categorization.produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else {
      listSelected = listSelectedCategorization;
      const lastIndex = listSelected.length > 0 ? listSelected.length - 1 : 0;

      if (listSelected[lastIndex] && listSelected[lastIndex].lastLevel) {
        listSelected[lastIndex] = {
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        };
        setListSelectedCategorization(listSelected);
      } else {
        listSelected.push({
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        });
        setListSelectedCategorization(listSelected);
      }
    }
  };

  const handleAddQuickLink = (data) => {
    let selectedBrand;

    if (brand && brand.nome !== data.brand) {
      selectedBrand = { nome: data.brand };
      setBrand({ nome: data.brand })
    } else if (brand) {
      selectedBrand = brand;
    } else {
      selectedBrand = { nome: data.brand };
      setBrand({ nome: data.brand })
    }
    
    const formatedData = {
      selectedImages: imagens,
      title: data.titulo,
      filter_type: data.filterType,
      filterbyKey: data.filterby,
      condition,
      listSelectedCategorization,
      brand: selectedBrand,
      order: data.order,
      products: selectedAds,
      pontosMinimos: data.pontosMinimos,
      pontosMaximos: data.pontosMaximos,
      deliveryTypes: {
        operador_logistico: data.operador_logistico ? true : false,
        aplicativo_entrega: data.aplicativo_entrega ? true : false,
        entrega_maos: data.entrega_maos ? true : false,
      },
      disabled: check.pause_adjustment,
      storeUrl: data.storeUrl
    };
    
    if(isEdit) {
      formatedData._id = selectedQuickLink;
    }

    const formDataSc = new FormData();
    formDataSc.append("quickLink", JSON.stringify(formatedData));

    if (!imagens) return Swal.fire('Selecione ao menos uma imagem para o link rápido', '', 'error');

    imagens.map((image, index) => {
      if (image !== 0) {
        formDataSc.append("image" + (index + 1), image.file);
      }
    });

    if(isEdit) {
      Swal.fire('Salvando Links Rápidos... Aguarde');
      Swal.showLoading();
      quickLink.updateQuickLink(formDataSc).then((response) => {
        refModalAddQuickLink.current.closeModal();
        reset();
        SweetAlert({
          title: "",
          text: response.data.message ? response.data.message : 'Links Rápidos editada com sucesso!',
          icon: "success",
        }).then(() => {
          loadQuickLink(filter);
        });
      });
    } else {
      Swal.fire('Criando Links Rápidos... Aguarde');
      Swal.showLoading();
        if(check.pause_adjustment) {
          SweetAlert({
            title: "Links Rápidos",
            text: `Lembrando! O Link Rápido está sendo criada desativada`,
            icon: "info",
            buttons: [, "Confirmar"],
          }).then(function (isConfirm) {
            if (isConfirm) {
              quickLink.createQuickLink(formDataSc).then((response) => {
                loadQuickLink(filter);
                refModalAddQuickLink.current.closeModal();
                reset();
                SweetAlert({
                  title: "",
                  text: response.data.message ? response.data.message : 'Links Rápidos incluida com sucesso!',
                  icon: "success",
                })
              }).catch((error) => {
                Swal.fire({
                  type: "info",
                  html: quickLink.formatErrorMessage(error.response),
                  title: "",
                });
              });
            }
          });
        } else {
          SweetAlert({
            title: "Links Rápidos",
            text: `O Link Rápido está sendo criada ativada`,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          }).then(function (isConfirm) {
            if (isConfirm) {
              quickLink.createQuickLink(formDataSc).then((response) => {
                loadQuickLink(filter);
                refModalAddQuickLink.current.closeModal();
                reset();
                SweetAlert({
                  title: "",
                  text: response.data.message ? response.data.message : 'Links Rápidos incluida com sucesso!',
                  icon: "success",
                })
              }).catch((error) => {
                Swal.fire({
                  type: "info",
                  html: quickLink.formatErrorMessage(error.response),
                  title: "",
                });
              });
            }
          });
        } 
    }
  }

  const loadQuickLink = (filter) => {
    Swal.fire('Carregando Links Rápidos ... Aguarde');
    Swal.showLoading();
    quickLink.getAllQuickLinks(filter).then((response) => {
      Swal.close();
      setQuickLink(response.data.payload);
    }).catch((error) => {
      Swal.fire({
        type: "info",
        html: quickLink.formatErrorMessage(error.response),
        title: "",
      });
    });
  }

  useEffect(() => {
    loadQuickLink();
    loadInfo();
  }, []);

  useEffect(() => {
    setAllCategorizationLabel(
      listSelectedCategorization.reduce((label, selected, index) => {
        return (label += ` ${index > 0 ? ">" : ""} ${selected.categorization.nome
          }`);
      }, "")
    );
  }, [
    listSelectedCategorization.length,
    listSelectedCategorization[listSelectedCategorization.length - 1],
  ]);

  const clearFilter = (e) => {
    e.preventDefault();

    setFilter('');
    loadQuickLink();
  }

  const onSearch = (data) => {
    console.log(data, 'data');

    setSearchKey(data.filterby);
    setDeliveryType(data.frete_type);
    setPoints(`${data.pontosMinimos}-${data.pontosMaximos}`);
    setPrice(data.order);
    setStore(data.storeUrl);

    setRunSearch(runSearch + 1);

    /* searchAnuncio({
      limit: limit,
      key: data.filterby,
      deliveryType: data.frete_type,
      points: `${data.pontosMinimos}-${data.pontosMaximos}`,
      price: data.order,
      storeUrl: data.storeUrl
    });*/
  }

  const searchAnuncio = React.useCallback(() => {
    const anuncioService = new AnuncioService();

    let key = '';
    if (searchKey) {
      if (searchKey.indexOf('&') !== -1) key = searchKey.replace(/&/g, 'ampersand');
    }

    anuncioService.findAdsByUserSearch({
      searchString: key,
      limit: limit,
      brand: brand ? brand._id : null,
      category: listSelectedCategorization.length > 0 ? listSelectedCategorization[listSelectedCategorization.length - 1].categorization._id : null,
      condition: condition ? condition._id : null,
      deliveryType: deliveryType ? deliveryType : null,
      points: points ? points : null,
      price: price ? price : null,
      website: 'shopping',
      statusAd: 'ativo',
      storeUrl: store
    }).then(response => {
      if(store) return setSearchAds(response.data.payload.ad);
      setSearchAds(response.data.payload);
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: anuncioService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }, [limit, searchKey, brand, listSelectedCategorization, condition, deliveryType, points, price, store]);

  const addAnuncios = (e, anuncio) => {
    e.preventDefault();

    if (selectedAds) {
      const existAnuncio = selectedAds.find((a) => a._id === anuncio._id);

      if (!existAnuncio) {
        setSelectedAds([...selectedAds, anuncio]);
      }
    } else {
      setSelectedAds([anuncio]);
    }
  }

  const removeAnuncios = (e, anuncio) => {
    e.preventDefault();

    const excludesAnuncio = selectedAds.filter((a) => a._id !== anuncio._id);
    setSelectedAds(excludesAnuncio);
  }

  const openSelectProductModal = async () => {
    setBrand([]);
    setCondition([]);
    setListSelectedCategorization([]);
    setSearchKey('');
    
    refModalDialogSelectProduct.current.openModal();
    setRunSearch(runSearch + 1);
    // searchAnuncio({ limit: limit, key: "" });
  }

  useEffect(() => {
    if (tipoFiltro !== 'filters') {
      searchAnuncio();
    }
    
  }, [tipoFiltro, runSearch])

  const loadMoreAds = () => {
    const newLimit = limit + 8;
    setLimit(newLimit);

    setRunSearch(runSearch + 1);
    /*searchAnuncio({
      limit: newLimit,
      key: searchKey,
      deliveryType: deliveryType ? deliveryType : null,
      points: points ? points : null,
      price: price ? price : null,
    });*/
  }

  const onCleanFilter = () => {
    resetSearchFilter({})
    setBrand([]);
    setCondition([]);
    setListSelectedCategorization([]);
    setLimit(8);
    setSearchKey("");
    setDeliveryType();
    setPoints();
    setPrice();
    setStore();

    setRunSearch(runSearch + 1);
    // searchAnuncio({ limit: 8, key: "" })
  }

  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">
          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title"> Links Rápidos</div>
          </div>

          <div className="_wrap-filter-activity">
            <h2 className="title">Filtrar</h2>
            <div className="_filter-activity">
              <form className="_form _search" onSubmit={handleSubmitFilter(onFilter)}>
                <div className="form-group search">

                  <TextField
                    id="filterQuickLink"
                    label="Filtrar *"
                    variant="outlined"
                    size={matches ? "small" : "small"}
                    name="filter"
                    fullWidth
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    error={!!errors.qtd_points}
                    inputRef={registerFilter()}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      style: { height: '39px' }
                    }}
                  />

                  <button type="submit">
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </form>
              <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div>
              <div className="excel-div">
                <button className="excel-button" onClick={() => openAddQuickLinkseModal()}>Criar Link Rápido</button>
              </div>
            </div>
          </div>

          <Table bordered hover size="lg">
            <thead>
              <tr>
                <th>Título</th>
                <th>Ativo</th>
                <th>Editar</th>
                <th>Deletar</th>
              </tr>
            </thead>
            <tbody>
              {quickLinks && quickLinks.map(quickLink =>
                <tr key={quickLink._id}>
                  <td>{quickLink.title}</td>
                  <td>{quickLink.disabled ? 'Não' : 'Sim'}</td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 35 : 40 }}
                      onClick={() => openEditQuickLinkModal(quickLink)}
                    >
                      Editar
                    </Button>
                  </td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 35 : 40 }}
                      onClick={() => deleteQuickLink(quickLink)}
                    >
                      Deletar
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </main>

      <ModalDialog
        ref={refModalAddQuickLink}
        dialogTitle="Incluir Links Rápido"
        onCloseModal={
          <button
            type="button"
            className="btn"
            onClick={() => {
              reset({}); refModalAddQuickLink.current.closeModal()
            }}
          >
            {matches ? <CloseIcon /> : <ChevronLeftIcon />}
          </button>
        }>
        <div className="_wrap-form">
          <form className="_form" onSubmit={handleSubmit(handleAddQuickLink)}>
          <h1 className="_content-title">Preencha as informações para incluir o Link Rápido</h1>
          <ModalDialog
            customMethod={onSelectCategorization}
            dialogTitle="Categorias"
            btnTitle="Aplicar"
            className="_modal-category"
            ref={refModalDialogCategory}
          >
            <span className="_content">
              <a href="#!" className="tag-selected-radio">
                {listSelectedCategorization.map(
                  (selectedCategorization, index) => {
                    if (index === 0) {
                      return (
                        <div
                          onClick={() =>
                            handleCategorizationBack(
                              selectedCategorization.oldCategorizationList,
                              index
                            )
                          }
                          key={index}
                          className="_title-selected _only"
                        >
                          <FontAwesomeIcon
                            className="icon"
                            icon={faChevronLeft}
                          ></FontAwesomeIcon>
                          <span class="_text">

                            {selectedCategorization.categorization.nome}
                          </span>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          onClick={() =>
                            handleCategorizationBack(
                              selectedCategorization.oldCategorizationList,
                              index
                            )
                          }
                          key={index}
                          className="_title-selected _only _multiple"
                        >
                          <span class="_text">

                            {selectedCategorization.categorization.nome}
                          </span>
                        </div>
                      );
                    }
                  }
                )}
              </a>

              <ul id="drilldown">
                {listActiveCategorization.map((categorization, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type="radio"
                        name="select_category_lvl_1"
                        checked={
                          category === categorization.nome ? "checked" : ""
                        }
                        onChange={() => handleCategorization(categorization)}
                      />
                      <span className="_name">{categorization.nome}</span>
                      {hasChindren(categorization) ? (
                        <div className="icon">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                          ></FontAwesomeIcon>
                        </div>
                      ) : (
                          <></>
                        )}
                    </label>
                  </li>
                ))}
              </ul>
            </span>
          </ModalDialog>

          <section className="_add-photos">
            <div className="form-group _photos">
              <div className="_label">
                Fotos *
              </div>
              <div className="_wrap-photos">
                <div className="_wrap-photo-item">
                  <div style={{ width: "150px", height: "120px" }} className="_photo-item">
                    <input
                      type="file"
                      accept="image/x-png, image/jpeg"
                      name={`photo-upload1`}
                      id={`photo-upload1`}
                      className="form-control-file"
                      onChange={(e) => setSelectedImages(e, 0)}
                    />
                      {selectedImages[0] ? (
                        <div className="_image-selected">
                        <img
                          src={selectedImages[0].show}
                          alt=""
                          className={"my-image "}
                        />

                        <button
                          type="button"
                          className="btn btn-transparent _delete-image"
                          onClick={(e) => removeImage(e, 0)}
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                          ></FontAwesomeIcon>
                        </button>
                      </div>
                      ) : (
                        <></>
                      )}
                        
                    
                    <label htmlFor={`photo-upload1`}>
                      <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>

                      <div className="_title-label">Adicionar foto principal</div>

                      <div className="_accept-formats-label">
                        JPG, GIF E PNG Somente
                        </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </section>
          
            <div id="view_create-ad"> 
              <section className="form-body">
                <Grid container spacing={2}>
                  
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-add-title"
                      label="Título *"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="titulo"
                      fullWidth
                      helperText={!!errors.titulo && "Campo não pode ficar em branco"}
                      error={!!errors.titulo}
                      inputRef={register({
                        required: true,
                      })}
                      inputProps={{
                        maxLength: 40,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item md={12} xs={12} style={{ marginBottom: '5px' }}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                        Tipo de Filtro *
                      </InputLabel>
                      <Select
                        native
                        label="Tipo de Filtro"
                        error={!!errors.filterType}
                        inputRef={register({
                          required: true
                        })}
                        inputProps={{
                          name: "filterType",
                          id: "filterType"
                        }}
                        onChange={(e) => setTipoFiltro(e.target.value)}
                      >
                        <option value="filters">Filtros</option>
                        <option value="products">Seleção de Produtos</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  {tipoFiltro === 'filters' ? (
                    <>
                      <Grid item xs={12}>
                        <h1 className="_content-title">Filtros</h1>
                        <TextField
                          id="outlined-add-title"
                          label="Filtrar por"
                          type="text"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="filterby"
                          fullWidth
                          inputRef={register({
                            required: false,
                          })}
                          inputProps={{
                            maxLength: 40,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="outlined-add-title"
                          label="Loja"
                          type="text"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="storeUrl"
                          fullWidth
                          inputRef={register({
                            required: false,
                          })}
                          inputProps={{
                            maxLength: 40,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      {/* Condition */}
                      <Grid item xs={12}>
                        <Autocomplete
                          id="outlined-condition"
                          autoComplete={true}
                          options={conditions}
                          getOptionLabel={(option) => option.nome}
                          getOptionSelected={(option) => option._id}
                          size="small"
                          fullWidth
                          value={condition}
                          onChange={(e, value) => handleCondition(e, value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Condição"
                              name="add_condition"
                              variant="outlined"
                              size={matches ? "small" : "small"}
                              error={!!errors.add_condition}
                              inputRef={register({
                                required: false,
                              })}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </Grid>

                      {/* Category */}
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-category"
                          label="Categoria"
                          type="text"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="add_category"
                          value={allCategorizationLabel}
                          fullWidth
                          error={!!errors.add_category}
                          inputRef={register({
                            required: false,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onClick={() => openCategorizationModal()}
                          inputProps={{
                            endadornment: (
                              <InputAdornment position="end">
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                ></FontAwesomeIcon>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      {/* Brand */}
                      <Grid item xs={12}>
                        <Autocomplete
                          filterOptions={filterOptions}
                          id="outlined-brand"
                          options={brands}
                          includeInputInList
                          freeSolo
                          getOptionLabel={(option) => option.nome}
                          getOptionSelected={(option) => option._id}
                          fullWidth
                          value={brand}
                          onChange={(e, value) => handleBrand(e, value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Marca"
                              name="brand"
                              variant="outlined"
                              size={matches ? "small" : "medium"}
                              error={!!errors.brand}
                              inputRef={register({
                                required: false,
                              })}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                            Ordenar por
                          </InputLabel>
                          <Select
                            native
                            label="Ordernar por"
                            inputRef={register({
                              required: false
                            })}
                            inputProps={{
                              name: "order",
                            }}
                          >
                            <option value=""></option>
                            <option value={-1}>Maior Preço</option>
                            <option value={1}>Menor Preço</option>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <TextField
                          id="outlined-login"
                          label="Pontos: Mínimo"
                          type="number"
                          variant="outlined"
                          size="small"
                          name="pontosMinimos"
                          defaultValue={0}
                          fullWidth
                          inputRef={register({
                            required: true,
                          })}
                        />
                      </Grid>

                      <Grid item md={6} xs={6}>
                        <TextField
                          id="outlined-login"
                          label="Pontos: Máximo"
                          type="number"
                          variant="outlined"
                          size="small"
                          name="pontosMaximos"
                          defaultValue={9999}
                          fullWidth
                          inputRef={register({
                            required: true,
                          })}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item md={12} xs={12} className="WrapButtonSubmit">
                        <h1 className="_content-title">Seleção de Produtos</h1>
                        <Button onClick={() => openSelectProductModal()}>
                          Selecionar Produtos
                        </Button>
                      </Grid>
                    </>
                  )}

                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={<Switch checked={check.pause_adjustment} onChange={handleChangeCheckbox} name="pause_adjustment" color="primary" />}
                      label="Desativar Links Rápidos"
                    />
                  </Grid>
                </Grid>

                {tipoFiltro === 'filters' && (
                  <section className="_select-freight">
                    <div className="_label">Tipo de entrega *</div>
                    <Grid container spacing={2} className="_wrap-freight">
                      <Grid item md={2}>
                        <div className="_wrap-check-radio">
                          <label className="_check-radio">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="operador_logistico"
                              //error={!!errors.operador_logistico}
                              ref={register({
                                required: false
                              })}
                            />
                            <span className="overlay"></span>
                            <div className="_wrap-alt-icon">
                              <div className="_alt-icon">
                                <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
                              </div>
                              <span>Correios</span>
                            </div>
                            <span className="check">
                              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                            </span>
                          </label>
                        </div>
                      </Grid>
                      {false ? (<Grid item md={2}>
                        <div className="_wrap-check-radio">
                          <label className="_check-radio">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="aplicativo_entrega"
                              //error={!!errors.aplicativo_entrega}
                              ref={register({
                                required: false
                              })}
                            />
                            <span className="overlay"></span>
                            <div className="_wrap-alt-icon">
                              <div className="_alt-icon">
                                <FontAwesomeIcon icon={faMobile}></FontAwesomeIcon>
                              </div>
                              <span>Aplicativo de entrega</span>
                            </div>
                            <span className="check">
                              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                            </span>
                          </label>
                        </div>
                      </Grid>) : (<></>)}
                      <Grid item md={2}>
                        <div className="_wrap-check-radio">
                          <label className="_check-radio">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="entrega_maos"
                              //error={!!errors.entrega_maos}
                              ref={register({
                                required: false
                              })}
                            />
                            <span className="overlay"></span>
                            <div className="_wrap-alt-icon">
                              <div className="_alt-icon">
                                <FontAwesomeIcon icon={faHandHoldingBox}></FontAwesomeIcon>
                              </div>
                              <span>Entrega em mãos</span>
                            </div>
                            <span className="check">
                              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                            </span>
                          </label>
                        </div>
                      </Grid>
                    </Grid>
                  </section>
                )}

                <Button
                  type="submit"
                  className="btn-step"
                  style={{ height: matches ? 35 : 35, marginBottom: '20px' }}
                >
                  Concluir
                </Button>
              </section>
            </div>
          </form>
        </div>
      </ModalDialog>

      <ModalDialog
        dialogTitle="Seleção de Produtos"
        className="_modal-category"
        maxWidth="lg"
        btnTitle="Aplicar"
        ref={refModalDialogSelectProduct}
      >
        <div className="select-product-container">
          <div className="search-products-container">
            <div className="_wrap-form" style={{ marginBottom: '20px', marginTop: '20px' }}>
              <form className="_form" onSubmit={handleSubmitSearchFilter(onSearch)}>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-add-title"
                      label="Filtrar por"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="filterby"
                      fullWidth
                      inputRef={registerSearchFilter({
                        required: false,
                      })}
                      inputProps={{
                        maxLength: 40,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
 
                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-add-title"
                      label="Loja"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="storeUrl"
                      fullWidth
                      inputRef={registerSearchFilter({
                        required: false,
                      })}
                      inputProps={{
                        maxLength: 40,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                        Ordenar por
                      </InputLabel>
                      <Select
                        native
                        label="Ordernar por"
                        inputRef={registerSearchFilter({
                          required: false
                        })}
                        inputProps={{
                          name: "order",
                        }}
                      >
                        <option value=""></option>
                        <option value={-1}>Maior Preço</option>
                        <option value={1}>Menor Preço</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-category"
                      label="Categoria"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="add_category"
                      value={allCategorizationLabel}
                      fullWidth
                      error={!!errors.add_category}
                      inputRef={registerSearchFilter({
                        required: false,
                      })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={() => openCategorizationModal()}
                      inputProps={{
                        endadornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon
                              icon={faChevronRight}
                            ></FontAwesomeIcon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <Autocomplete
                      filterOptions={filterOptions}
                      id="outlined-brand"
                      options={brands}
                      includeInputInList
                      freeSolo
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      fullWidth
                      value={brand}
                      onChange={(e, value) => handleBrand(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Marca"
                          name="brand"
                          variant="outlined"
                          size={matches ? "small" : "medium"}
                          error={!!errors.brand}
                          inputRef={registerSearchFilter({
                            required: false,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <Autocomplete
                      id="outlined-condition"
                      autoComplete={true}
                      options={conditions}
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      size="small"
                      fullWidth
                      value={condition}
                      onChange={(e, value) => handleCondition(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Condição"
                          name="add_condition"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          error={!!errors.add_condition}
                          inputRef={registerSearchFilter({
                            required: false,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                        Tipo de Frete
                      </InputLabel>
                      <Select
                        native
                        label="Tipo de Frete"
                        inputRef={registerSearchFilter({
                          required: false
                        })}
                        inputProps={{
                          name: "frete_type",
                        }}
                      >
                        <option value=""></option>
                        <option value={"operador_logistico"}>Correios</option>
                        <option value={"entrega_maos"}>Entrega em mãos</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-login"
                      label="Pontos: Mínimo"
                      type="number"
                      variant="outlined"
                      size="small"
                      name="pontosMinimos"
                      defaultValue={0}
                      fullWidth
                      inputRef={registerSearchFilter({
                        required: true,
                      })}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-login"
                      label="Pontos: Máximo"
                      type="number"
                      variant="outlined"
                      size="small"
                      name="pontosMaximos"
                      defaultValue={9999}
                      fullWidth
                      inputRef={registerSearchFilter({
                        required: true,
                      })}
                    />
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <Button type='submit' style={{ marginTop: '3px' }}>
                      Aplicar
                    </Button>
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <Button color="secondary" style={{ marginTop: '3px' }} onClick={() => onCleanFilter()}>
                      Limpar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
            <Grid container spacing={2}>
              {searchAds && searchAds.map((ad, index) => (
                <Grid item lg={3} md={4} sm={4} xs={6} key={index}>
                  <GridProduct
                    link={`/produto?id=${ad._id}`}
                    image={
                      quickLink.loadImage(ad.fotos[0])
                        ? quickLink.loadImage(ad.fotos[0])
                        : ""
                    }
                    weight={ad.tamanho_exibicao?.nome}
                    title={ad.titulo}
                    discount={
                      ad.pontos_de
                        ? ad.pontos_de.$numberDecimal
                        : ""
                    }
                    price={
                      ad.pontos_por
                        ? ad.pontos_por.$numberDecimal
                        : ""
                    }
                    type={ad.tipo_anuncio}
                    cashback={ad.cashback}
                    cashDiscount={ad.valor_de}
                    cashPrice={ad.valor_por}
                    website='shpping'
                    onClick={(e) => addAnuncios(e, ad)}
                  />
                </Grid>
              ))}

            </Grid>

            <div className="_wrap-load-more">
              {limit <= searchAds?.length ? (<button type="submit" onClick={() => loadMoreAds()} className="btn btn-red">Carregar mais</button>) : null}
            </div>
          </div>

          {selectedAds?.length > 0 && (
            <div className="alredy-select-container">
              <div className="_title-select-product">Produtos já selecionados</div>

              <List className="alredy-select-list">
                {selectedAds && selectedAds.map((ad, index) => (
                  <ProductCard
                    key={ad._id}
                    image={quickLink.loadImage(ad.fotos[0])}
                    title={ad.titulo}
                    // subtitle={`R$${ad?.taxa_scamb?.$numberDecimal || 0}`}
                    url={ad.url_encurtada}
                  >
                    <ButtonMaterial
                      endIcon={<DeleteIcon color="secondary" />}
                      onClick={(e) => { removeAnuncios(e, ad) }}
                    />
                  </ProductCard>
                ))}
              </List>
            </div>
          )}
        </div>
      </ModalDialog>
    </>
  )
}

export default QuickLink;