import React from 'react'

import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import { Price, PricePoints } from "../../../../../components/ui/price";

import { OrderDetailsList, OrderDetailItem, Column, Title, Value, Button, WrapperSkippedStitches, WColumns, WColumn, ButtonIcon, InfoTitle, InfoSubTitle } from "./styles";

export const SkippedStitches = ({ valuePoints, children }) => (
    <WrapperSkippedStitches>
        <WColumns>
            <WColumn>
                <ButtonIcon type="button">
                    <Icon icon={["fas", "info"]} />
                </ButtonIcon>
            </WColumn>
            <WColumn>
                <InfoTitle> Faltam <PricePoints value={valuePoints} /> </InfoTitle>
                <InfoSubTitle> para completar a compra </InfoSubTitle>
            </WColumn>
        </WColumns>
        {children}
    </WrapperSkippedStitches>
)

export const OrderDetail = ({ title = "", valuePoints, valuePrice, iconAction, bgColor, valueColor }) => (
    <OrderDetailItem bgColor={bgColor}>
        <Column>
            <Title> {title} {iconAction && (
                <Button type="button" onClick={iconAction}>
                    <Icon icon={["far", "question-circle"]} />
                </Button>
            )} </Title>

        </Column>

        <Column>
            <Value valueColor={valueColor}>
                {valuePrice && (<Price value={valuePrice} />)}
                {valuePoints && (<PricePoints value={valuePoints} />)}
            </Value>
        </Column>
    </OrderDetailItem>
)

function OrderDetails({ children }) {
    return (
        <OrderDetailsList>
            {children}
        </OrderDetailsList>
    )
}

export default OrderDetails
