import React, { useRef, useEffect, useState } from 'react';

import { Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import SweetAlert from 'sweetalert';

import { 
  TextField, 
  useMediaQuery, 
  Grid,
  InputAdornment, 
  Select,
} from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import DeleteIcon from '@material-ui/icons/Delete';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import List from '@material-ui/core/List';
import ButtonMaterial from '@material-ui/core/Button';

import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/pro-light-svg-icons";

import Domain from '../../services/Domain';
import Categorizacao from '../../services/Categorizacao';
import ShowcaseService from '../../services/Showcase';
import AnuncioService from '../../services/Anuncios';

import ModalDialog from '../../components/ModalDialog';
import GridProduct from '../../components/GridProduct';
import ProductCard from '../../components/ProductCard';
import { isFriday } from 'date-fns';

const AdExternal = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const refModalDialogCategory = useRef();
  const showcase = new ShowcaseService();

  const [category, setCategory] = useState("");
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState({ nome: "" });
  const [conditions, setConditions] = useState([]);
  const [condition, setCondition] = useState({ nome: "" });
  const [listSelectedCategorization, setListSelectedCategorization] = useState([]);
  const [listActiveCategorization, setListActiveCategorization] = useState([]);
  const [allCategorizationLabel, setAllCategorizationLabel] = useState("");
  const [listCategorization, setListCategorization] = useState([]);
  const [searchAds, setSearchAds] = useState();
  const [selectedAds, setSelectedAds] = useState();
  const [limit, setLimit] = useState(8);
  const [searchKey, setSearchKey] = useState("");
  const [deliveryType, setDeliveryType] = useState();
  const [points, setPoints] = useState();
  const [price, setPrice] = useState();

  const { handleSubmit, register, errors, reset, setValue } = useForm();
  
  const openCategorizationModal = () => {
    setListActiveCategorization(listCategorization);
    setListSelectedCategorization([]);
    setAllCategorizationLabel("");

    refModalDialogCategory.current.openModal();
  };

  const loadInfo = () => {
    const categorization = new Categorizacao();
    const domain = new Domain();
  
    categorization
      .list()
      .then((response) => {
        if (response.status === 200) {
          setListCategorization(response.data.payload);
          setListActiveCategorization(response.data.payload);
        }
      })
      .catch((error) =>
        categorization.formatErrorMessage(error.response, 'CALICT'),
      );
  
    domain
      .listBrands()
      .then((response) => {
        if (response.status === 200) {
          setBrands(response.data.payload);
        }
      })
      .catch((error) =>
        domain.formatErrorMessage(error.response, 'CALIBDO'),
      );
  
    domain
      .listConditions()
      .then((response) => {
        if (response.status === 200) {
          setConditions(response.data.payload);
        }
      })
      .catch((error) =>
        domain.formatErrorMessage(error.response, 'CALICDO'),
      );
  };

  const onSelectCategorization = (categorizationlist) => {
    let selectedCategorizations = categorizationlist
      ? categorizationlist
      : listSelectedCategorization;

    let categorizationIdsSelected = selectedCategorizations.map(
      (selectedCategorization) => {
        return selectedCategorization.categorization._id;
      }
    );
  }

  const hasChindren = (categorization) => {
    if (categorization.categorias && categorization.categorias.length > 0) {
      return true;
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      return true;
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      return true;
    }

    return false;
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start'
  });

  const handleCondition = (e, condition) => {
    setCondition(condition);
  };

  const handleBrand = (e, brand) => {
    setBrand(brand);
  };

  const handleCategorizationBack = (oldCategorizationList, index) => {
    const listSelected = listSelectedCategorization.slice(0, index);
    setListSelectedCategorization(listSelected);

    setListActiveCategorization(oldCategorizationList);
  };

  const handleCategorization = (categorization) => {
    setCategory(categorization.nome);

    let listSelected;
    if (categorization.categorias && categorization.categorias.length > 0) {
      setListActiveCategorization(categorization.categorias);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      setListActiveCategorization(categorization.tipos_produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      setListActiveCategorization(categorization.produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else {
      listSelected = listSelectedCategorization;
      const lastIndex = listSelected.length > 0 ? listSelected.length - 1 : 0;

      if (listSelected[lastIndex] && listSelected[lastIndex].lastLevel) {
        listSelected[lastIndex] = {
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        };
        setListSelectedCategorization(listSelected);
      } else {
        listSelected.push({
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        });
        setListSelectedCategorization(listSelected);
      }
    }
  };

  const searchAnuncio = ({ limit, key, deliveryType, points, price }) => {
    const anuncioService = new AnuncioService();

    if(key) {
      if(key.indexOf('&') !== -1) key = key.replace(/&/g, 'ampersand');
    }

    anuncioService.findAdsByUserSearch({ 
      searchString: key, 
      limit: limit,
      brand: brand ? brand._id : null,
      category: listSelectedCategorization.length > 0 ? listSelectedCategorization[listSelectedCategorization.length - 1].categorization._id : null,
      condition: condition ? condition._id : null,
      deliveryType: deliveryType ? deliveryType : null,
      points: points ? points : null,
      price: price ? price : null,
      website: 'scamb' 
    }).then(response => {
      setSearchAds(response.data.payload);
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: anuncioService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }

  const loadMoreAds = () => {
    const newLimit = limit + 8;
    setLimit(newLimit);

    searchAnuncio({ 
      limit: newLimit, 
      key: searchKey,
      deliveryType: deliveryType ? deliveryType : null,
      points: points ? points : null,
      price: price ? price : null,
    });
  }

  const onSearch = (data) => {
    setSearchKey(data.filterby);
    setDeliveryType(data.frete_type);
    setPoints(`${data.pontosMinimos}-${data.pontosMaximos}`);
    setPrice(data.order);

    searchAnuncio({ 
      limit: limit, 
      key: data.filterby,
      deliveryType: data.frete_type,
      points: `${data.pontosMinimos}-${data.pontosMaximos}`,
      price: data.order
    });
  }

  const onCleanFilter = () => {
    reset({})
    setBrand([]);
    setCondition([]);
    setListSelectedCategorization([]);
    setLimit(8);
    setSearchKey("");
    setDeliveryType();
    setPoints();
    setPrice();

    searchAnuncio({ limit: 8, key: "" })
  }

  const updateSelectedAds = () => {
    const anuncioService = new AnuncioService();

    anuncioService.findAllExternalAds({ website: 'scamb' }).then(response => {
      setSelectedAds(response.data.payload);
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: anuncioService.formatErrorMessage(err.response),
        title: "",
      });
    });
  }

  const addAnuncios = (e, anuncio) => {
    e.preventDefault();

    if (selectedAds) {
      const existAnuncio = selectedAds.find((a) => a._id === anuncio._id);

      if (!existAnuncio) {
        const anuncioService = new AnuncioService();
        
        anuncioService.changeStatusAdExternal({ 
          anuncioId: anuncio._id, 
          status: true 
        }).then(response => {
            if(response.data.type === 'success') updateSelectedAds();
        }).catch(err => {
          Swal.fire({
            type: "info",
            html: anuncioService.formatErrorMessage(err.response),
            title: "",
          });
        })
      }
    }
  }

  const removeAnuncios = (e, anuncio) => {
    e.preventDefault();
    const anuncioService = new AnuncioService();
        
    anuncioService.changeStatusAdExternal({ 
      anuncioId: anuncio._id, 
      status: false 
    }).then(response => {
      if(response.data.type === 'success') updateSelectedAds();
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: anuncioService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }

  useEffect(() => {
    searchAnuncio({ limit: limit, key: "" });
    updateSelectedAds();
    loadInfo();
  }, []);

  useEffect(() => {
    setAllCategorizationLabel(
      listSelectedCategorization.reduce((label, selected, index) => {
        return (label += ` ${index > 0 ? ">" : ""} ${selected.categorization.nome
          }`);
      }, "")
    );
  }, [
    listSelectedCategorization.length,
    listSelectedCategorization[listSelectedCategorization.length - 1],
  ]);

  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">
          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title"> Anúncios Externos</div>
          </div>
          <div className="_modal-category GridProducts">
            <div className="select-product-container">
              <div className="search-products-container">
                <div className="_wrap-form" style={{ marginBottom: '20px', marginTop: '20px' }}>
                  <form className="_form" onSubmit={handleSubmit(onSearch)}>
                    <Grid container spacing={2}>
                      <Grid item md={4} xs={4}>
                        <TextField
                          id="outlined-add-title"
                          label="Filtrar por"
                          type="text"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="filterby"
                          fullWidth
                          inputRef={register({
                            required: false,
                          })}
                          inputProps={{
                            maxLength: 40,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item md={4} xs={4}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                            Ordenar por
                          </InputLabel>
                          <Select
                            native
                            label="Ordernar por"
                            inputRef={register({
                              required: false
                            })}
                            inputProps={{
                              name: "order",
                            }}
                          >
                            <option value=""></option>
                            <option value={-1}>Maior Preço</option>
                            <option value={1}>Menor Preço</option>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={4} xs={4}>
                        <TextField
                          id="outlined-category"
                          label="Categoria"
                          type="text"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="add_category"
                          value={allCategorizationLabel}
                          fullWidth
                          error={!!errors.add_category}
                          inputRef={register({
                            required: false,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onClick={() => openCategorizationModal()}
                          inputProps={{
                            endadornment: (
                              <InputAdornment position="end">
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                ></FontAwesomeIcon>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item md={4} xs={4}>
                        <Autocomplete
                          filterOptions={filterOptions}
                          id="outlined-brand"
                          options={brands}
                          includeInputInList
                          freeSolo
                          getOptionLabel={(option) => option.nome}
                          getOptionSelected={(option) => option._id}
                          fullWidth
                          value={brand}
                          onChange={(e, value) => handleBrand(e, value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Marca"
                              name="brand"
                              variant="outlined"
                              size={matches ? "small" : "medium"}
                              error={!!errors.brand}
                              inputRef={register({
                                required: false,
                              })}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item md={4} xs={4}>
                        <Autocomplete
                          id="outlined-condition"
                          autoComplete={true}
                          options={conditions}
                          getOptionLabel={(option) => option.nome}
                          getOptionSelected={(option) => option._id}
                          size="small"
                          fullWidth
                          value={condition}
                          onChange={(e, value) => handleCondition(e, value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Condição"
                              name="add_condition"
                              variant="outlined"
                              size={matches ? "small" : "small"}
                              error={!!errors.add_condition}
                              inputRef={register({
                                required: false,
                              })}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item md={4} xs={4}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                            Tipo de Frete
                          </InputLabel>
                          <Select
                            native
                            label="Tipo de Frete"
                            inputRef={register({
                              required: false
                            })}
                            inputProps={{
                              name: "frete_type",
                            }}
                          >
                            <option value=""></option>
                            <option value={"operador_logistico"}>Correios</option>
                            <option value={"entrega_maos"}>Entrega em mãos</option>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={4} xs={4}>
                        <TextField
                          id="outlined-login"
                          label="Pontos: Mínimo"
                          type="number"
                          variant="outlined"
                          size="small"
                          name="pontosMinimos"
                          defaultValue={0}
                          fullWidth
                          inputRef={register({
                            required: true,
                          })}
                        />
                      </Grid>

                      <Grid item md={4} xs={4}>
                        <TextField
                          id="outlined-login"
                          label="Pontos: Máximo"
                          type="number"
                          variant="outlined"
                          size="small"
                          name="pontosMaximos"
                          defaultValue={9999}
                          fullWidth
                          inputRef={register({
                            required: true,
                          })}
                        />
                      </Grid>

                      <Grid item md={2} xs={2}>
                        <Button type='submit' style={{marginTop: '3px'}}>
                          Aplicar
                        </Button>
                      </Grid>

                      <Grid item md={2} xs={2}>
                        <Button color="secondary" style={{marginTop: '3px'}} onClick={() => onCleanFilter()}>
                          Limpar
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </div>
                <Grid container spacing={2}>
                  {searchAds && searchAds.map((ad, index) => (
                    <Grid item lg={3} md={4} sm={4} xs={6} key={index}>
                      <GridProduct
                        link={ad.url_encurtada}
                        image={
                          showcase.loadImage(ad.fotos[0])
                            ? showcase.loadImage(ad.fotos[0])
                            : ""
                        }
                        weight={ad.tamanho_exibicao?.nome}
                        title={ad.titulo}
                        discount={
                          ad.pontos_de
                            ? ad.pontos_de.$numberDecimal
                            : ""
                        }
                        price={
                          ad.pontos_por
                            ? ad.pontos_por.$numberDecimal
                            : ""
                        }
                        type={ad.tipo_anuncio}
                        cashback={ad.cashback}
                        cashDiscount={ad.valor_de}
                        cashPrice={ad.valor_por}
                        website='scamb'
                        onClick={(e) => addAnuncios(e, ad)}
                      />
                    </Grid>  
                  ))}

                </Grid>

                <div className="_wrap-load-more">
                  {limit <= searchAds?.length ? (<button type="submit" onClick={() => loadMoreAds()} className="btn btn-red">Carregar mais</button>) : null}
                </div>
              </div>
              <div className="alredy-select-container">
                <div className="_title-select-product">Produtos já selecionados</div>

                <List className="alredy-select-list">
                  {selectedAds && selectedAds.map((ad) => (
                    <ProductCard
                      key={ad._id}
                      image={showcase.loadImage(ad.fotos[0])}
                      title={ad.titulo}
                      url={ad.url_encurtada}
                    >
                    <ButtonMaterial
                      endIcon={<DeleteIcon color="secondary"/>}
                      onClick={(e) => { removeAnuncios(e, ad) }}
                    />
                  </ProductCard>
                  ))}
                </List>
              </div>          
            </div>
          </div>
        </div>
      </main>

      <ModalDialog
          customMethod={onSelectCategorization}
          dialogTitle="Categorias"
          btnTitle="Aplicar"
          className="_modal-category"
          maxWidth="xs"
          ref={refModalDialogCategory}
        >
          <span className="_content">
            <a href="#!" className="tag-selected-radio">
              {listSelectedCategorization.map(
                (selectedCategorization, index) => {
                  if (index === 0) {
                    return (
                      <div
                        onClick={() =>
                          handleCategorizationBack(
                            selectedCategorization.oldCategorizationList,
                            index
                          )
                        }
                        key={index}
                        className="_title-selected _only"
                      >
                        <FontAwesomeIcon
                          className="icon"
                          icon={faChevronLeft}
                        ></FontAwesomeIcon>
                        <span class="_text">

                          {selectedCategorization.categorization.nome}
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        onClick={() =>
                          handleCategorizationBack(
                            selectedCategorization.oldCategorizationList,
                            index
                          )
                        }
                        key={index}
                        className="_title-selected _only _multiple"
                      >
                        <span class="_text">

                          {selectedCategorization.categorization.nome}
                        </span>
                      </div>
                    );
                  }
                }
              )}
            </a>

            <ul id="drilldown">
              {listActiveCategorization.map((categorization, index) => (
                <li key={index}>
                  <label>
                    <input
                      type="radio"
                      name="select_category_lvl_1"
                      checked={
                        category === categorization.nome ? "checked" : ""
                      }
                      onChange={() => handleCategorization(categorization)}
                    />
                    <span className="_name">{categorization.nome}</span>
                    {hasChindren(categorization) ? (
                      <div className="icon">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                        ></FontAwesomeIcon>
                      </div>
                    ) : (
                        <></>
                      )}
                  </label>
                </li>
              ))}
            </ul>
          </span>
        </ModalDialog>
      </>
  )
}

export default AdExternal;