//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #4 - Import * React Router Dom
//===============================================================================================================
import { Link } from "react-router-dom";

//===============================================================================================================
// #5 - Import * UI Components
//===============================================================================================================
// import Button from "../../../components/design/form/button";
import Button from "../../../../../components/Form/button";

//===============================================================================================================
// #6 - Import * Font Awesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faExclamation } from "@fortawesome/pro-solid-svg-icons";

//===============================================================================================================
// #7 - Import * Styled Components
//===============================================================================================================
import { Wrapper, Main, Content, IconChecked, Title, Text } from "./styles";

export const HText = ({ marginTop, children }) => <Text marginTop={marginTop}>{children}</Text>

function CheckoutThanks({ title, icon, iconType, children }) {

    return (
        <Wrapper>
            <Main>
                <Content>
                    <IconChecked iconType={iconType}>
                        {icon}
                    </IconChecked>

                    <Title>
                        {title}<br />
                    </Title>

                    {children}

                </Content>
            </Main>
        </Wrapper>
    )
}

export default CheckoutThanks;