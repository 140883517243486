import React, { useState, useEffect, useCallback, useRef } from 'react';
import Swal from 'sweetalert2';
import { Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';

import {
  TextField,
  useMediaQuery,
  Grid,
  InputAdornment,
  Select,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ButtonMaterial from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Pagination from '@material-ui/lab/Pagination';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import SubjectIcon from '@material-ui/icons/Subject';
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CropFreeIcon from '@material-ui/icons/CropFree';
import DehazeIcon from '@material-ui/icons/Dehaze';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/pro-light-svg-icons";

import Report from './../../services/Report';
import Download from './../../services/Dowload';
import Domain from '../../services/Domain';
import Categorizacao from '../../services/Categorizacao';
import AnuncioService from '../../services/Anuncios';

import ModalDialog from '../../components/ModalDialog';
import ProductCard from '../../components/ProductCard';

import config from '../../configs/config.json';


export default function Curatorship() {

  const [registros, setRegistros] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [pagination, setPagination] = useState();
  const [filter, setFilter] = useState();
  const [selectedScamberEnd, setSelectedScamberEnd] = useState();
  const [selectedScamberTel, setSelectedScamberTel] = useState();
  const [category, setCategory] = useState("");
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState({ nome: "" });
  const [conditions, setConditions] = useState([]);
  const [condition, setCondition] = useState({ nome: "" });
  const [listSelectedCategorization, setListSelectedCategorization] = useState([]);
  const [listActiveCategorization, setListActiveCategorization] = useState([]);
  const [allCategorizationLabel, setAllCategorizationLabel] = useState("");
  const [listCategorization, setListCategorization] = useState([]);
  const [searchAds, setSearchAds] = useState();
  const [limit, setLimit] = useState(8);
  const [searchKey, setSearchKey] = useState("");
  const [deliveryType, setDeliveryType] = useState();
  const [statusAd, setStatusAd] = useState();
  const [storeUrl, setStoreUrl] = useState();
  const [points, setPoints] = useState();
  const [price, setPrice] = useState();
  const [selectedAd, setSelectedAd] = useState();


  const matches = useMediaQuery("(min-width:600px)");
  const limitofData = 20;
  const refModalDetail = useRef();
  const refModalDialogCategory = useRef();
  const refModalDialogSelectProduct = useRef();
  const refModalDialogChangeAdStatus = useRef();
  const report = new Report();

  const { reset } = useForm();
  const { handleSubmit: handleSubmitSearchFilter, register: registerSearchFilter, reset: resetSearchFilter, errors} = useForm();
  const { handleSubmit: handleSubmitChangeAdStatus, register: registerChangeAdStatus} = useForm();

  useEffect(() => {
    if (!Boolean(localStorage.getItem('token'))) window.location.href = "/"

    report.contasDetalhe({ limit: limitofData, justStore: true })
      .then((response) => {
        if (response.status !== 200) return Swal.fire({ icon: 'info', 'text': 'Não foi possível carregar a tabela.' });
        setRegistros(response.data.payload.result);
        setPagination(response.data.payload.pagination);
      })
      .catch((error) => {
        Swal.fire({ icon: 'info', 'text': 'Não foi possível carregar a tabela.' });
      })

  }, []);

  let submitFilter = (e) => {
    e.preventDefault();
    let requestObject = {}

    Swal.fire('Aguarde...');
    Swal.showLoading();
    if ((!startDate && !endDate) && !filter) return Swal.fire({ icon: 'info', title: '', text: 'Informe os filtros novamente' })

    if(startDate && endDate){
      let startDateSplited = startDate;
      let endDateSplited = endDate;

      startDateSplited = startDateSplited.split('-')
      endDateSplited = endDateSplited.split('-')

      requestObject = {
        "startDate": {
          "day": startDateSplited[2],
          "month": startDateSplited[1],
          "year": startDateSplited[0]
        },
        "endDate": {
          "day": endDateSplited[2],
          "month": endDateSplited[1],
          "year": endDateSplited[0]
        },
        "limit": limitofData,
        "filter": filter,
        "justStore": true
      }
    } else {
      requestObject = {
        "limit": limitofData,
        "filter": filter,
        "justStore": true
      }
    }
    
    report.contasDetalhe(requestObject).then((response) => {
      if (response.status !== 200) return Swal.fire({ icon: 'info', 'text': 'Token inválido ou não informado.' });
      setRegistros(response.data.payload.result);
      setPagination(response.data.payload.pagination);
      Swal.close();
    }).catch((error) => {
      Swal.fire({ icon: 'info', title: 'Não foi possível carregar a tabela.', text: 'Confira se as informações de filtro estão corretas' });
     })
  }

  const getHost = () => {

    if (config.nodejs.server.indexOf('localhost') !== -1) {
      return `https://d1.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('/adm-dev') !== -1 && config.nodejs.server.indexOf('/adm-dev2') === -1 ) {
      return `https://d1.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('/adm-dev2') !== -1) {
        return `https://d2.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('apihml') !== -1 && config.nodejs.server.indexOf('apihml2') === -1 ) {
      return `https://h1.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('apihml2') !== -1) {
        return `https://h2.scamb.com.vc`
    } else {
      return `https://scamb.com.vc`
    }

  }

  const handlePageChange = useCallback((page) => {
    const report = new Report();

    report.contasDetalhe({ page: page, limit: limitofData, justStore: true })
      .then((response) => {
        if (response.status !== 200) return Swal.fire({ icon: 'info', 'text': 'Não foi possível carregar a tabela.' });
        setRegistros(response.data.payload.result);

      })
      .catch((error) => {
        Swal.fire({ icon: 'info', 'text': 'Não foi possível carregar a tabela.' });
      });

  }, []);


  // const getDowload = () => {
  //   const dowload = new Download();
  //   let requestObject = {}

  //   if (startDate && endDate) {
  //     let startDateSplited = startDate;
  //     let endDateSplited = endDate;

  //     startDateSplited = startDateSplited.split('-')
  //     endDateSplited = endDateSplited.split('-')

  //     requestObject = {
  //       "startDate": {
  //         "day": startDateSplited[2],
  //         "month": startDateSplited[1],
  //         "year": startDateSplited[0]
  //       },
  //       "endDate": {
  //         "day": endDateSplited[2],
  //         "month": endDateSplited[1],
  //         "year": endDateSplited[0]
  //       },
  //       "filter": filter
  //     }
  //   }
  //   else {
  //     requestObject = {
  //       "limit": limitofData,
  //       "filter": filter
  //     }
  //   }

  //   Swal.fire('Gerando arquivo ... Aguarde');
  //   Swal.showLoading();
  //   dowload.getAccountFile(requestObject).then((response) => {
  //     var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset=utf-8;' }));
  //     var fileLink = document.createElement('a');
  //     const newDate  = new Date();

  //     fileLink.href = fileURL;
  //     fileLink.setAttribute('download', `contas_detalhe_${newDate.getDate()}_${newDate.getMonth() + 1}_${newDate.getFullYear()}.csv`);
  //     document.body.appendChild(fileLink);

  //     Swal.close();
  //     fileLink.click();
  //   }).catch(error => {
  //     Swal.close();
  //     Swal.fire({
  //       type: "info",
  //       html: dowload.formatErrorMessage(error.response),
  //       title: "",
  //     });
  //   });
  // };

  const loadContas = () => {
    const report = new Report();

    Swal.fire('Carregando lista ... Aguarde');
    Swal.showLoading();
    report.contasDetalhe({ limit: limitofData, justStore: true}).then((response) => {
      setRegistros(response.data.payload.result);
      setPagination(response.data.payload.pagination);
      Swal.close();
    }).catch((error) => {
      Swal.fire({
        type: "info",
        text: report.formatErrorMessage(error.response),
        title: "",
      });
    })
  }
  
  const handleCategorizationBack = (oldCategorizationList, index) => {
    const listSelected = listSelectedCategorization.slice(0, index);
    setListSelectedCategorization(listSelected);

    setListActiveCategorization(oldCategorizationList);
  };

  const handleCategorization = (categorization) => {
    setCategory(categorization.nome);

    let listSelected;
    if (categorization.categorias && categorization.categorias.length > 0) {
      setListActiveCategorization(categorization.categorias);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      setListActiveCategorization(categorization.tipos_produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      setListActiveCategorization(categorization.produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else {
      listSelected = listSelectedCategorization;
      const lastIndex = listSelected.length > 0 ? listSelected.length - 1 : 0;

      if (listSelected[lastIndex] && listSelected[lastIndex].lastLevel) {
        listSelected[lastIndex] = {
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        };
        setListSelectedCategorization(listSelected);
      } else {
        listSelected.push({
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        });
        setListSelectedCategorization(listSelected);
      }
    }
  };

  const onSelectCategorization = (categorizationlist) => {
    let selectedCategorizations = categorizationlist
      ? categorizationlist
      : listSelectedCategorization;

    let categorizationIdsSelected = selectedCategorizations.map(
      (selectedCategorization) => {
        return selectedCategorization.categorization._id;
      }
    );
  }

  const hasChindren = (categorization) => {
    if (categorization.categorias && categorization.categorias.length > 0) {
      return true;
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      return true;
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      return true;
    }

    return false;
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start'
  });

  const handleCondition = (e, condition) => {
    setCondition(condition);
  };

  const handleBrand = (e, brand) => {
    setBrand(brand);
  };

  const searchAnuncio = ({ limit, key, deliveryType, points, price, storeUrl, statusAd }) => {
    const anuncioService = new AnuncioService();

    if (key) {
      if (key.indexOf('&') !== -1) key = key.replace(/&/g, 'ampersand');
    }

    anuncioService.findAdsByUserSearch({
      searchString: key,
      limit: limit,
      brand: brand ? brand._id : null,
      category: listSelectedCategorization.length > 0 ? listSelectedCategorization[listSelectedCategorization.length - 1].categorization._id : null,
      condition: condition ? condition._id : null,
      deliveryType: deliveryType ? deliveryType : null,
      points: points ? points : null,
      price: price ? price : null,
      website: 'scamb',
      storeUrl: storeUrl ? storeUrl : null,
      statusAd: statusAd ? statusAd : null
    }).then(response => {
      setSearchAds(response.data.payload.ad);
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: anuncioService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }

  const onSearch = (data) => {
    setSearchKey(data.filterby);
    setDeliveryType(data.frete_type);
    setPoints(`${data.pontosMinimos}-${data.pontosMaximos}`);
    setPrice(data.order);
    setStatusAd(data.status_ad)

    searchAnuncio({
      limit: limit,
      key: data.filterby,
      deliveryType: data.frete_type,
      points: `${data.pontosMinimos}-${data.pontosMaximos}`,
      price: data.order,
      storeUrl: storeUrl,
      statusAd: data.status_ad
    });
  }

  const loadMoreAds = () => {
    const newLimit = limit + 8;
    setLimit(newLimit);

    searchAnuncio({
      limit: newLimit,
      key: searchKey,
      deliveryType: deliveryType ? deliveryType : null,
      points: points ? points : null,
      price: price ? price : null,
      storeUrl: storeUrl,
      statusAd: statusAd ? statusAd : null
    });
  }

  const clearFilter = (e) => {
    e.preventDefault();

    setStartDate('');
    setEndDate('');
    
    loadContas();
  }

  const onCleanFilter = () => {
    resetSearchFilter({})
    setBrand([]);
    setCondition([]);
    setListSelectedCategorization([]);
    setLimit(8);
    setSearchKey("");
    setDeliveryType();
    setStatusAd();
    setPoints();
    setPrice();

    searchAnuncio({ limit: 8, key: "", storeUrl: storeUrl })
  }

  const openSelectProductModal = ({ scamber }) => {
    setBrand([]);
    setCondition([]);
    setListSelectedCategorization([]);
    resetSearchFilter({});
    setLimit(8);
    setSearchKey("");
    setDeliveryType();
    setStatusAd();
    setPoints();
    setPrice();
    setStoreUrl(scamber.minha_loja?.url);

    searchAnuncio({ limit: limit, key: "", storeUrl: scamber.minha_loja?.url })
    refModalDialogSelectProduct.current.openModal();
  }

  const openCategorizationModal = () => {
    setListActiveCategorization(listCategorization);
    setListSelectedCategorization([]);
    setAllCategorizationLabel("");

    refModalDialogCategory.current.openModal();
  }; 

  const loadInfo = () => {
    const categorization = new Categorizacao();
    const domain = new Domain();

    categorization.list().then((response) => {
      if (response.status === 200) {
        setListCategorization(response.data.payload);
        setListActiveCategorization(response.data.payload);
      }
    }).catch((error) =>
      categorization.formatErrorMessage(error.response, 'CALICT'),
    );

    domain.listBrands().then((response) => {
      if (response.status === 200) {
        setBrands(response.data.payload);
      }
    }).catch((error) =>
      domain.formatErrorMessage(error.response, 'CALIBDO'),
    );

    domain.listConditions().then((response) => {
      if (response.status === 200) {
        setConditions(response.data.payload);
      }
    }).catch((error) =>
        domain.formatErrorMessage(error.response, 'CALICDO'),
    );
  };

  const generateQrCode = ({ adId }) => {
    const anuncioService = new AnuncioService();

    Swal.fire('Gerando QR Code ... Aguarde');
    anuncioService.generateQrCodeImage({ adId: adId }).then((response) => {
      if(response.data.type === 'success') {
        searchAnuncio({
          limit: limit ,
          key: searchKey,
          deliveryType: deliveryType ? deliveryType : null,
          points: points ? points : null,
          price: price ? price : null,
          storeUrl: storeUrl,
          statusAd: statusAd ? statusAd : null
        });

        Swal.fire({
          title: "",
          text: "QR Code gerado com sucesso",
          type: "success",
        });
      }
    }).catch(err => {
      Swal.fire({
        type: "info",
        text: anuncioService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }

  const exportQrCode = ({ adId }) => {
    const anuncioService = new AnuncioService();

    Swal.fire('Baixando QR Code ... Aguarde');
    anuncioService.exportQrCode({ adId: adId }).then(async (response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'image/png' }));
      var fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `qr-code${adId}.png`);
      document.body.appendChild(fileLink);

      Swal.close();
      fileLink.click();

      Swal.close();
    }).catch(err => {
      Swal.fire({
        type: "info",
        text: anuncioService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }

  const changeAdStatus = (data) => {
    const anuncioService = new AnuncioService();

    Swal.fire('Alterando status do anúncio... Aguarde');
    anuncioService.changeAdStatus({ adId: selectedAd._id, status: data.status }).then((response) => {
      if(response.data.type === 'success') {
        searchAnuncio({
          limit: limit ,
          key: searchKey,
          deliveryType: deliveryType ? deliveryType : null,
          points: points ? points : null,
          price: price ? price : null,
          storeUrl: storeUrl,
          statusAd: statusAd ? statusAd : null
        });

        Swal.fire({
          title: "",
          text: response.data.message,
          type: "success",
        });
      }
    }).catch(err => {
      Swal.fire({
        type: "info",
        text: anuncioService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }

  useEffect(() => {
    loadInfo();
  }, []);

  return (
    <>
      <main role="main" id="view_account-details">
        <div className="_fluid-container">

          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title">
              Contas detalhe
          </div>
          </div>

          <div className="_wrap-filter-activity">
            <h2 className="title">Filtrar por data</h2>
            <div className="_filter-activity">
              <form className="_form" onSubmit={(e) => submitFilter(e)}>
                <div className="_wrap-dates">
                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Data inicial</label>
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="00/00/0000"
                        value={startDate}
                        aria-describedby="cc-name"
                        onChange={(e) => setStartDate(e.target.value)}
                      />

                    </div>
                  </div>

                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Data final</label>
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="00/00/0000"
                        value={endDate}
                        aria-describedby="cc-name"
                        onChange={(e) => setEndDate(e.target.value)}
                      />

                    </div>
                  </div>

                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Filtrar</label>
                    <div className="input-group">
                      <TextField
                        type="text"
                        className="form-control"
                        name="filterEmail"
                        aria-describedby="cc-name"
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    </div>
                  </div>

                </div>
                <div className="_wrap-input-group _submit">
                  <button type="submit" className="submit-filter">
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </form>
              <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div>
              {/* <div className="excel-div">
                <button onClick={getDowload} className="excel-button">Exportar .csv</button>
              </div> */}
            </div>
          </div>


          <div className="">
            <form style={{ display: 'none' }} onSubmit={(e) => submitFilter(e)}>
              <div className="form-group row">

                <label htmlFor="example-date-input" className="col-2 col-form-label">Data inicial</label>
                <div className="col-10">
                  <input onChange={(e) => setStartDate(e.target.value)} className="form-control" type="date" id="example-date-input" />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="example-date-input" className="col-2 col-form-label">Data final</label>
                <div className="col-10">
                  <input onChange={(e) => setEndDate(e.target.value)} className="form-control" type="date" id="example-date-input" />
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block">Filtrar</button>
            </form>
          </div>
          <div className="_wrap-table">
            <h2 className="title">Novas contas</h2>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope={"col"}>NOME</th>
                  <th scope={"col"}>EMAIL</th>
                  <th scope={"col"}>CPF</th>
                  <th scope={"col"}>NOME LOJA</th>
                  <th scope={"col"}>URL DA LOJA</th>
                  <th scope={"col"}>DETALHES DA CONTA</th>
                  <th scope={"col"}>PRODUTOS</th>
                </tr>
              </thead>
              <tbody>
                {registros.length ? registros.map((registro) => {
                  return (
                    <tr key={registro._id}>
                      <td>{registro.nome}</td>
                      <td>{registro.email}</td>
                      <td>{registro.cpf}</td>
                      <td>{registro?.minha_loja?.titulo}</td>
                      {registro?.minha_loja?.url ?
                        <td><a target='_blank' href={`${getHost()}/loja/${registro?.minha_loja?.url}`}>{`${getHost()}/loja/${registro?.minha_loja?.url}`}</a></td>
                        : <td> </td>}
                      <td>
                        <Button
                          className={"btn-step btn-edit-table"}
                          color="primary"
                          autoFocus
                          style={{ height: matches ? 45 : 50 }}
                          onClick={() => { setSelectedScamberEnd(registro.endereco); setSelectedScamberTel(registro.telefone); refModalDetail.current.openModal() }}
                        >
                          Ver Detalhes
                        </Button>
                      </td>
                      <td>
                        <Button
                          className={"btn-step btn-edit-table"}
                          color="primary"
                          autoFocus
                          style={{ height: matches ? 45 : 50 }}
                          onClick={() => openSelectProductModal({scamber: registro})}
                        >
                          Ver Produtos
                        </Button>
                      </td>
                    </tr>
                  )
                }) :
                  <>
                  </>
                }
              </tbody>

            </table>
          </div>
        </div>
        <div class="pagination-style">
          {pagination && pagination.totalPages ? (
            <Pagination
              shape="rounded"
              count={pagination.totalPages}
              variant="outlined"
              color="primary"
              hidePrevButton
              hideNextButton
              size="large"
              onChange={(_, p) => handlePageChange(p)}
            />
          ) : undefined}
        </div>
      </main>

      <ModalDialog
        ref={refModalDetail}
        dialogTitle="Detalhes"
        onCloseModal={
          <button
            type="button"
            className="btn"
            onClick={() => {
              reset({});
              refModalDetail.current.closeModal()
            }}>
            {matches ? <CloseIcon /> : <ChevronLeftIcon />}
          </button>
        }>
        <div className="_wrap-form">

        {selectedScamberTel && selectedScamberTel.length > 0 ? selectedScamberTel.map(telefone => (
              <div key={telefone._id}>
                <p style={{ paddingBottom: '15px', paddingTop: '15px', marginBottom: 0 }}><strong>Telefone</strong></p>
                <p style={{ marginBottom: 0 }}><strong>Tipo:</strong> {telefone.tipo}</p>
                <p style={{ marginBottom: 0 }}><strong>DDD:</strong> {telefone.ddd}</p>
                <p style={{ marginBottom: 0 }}><strong>Número:</strong> {telefone.numero}</p>
              </div>
            )) : (
              <div>
                <p><strong>Não possui telefone cadastrado</strong></p>
              </div>
            )}

          {selectedScamberEnd && selectedScamberEnd.length > 0 ? selectedScamberEnd.map(endereco => (
            endereco.principal ? (
              <div key={endereco._id}>
                <p style={{ paddingBottom: '15px', paddingTop: '15px', marginBottom: 0 }}><strong>Endereço</strong></p>
                {endereco.apelido ? <p style={{ marginBottom: 0 }}><strong>Apelido:</strong> {endereco.apelido}</p> : undefined}
                <p style={{ marginBottom: 0 }}><strong>CEP:</strong> {endereco.cep}</p>
                <p style={{ marginBottom: 0 }}><strong>Logradouro:</strong> {endereco.logradouro}</p>
                <p style={{ marginBottom: 0 }}><strong>Número:</strong> {endereco.numero}</p>
                <p style={{ marginBottom: 0 }}><strong>Bairro:</strong> {endereco.bairro}</p>
                <p style={{ marginBottom: 0 }}><strong>Cidade:</strong> {endereco.cidade}</p>
                <p style={{ marginBottom: 0 }}><strong>Estado:</strong> {endereco.estado}</p>
                {endereco.complemento ? <p><strong>Complemento:</strong> {endereco.complemento}</p> : undefined}
              </div>
            ) : (
                <div key={endereco._id}>
                  <p style={{ paddingBottom: '15px', paddingTop: '15px', marginBottom: 0 }}><strong>Endereço</strong></p>
                  {endereco.apelido ? <p style={{ marginBottom: 0 }}><strong>Apelido:</strong> {endereco.apelido}</p> : undefined}
                  <p style={{ marginBottom: 0 }}><strong>CEP:</strong> {endereco.cep}</p>
                  <p style={{ marginBottom: 0 }}><strong>Logradouro:</strong> {endereco.logradouro}</p>
                  <p style={{ marginBottom: 0 }}><strong>Número:</strong> {endereco.numero}</p>
                  <p style={{ marginBottom: 0 }}><strong>Bairro:</strong> {endereco.bairro}</p>
                  <p style={{ marginBottom: 0 }}><strong>Cidade:</strong> {endereco.cidade}</p>
                  <p style={{ marginBottom: 0 }}><strong>Estado:</strong> {endereco.estado}</p>
                  {endereco.complemento ? <p><strong>Complemento:</strong> {endereco.complemento}</p> : undefined}
                </div>
              ))) : (
              <div>
                <p><strong>Não possui endereço principal cadastrado</strong></p>
              </div>
            )}
        </div>
      </ModalDialog>

      <ModalDialog
        dialogTitle="Seleção de Produtos"
        className="_modal-category GridProducts"
        maxWidth="lg"
        btnTitle="Aplicar"
        ref={refModalDialogSelectProduct}
      >
        <div className="select-product-container">
          <div className="search-products-container">
            <div className="_wrap-form" style={{ marginBottom: '20px', marginTop: '20px' }}>
              <form className="_form" onSubmit={handleSubmitSearchFilter(onSearch)}>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-add-title"
                      label="Filtrar por"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="filterby"
                      fullWidth
                      inputRef={registerSearchFilter({
                        required: false,
                      })}
                      inputProps={{
                        maxLength: 40,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                        Ordenar por
                      </InputLabel>
                      <Select
                        native
                        label="Ordernar por"
                        inputRef={registerSearchFilter({
                          required: false
                        })}
                        inputProps={{
                          name: "order",
                        }}
                      >
                        <option value=""></option>
                        <option value={-1}>Maior Preço</option>
                        <option value={1}>Menor Preço</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-category"
                      label="Categoria"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="add_category"
                      value={allCategorizationLabel}
                      fullWidth
                      error={!!errors.add_category}
                      inputRef={registerSearchFilter({
                        required: false,
                      })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={() => openCategorizationModal()}
                      inputProps={{
                        endadornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon
                              icon={faChevronRight}
                            ></FontAwesomeIcon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <Autocomplete
                      filterOptions={filterOptions}
                      id="outlined-brand"
                      options={brands}
                      includeInputInList
                      freeSolo
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      fullWidth
                      value={brand}
                      onChange={(e, value) => handleBrand(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Marca"
                          name="brand"
                          variant="outlined"
                          size={matches ? "small" : "medium"}
                          error={!!errors.brand}
                          inputRef={registerSearchFilter({
                            required: false,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <Autocomplete
                      id="outlined-condition"
                      autoComplete={true}
                      options={conditions}
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      size="small"
                      fullWidth
                      value={condition}
                      onChange={(e, value) => handleCondition(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Condição"
                          name="add_condition"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          error={!!errors.add_condition}
                          inputRef={registerSearchFilter({
                            required: false,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                        Tipo de Frete
                      </InputLabel>
                      <Select
                        native
                        label="Tipo de Frete"
                        inputRef={registerSearchFilter({
                          required: false
                        })}
                        inputProps={{
                          name: "frete_type",
                        }}
                      >
                        <option value=""></option>
                        <option value={"operador_logistico"}>Correios</option>
                        <option value={"entrega_maos"}>Entrega em mãos</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <TextField
                      id="outlined-login"
                      label="Pontos: Mínimo"
                      type="number"
                      variant="outlined"
                      size="small"
                      name="pontosMinimos"
                      defaultValue={0}
                      fullWidth
                      inputRef={registerSearchFilter({
                        required: true,
                      })}
                    />
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <TextField
                      id="outlined-login"
                      label="Pontos: Máximo"
                      type="number"
                      variant="outlined"
                      size="small"
                      name="pontosMaximos"
                      defaultValue={9999}
                      fullWidth
                      inputRef={registerSearchFilter({
                        required: true,
                      })}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                        Status
                      </InputLabel>
                      <Select
                        native
                        label="Status"
                        inputRef={registerSearchFilter({
                          required: false
                        })}
                        inputProps={{
                          name: "status_ad",
                        }}
                      >
                        <option value=""></option>
                        <option value={"ativo"}>Ativo</option>
                        <option value={"desativado"}>Desativado</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <Button type='submit' style={{ marginTop: '3px' }}>
                      Aplicar
                    </Button>
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <Button color="secondary" style={{ marginTop: '3px' }} onClick={() => onCleanFilter()}>
                      Limpar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>

            <Grid container spacing={2}>
              {searchAds && searchAds.map((ad, index) => (
                <Grid item xs={12} key={index}>
                  <ProductCard
                    key={ad._id}
                    image={report.loadImage(ad.fotos[0])}
                    title={ad.titulo}
                    url={ad.url_encurtada}
                  >
                    {ad.qr_code ? (
                      <ButtonMaterial
                        endIcon={<CropFreeIcon />}
                        variant="contained"
                        onClick={() => exportQrCode({adId: ad._id})}
                        style={{marginRight: '10px'}}
                      > 
                        Baixar QR Code
                      </ButtonMaterial>
                    ) : (
                      <ButtonMaterial
                        endIcon={<CropFreeIcon />}
                        variant="contained"
                        onClick={() => generateQrCode({adId: ad._id})}
                        style={{marginRight: '10px'}}
                      > 
                        Gerar QR Code
                      </ButtonMaterial>
                    )}
                    
                    <ButtonMaterial
                      onClick={() => { setSelectedAd(ad); refModalDialogChangeAdStatus.current.openModal()}}
                      variant="contained"
                      endIcon={<DehazeIcon />}
                    >
                      Curadoria
                    </ButtonMaterial>
                  </ProductCard>
                </Grid>
              ))}

            </Grid>

            <div className="_wrap-load-more">
              {limit <= searchAds?.length ? (<button type="submit" onClick={() => loadMoreAds()} className="btn btn-red">Carregar mais</button>) : null}
            </div>
          </div>
        </div>
      </ModalDialog>

      <ModalDialog
        customMethod={onSelectCategorization}
        dialogTitle="Categorias"
        btnTitle="Aplicar"
        className="_modal-category"
        maxWidth="xs"
        ref={refModalDialogCategory}
      >
        <span className="_content">
          <a href="#!" className="tag-selected-radio">
            {listSelectedCategorization.map(
              (selectedCategorization, index) => {
                if (index === 0) {
                  return (
                    <div
                      onClick={() =>
                        handleCategorizationBack(
                          selectedCategorization.oldCategorizationList,
                          index
                        )
                      }
                      key={index}
                      className="_title-selected _only"
                    >
                      <FontAwesomeIcon
                        className="icon"
                        icon={faChevronLeft}
                      ></FontAwesomeIcon>
                      <span class="_text">

                        {selectedCategorization.categorization.nome}
                      </span>
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={() =>
                        handleCategorizationBack(
                          selectedCategorization.oldCategorizationList,
                          index
                        )
                      }
                      key={index}
                      className="_title-selected _only _multiple"
                    >
                      <span class="_text">

                        {selectedCategorization.categorization.nome}
                      </span>
                    </div>
                  );
                }
              }
            )}
          </a>

          <ul id="drilldown">
            {listActiveCategorization.map((categorization, index) => (
              <li key={index}>
                <label>
                  <input
                    type="radio"
                    name="select_category_lvl_1"
                    checked={
                      category === categorization.nome ? "checked" : ""
                    }
                    onChange={() => handleCategorization(categorization)}
                  />
                  <span className="_name">{categorization.nome}</span>
                  {hasChindren(categorization) ? (
                    <div className="icon">
                      <FontAwesomeIcon
                        icon={faChevronRight}
                      ></FontAwesomeIcon>
                    </div>
                  ) : (
                    <></>
                  )}
                </label>
              </li>
            ))}
          </ul>
        </span>
      </ModalDialog>

      <ModalDialog ref={refModalDialogChangeAdStatus} dialogTitle="Curadoria" onCloseModal={<button type="button" className="btn" onClick={() => { reset({}); refModalDialogChangeAdStatus.current.closeModal() }}> {matches ? <CloseIcon /> : <ChevronLeftIcon />} </button>}>
        <div className="_wrap-form">
          <form className="_form" onSubmit={handleSubmitChangeAdStatus(changeAdStatus)}>
            <h1 className="_content-title">Selecione uma ação</h1>
            <Grid container spacing={2}>

              <Grid item md={12} xs={12}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel shrink={true} id="change-order-status--outlined-label">
                    Alterar status do anúncio
                  </InputLabel>
                  <Select
                    native
                    label="Status"
                    name="status"
                    inputRef={registerChangeAdStatus({
                      required: true
                    })}
                    inputProps={{
                      name: "status",
                    }}
                  >
                    <option value="">Selecione</option>
                    <option value="ativo">Aprovar anúncio</option>
                    <option value="desativado">Desaprovar anúncio</option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Button
              className={"btn-step"}
              color="primary"
              autoFocus
              type="submit"
              style={{ height: matches ? 35 : 40 }}
            >
              Concluir
            </Button>
          </form>
        </div>
      </ModalDialog>
    </>
  )
}