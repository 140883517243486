import React, { useRef, useEffect, useState } from "react";
import Swal from 'sweetalert2';
import { Table, Button } from "react-bootstrap";
import { TextField,useMediaQuery } from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import AnuncioService from './../../services/Anuncios';
import Download from './../../services/Dowload';

import ModalDialog from '../../components/ModalDialog';

function Anuncios() {
  const refModalItems = useRef();
  const matches = useMediaQuery("(min-width:600px)");

  const [listAnuncios, setListAnuncios] = useState([])
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedAnuncio, setSelectedAnuncio] = useState();

  const loadAnuncio = (initialDate, endDate, nameFilter, filter) => {
    const anuncioService = new AnuncioService();

    Swal.fire('Carregando lista ... Aguarde');
    Swal.showLoading();
    anuncioService.findCoutsAnunciosbyCategories(initialDate, endDate, 'scamb').then((response) => {
      Swal.close();
      setListAnuncios(response.data.payload);
    }).catch((error) => {
      Swal.fire({
        type: "info",
        text: anuncioService.formatErrorMessage(error.response),
        title: "",
      });
    })
  }

  const getOrdersFile = () => {
    const download = new Download();

    Swal.fire('Gerando arquivo ... Aguarde');
    Swal.showLoading();
    download.getAnuncioFile(startDate, endDate, 'scamb').then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset=utf-8;' }));
      var fileLink = document.createElement('a');
      const newDate = new Date();

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `produtos_cadastrados_${newDate.getDate()}_${newDate.getMonth() + 1}_${newDate.getFullYear()}.csv`);
      document.body.appendChild(fileLink);

      Swal.close();
      fileLink.click();
    }).catch(error => {
      Swal.close();
      Swal.fire({
        type: "info",
        html: download.formatErrorMessage(error.response),
        title: "",
      });
    });
  }

  const onFilter = (e) => {
    e.preventDefault();
    loadAnuncio(startDate, endDate);
  }

  const clearFilter = (e) => {
    e.preventDefault();

    setStartDate('');
    setEndDate('');

    loadAnuncio();
  }

  useEffect(() => {
    loadAnuncio(startDate, endDate);
  }, [])

  const getCategoryAndCount = (anuncios, category) => {
    let total = 0;
    const getCount = anuncios.filter((anuncio => anuncio._id.category[0] === category));

    if (getCount) {
      getCount.map(anuncio => {
        return total += anuncio.count
      })
    }

    return total;
  }

  const getTotalCount = (anuncios) => {
    let total = 0;

    anuncios.map((anuncio) => {
      return total += anuncio.count;
    });

    return total;
  }

  const filterAnuncios = (anuncios) => {
    let anuncioObject = [];

    const nameCategory = {
      '5f2b397f4132b6c235b31490': 'Roupas e etc',
      '5f2b397f4132b6c235b31491': 'Mulher',
      '5f2b397f4132b6c235b314c5': 'Homem',
      '5f2b397f4132b6c235b314ed': 'Infantil',
      '5f2b397f4132b6c235b31545': 'Beleza e Saúde',
      '5f2b397f4132b6c235b3154a': 'Casa e Decoração',
      '5f2b397f4132b6c235b31569': 'Outros',
    }

    anuncios.map((anuncio) => {
      return anuncioObject.push({
        categoria: nameCategory[anuncio._id.category],
        itens: anuncio.anuncios
      })
    });

    return setSelectedAnuncio(anuncioObject);
  }

  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">

          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title">Produtos Cadastrados</div>
          </div>

          <div className="_wrap-filter-activity">
            <h2 className="title">Filtros</h2>
            <div className="_filter-activity">
              <form className="_form" onSubmit={(e) => onFilter(e)}>
                <div className="_wrap-dates">
                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Data inicial</label>
                    <div className="input-group">
                      <TextField
                        type="date"
                        className="form-control"
                        name="startDate"
                        placeholder="00/00/0000"
                        aria-describedby="cc-name"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Data final</label>
                    <div className="input-group">
                      <TextField
                        type="date"
                        className="form-control"
                        name="endDate"
                        placeholder="00/00/0000"
                        aria-describedby="cc-name"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="_wrap-input-group _submit">
                  <button type="submit" className="submit-filter">
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </form>
              <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div>
              <div className="excel-div">
                <button onClick={getOrdersFile} className="excel-button">Exportar .csv</button>
              </div>
            </div>
          </div>

          <Table bordered hover size="lg">
            <thead>
              <tr>
                <th>INCLUSÃO</th>
                <th>ROUPAS E ETC</th>
                <th>MULHER</th>
                <th>HOMEM</th>
                <th>INFANTIL</th>
                <th>BELEZA E SAÚDE</th>
                <th>CASA E DECORAÇÃO</th>
                <th>OUTROS</th>
                <th>TOTAL</th>
                <th>ANUNCIOS</th>
              </tr>
            </thead>
            <tbody>
              {listAnuncios.map((anuncio, index) => (
                <tr key={index}>
                  <td>{anuncio._id.dia + '/' + anuncio._id.mes + '/' + anuncio._id.ano}</td>
                  <td>{getCategoryAndCount(anuncio.anuncios, '5f2b397f4132b6c235b31490')}</td>
                  <td>{getCategoryAndCount(anuncio.anuncios, '5f2b397f4132b6c235b31491')}</td>
                  <td>{getCategoryAndCount(anuncio.anuncios, '5f2b397f4132b6c235b314c5')}</td>
                  <td>{getCategoryAndCount(anuncio.anuncios, '5f2b397f4132b6c235b314ed')}</td>
                  <td>{getCategoryAndCount(anuncio.anuncios, '5f2b397f4132b6c235b31545')}</td>
                  <td>{getCategoryAndCount(anuncio.anuncios, '5f2b397f4132b6c235b3154a')}</td>
                  <td>{getCategoryAndCount(anuncio.anuncios, '5f2b397f4132b6c235b31569')}</td>
                  <td>{getTotalCount(anuncio.anuncios)}</td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 45 : 50 }}
                      onClick={() => { filterAnuncios(anuncio.anuncios); refModalItems.current.openModal() }}
                    >
                      Ver Produtos
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </main>

      <ModalDialog
        ref={refModalItems}
        dialogTitle="Produtos"
        onCloseModal={
          <button
            type="button"
            className="btn"
            onClick={() => { refModalItems.current.closeModal() }}
          >
            {matches ? <CloseIcon /> : <ChevronLeftIcon />}
          </button>
        }
      >
        <div className="_wrap-form">
          {selectedAnuncio && selectedAnuncio.map((object, index) => (
            <div key={index}>
              <h6>Categoria: {object.categoria}</h6>
              {object.itens.map((item, index) => (
                <div key={index}>
                  <p style={{ paddingTop: '15px', marginBottom: 0 }}><strong>Produto: </strong><a href={item.url_encurtada} target="_black">{item.titulo}</a></p>
                  <p style={{ marginBottom: 0 }}><strong>Descrição: </strong> {item.descricao}</p>
                  <p style={{ marginBottom: 0 }}><strong>Valor Pontos: </strong> {item.pontos_por.$numberDecimal}</p>
                  <p style={{ paddingBottom: '15px', marginBottom: 0 }}><strong>Valor Taxa: </strong> R$ {item?.taxa_scamb.$numberDecimal || 0}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </ModalDialog>
    </>
  );
}

export default Anuncios;
