import Service from './Service';

export default class Cashback extends Service {

  listCashback() {
    return this.axiosInstance.get(`/cashback`);
  }

  editCashback({ cashbackId, cashback }) {
    return this.axiosInstance.put(`/cashback`, { cashbackId, cashback });
  }
}